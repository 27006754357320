import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import GoogleMapReact from 'google-map-react';
import {setUser, isRegister, translate, getOS,serverRequest, regKey, getKey} from './actions/index';
import { MapContainer, TileLayer, useMap,Marker,Popup } from 'react-leaflet';
import Routing from "./Routing";
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
class LeafMap extends Component {
static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33
    },
    zoom: 11
  };
    constructor(props) {

        super(props);
        this.googleMaps = React.createRef();



    }

    render() {
    const me=this;
    if(this.props.register.gps_loading)return null
    if(!this.props.register.QR_DELIVER.delivery_from_gps){
    return null
    }
    var greenIcon = window.L.icon({
    iconUrl: require('./assets/blue_motor.png'),
    shadowUrl: require('./assets/blue_motor_shadow.png'),
 draggableWaypoints: false,
    iconSize:     [50, 50], // size of the icon
    shadowSize:   [100, 50], // size of the shadow
    iconAnchor:   [15, 25], // point of the icon which will correspond to marker's location
    shadowAnchor: [25, 25],  // the same for the shadow
    popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
});

var qrdelivericon = window.L.icon({
    iconUrl: this.props.register.QR_DELIVER.image,
     draggableWaypoints: false,
    iconSize:     [50, 50], // size of the icon
    shadowSize:   [100, 50], // size of the shadow
    iconAnchor:   [15, 25], // point of the icon which will correspond to marker's location
    shadowAnchor: [25, 25],  // the same for the shadow
    popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
});
var center=[this.props.register.QR_DELIVER.delivery_from_gps.split(',')[0],this.props.register.QR_DELIVER.delivery_from_gps.split(',')[1]]
 var centerorigin=[this.props.register.QR_DELIVER.delivery_from_gps.split(',')[0],this.props.register.QR_DELIVER.delivery_from_gps.split(',')[1]]
 if(this.props.order.gps){

  center=[this.props.order.gps.lat,this.props.order.gps.lon]

  }
   if(this.props.courrier){
   console.log("on counrrier")
    center=[Number(this.props.order.courrier_gps.split(",")[0]),Number(this.props.order.courrier_gps.split(",")[1])];
  }

   return(<div style={{margin:'auto',opacity:this.props.register.leafloaded?1:0,position:'relative',width:'100%',marginBottom:20}}>
         <link rel="stylesheet" onLoad={()=>{

this.props.isRegister(['leafloaded',true])
}} href="https://unpkg.com/leaflet@1.2.0/dist/leaflet.css" />

<div className="summary">
{this.props.courrier&&this.props.register.route&&<div className="route-name km">

<CountdownCircleTimer
    isPlaying
    duration={(this.props.register.route.summary.totalTime)} strokeWidth={3} strokeLinecap={'square'} colors="#000  " size={100}
    onComplete={() => {
      // do your stuff here
      return { shouldRepeat: false, delay: 1.5 } // repeat animation in 1.5 seconds
    }}
  >{({ remainingTime }) => <div>{(remainingTime/60).toFixed(0)} min</div>}</CountdownCircleTimer></div>}
{this.props.courrier&&this.props.register.route&&<div className="route-name">{this.props.register.route.name}</div>}

{this.props.courrier&&this.props.register.route&&<div className="route-name km">{(this.props.register.route.summary.totalDistance/1000).toFixed(1)} km</div>}

</div>
       <MapContainer whenReady={map =>{
 console.log("weerready",map)
me.props.isRegister(['map',map]);
       }} style={{width:'100%',height:this.props.courrier?400:200}}center={center} zoom={20} scrollWheelZoom={false}>

  <TileLayer
    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  />
      {!this.props.order.gps&&<Marker icon={qrdelivericon} position={centerorigin}>

      </Marker>}
      {this.props.order.courrier_gps&&<Marker icon={greenIcon} position={this.props.order.courrier_gps.split(",")}>

      </Marker>}

    {this.props.order.gps&&<Routing courrier={this.props.courrier} center={center} isRegister={this.props.isRegister}order={this.props.order} service_url={'https://routing.openstreetmap.de/routed-foot/route/v1'} register={this.props.register}/>}
</MapContainer>

{this.props.courrier&&this.props.register.route&&<div className="route-inst">{this.getRoute()}</div>}


</div>);

    }
    getPopup(){

    return  <Popup>
          A pretty CSS3 popup. <br /> Easily customizable.
        </Popup>;
    }
    getRoute(){

    return this.props.register.route.instructions.map((item,index)=>{


        return <div key={index}>...</div>


    })


    }


}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({setUser, isRegister, regKey}, dispatch);

}

function mapStateToProps(state) {
    return {
        user: state.user,
        register: state.register


    }

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeafMap))
