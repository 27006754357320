import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import GoogleMapReact from 'google-map-react';
import {setUser, isRegister, translate, getOS,serverRequest, regKey, getKey} from './actions/index';
class Tracker extends Component {
static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33
    },
    zoom: 11
  };
    constructor(props) {

        super(props);
        this.googleMaps = React.createRef();



    }

    render() {

    const apiIsLoaded = (map, maps) => {
      const directionsService = new window.google.maps.DirectionsService();
      const directionsRenderer = new window.google.maps.DirectionsRenderer();
      directionsRenderer.setMap(map);
      const origin = {lat:Number(this.props.register.QR_DELIVER.delivery_from_gps.split(',')[0]),lng:Number(this.props.register.QR_DELIVER.delivery_from_gps.split(',')[1])}
      var destination = '';
    if(!this.props.register.order.checkout_session.metadata.pickup){
    destination=`${this.props.register.order.checkout_session.shipping.address.line1}${this.props.register.order.checkout_session.shipping.address.line2} ${this.props.register.order.checkout_session.shipping.address.city} ${this.props.register.order.checkout_session.shipping.address.country}`;


      directionsService.route(
        {
          origin: origin,
          destination: destination,
          travelMode: window.google.maps.TravelMode.BICYCLING
        },
        (result, status) => {
          if (status === window.    google.maps.DirectionsStatus.OK) {
            directionsRenderer.setDirections(result);
          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      );
       }
    };
    return (
      <div>
        <div className={'map'}>
          <GoogleMapReact
            bootstrapURLKeys={{ key:this.props.register.QR_DELIVER.gg_id,language:this.props.register.lang.simple}}
            defaultCenter={{lat:Number(this.props.register.QR_DELIVER.delivery_from_gps.split(',')[0]),lng:Number(this.props.register.QR_DELIVER.delivery_from_gps.split(',')[1])}}
            defaultZoom={10}
            center={{lat:Number(this.props.register.QR_DELIVER.delivery_from_gps.split(',')[0]),lng:Number(this.props.register.QR_DELIVER.delivery_from_gps.split(',')[1])}}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
          />
        </div>
      </div>
    );

    }


}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({setUser, isRegister, regKey}, dispatch);

}

function mapStateToProps(state) {
    return {
        user: state.user,
        register: state.register


    }

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tracker))
