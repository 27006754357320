import React, {Component} from 'react';
import {connect} from 'react-redux';
//import ReCAPTCHA from "react-google-recaptcha";
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router';
import {setUser, isRegister, serverRequest,replaceString, regKey,getOS, getKey,hasClass,findAddress,mongoObjectId,PrintElem} from './actions/index';
import AnimatedNumber from "animated-number-react";
import 'react-phone-input-2/lib/style.css'
import PhoneInput from 'react-phone-input-2';
import Moment from 'react-moment';
import { ScrollView } from "@cantonjs/react-scroll-view";
import moment from 'moment';
import {isMobile,isiPhone,} from 'react-device-detect';
import { IFrame } from './iframe';
import Tracker from './tracker.js';

import TrackerLeaf from './leaflet'
    import { Rating } from 'react-simple-star-rating';
import TimePicker from 'react-time-picker';
import underscore from 'underscore';

import {
    IdealBankElement,
    useStripe,
    useElements,
    Elements
} from '@stripe/react-stripe-js';
//import {loadStripe} from '@stripe/stripe-js';
import ReactGA from 'react-ga';
import {useState,useRef} from 'react';
import queryString from "query-string";
import css from './cardDetailed.css';
import Config from './config/config.js';
// import CurrencyInput from 'react-currency-input-field';
import getSymbolFromCurrency from 'currency-symbol-map';
import ReactCountryFlag from "react-country-flag";
import Autocomplete from "react-google-autocomplete";
import background from "./assets/paymentmethods.png";
import animatedLogo from "./assets/loader.base";
import bg from "./assets/qrfooter.png";
import Recaptcha from 'react-google-invisible-recaptcha';
import carbon from './assets/carbon-removal-icon.png'
import Parser from 'html-react-parser';
import LazyLoad from 'react-lazyload';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import QRCode from 'qrcode.react';
import currencies from './assets/currencies.json';
import CookieConsent,{ Cookies, resetCookieConsentValue } from "react-cookie-consent";
import {
    Menu,SubMenu,
    MenuItem,MenuDivider,
    MenuButton
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import {
  EmailShareButton,
 FacebookShareButton,
 HatenaShareButton,
 InstapaperShareButton,
 LineShareButton,
 LinkedinShareButton,
 LivejournalShareButton,
 MailruShareButton,
 OKShareButton,
 PinterestShareButton,
 PocketShareButton,
 RedditShareButton,
 TelegramShareButton,
 TumblrShareButton,
 TwitterShareButton,
 ViberShareButton,
 VKShareButton,
 WhatsappShareButton,
 WorkplaceShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon
} from "react-share";
import md5 from 'md5';
const fonts=['Material Icons', 'Nunito'];
require('./addanimation.scss');
var replaceStr=function(str,len=50) {
  try{
    if (str&&String(str)) {

      str = String(str).replace(/[^a-zA-Z0-9À-ž\\/!?:\u0621-\u064A0-9,. _&\s]/g,"-");
          if (str.length > len) {
        str = str.substring(0, len);
      }
      if (str == '-') return '';
      return str;
    }
  }catch(err){

  }



  return '';
}
var stripePromise;
const audio = new Audio(require('./assets/druplet.mp3'));
const  waitForWebfonts=function(fonts, callback) {
    var loadedFonts = 0;
    for(var i = 0, l = fonts.length; i < l; ++i) {
        (function(font) {
            var node = document.createElement('span');
            // Characters that vary significantly among different fonts
            node.innerHTML = 'giItT1WQy@!-/#';
            // Visible - so we can measure it - but not on the screen
            node.style.position      = 'absolute';
            node.style.left          = '-10000px';
            node.style.top           = '-10000px';
            // Large font size makes even subtle changes obvious
            node.style.fontSize      = '300px';
            // Reset any font properties
            node.style.fontFamily    = 'sans-serif';
            node.style.fontVariant   = 'normal';
            node.style.fontStyle     = 'normal';
            node.style.fontWeight    = 'normal';
            node.style.letterSpacing = '0';
            document.body.appendChild(node);

            // Remember width with no applied web font
            var width = node.offsetWidth;

            node.style.fontFamily = font;

            var interval;
            function checkFont() {
                // Compare current width with original width
                if(node && node.offsetWidth != width) {
                    ++loadedFonts;
                    node.parentNode.removeChild(node);
                    node = null;
                }

                // If all fonts have been loaded
                if(loadedFonts >= fonts.length) {
                    if(interval) {
                        clearInterval(interval);
                    }
                    if(loadedFonts == fonts.length) {
                        callback();
                        return true;
                    }
                }
            };

            if(!checkFont()) {
                interval = setInterval(checkFont, 50);
            }
        })(fonts[i]);
    }
};
const paymentMethods = {
    'eur': ['card', 'p24', 'ideal', 'giropay', 'eps', 'bancontact'],
    'pln': ['card', 'p24']
}
const formatAMPM = (time) => {
    try {

        let hours = time.split(":")[0];
        let minutes = time.split(":")[1];
        hours = hours || 12;
        const ampm = hours >= 12 ? " PM" : " AM";
        minutes = minutes < 10 ? `${minutes}` : minutes;
        hours %= 12;
        const strTime = `${hours}:${minutes} ${ampm}`;
        if(window.debug)console.log("time<<")
        return strTime;
    } catch (e) {
        if(window.debug)console.log(e, "time<<")
        return "";
    }
};
var replacePostcodeStr = function (str, len = 11) {
    if (str) {
        str = String(str).replace(/[^0-9a-zA-Z]/g, '');
        if (str.length > len) {
            str = str.substring(0, len);
        }
        if (str == '-') return '';
        return str;
    }


    return '';
}
var replaceAmount = function (str, len = 11) {
    if (str) {
        str = String(str).replace(/[^0-9,.]/g, '');
        if (str.length > len) {
            str = str.substring(0, len);
        }
        if (str == '-') return '';
        return str;
    }


    return '';
}
const ELEMENTS_OPTIONS = {
    fonts: [
        {
            cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
        },
    ],
    classes: {
        base: 'field',
        complete: 'complete',
        empty: 'is-empty',
        focus: 'is-focused',
        invalid: 'is-invalid',
        webkitAutofill: 'webkit-autofill',
    }
};

const SubmitButton = ({onClick, id,processing, error, children, disabled}) => (
    <button id={id} onClick={onClick}
            className={`SubmitButton ${error ? 'SubmitButton--error' : ''}`}
            type="submit"
            disabled={processing || disabled}
    >
        {processing ? 'Processing...' : children}
    </button>
);

const ErrorMessage = ({children}) => (
    <div className="ErrorMessage" role="alert">
        <svg width="16" height="16" viewBox="0 0 17 17">
            <path
                fill="#FFF"
                d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
            />
            <path
                fill="#6772e5"
                d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
            />
        </svg>
        {children}
    </div>
);
const Field = ({
                   label,
                   id,
                   type,
                   placeholder,
                   required,
                   autoComplete,
                   value,
                   onChange,
               }) => (
    <div className="FormRow">
        <label htmlFor={id} className="FormRowLabel">
            {label}
        </label>
        <input
            className="FormRowInput"
            id={id}
            type={type}
            placeholder={placeholder}
            required={required}
            autoComplete={autoComplete}
            value={value}
            onChange={onChange}
        />
    </div>
);
const CheckoutForm = (bttn) => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [cardComplete, setCardComplete] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(null);

    const [billingDetails, setBillingDetails] = useState({
        email: '',
        phone: '',
        name: '',
    });
    var obj = bttn;
    if(window.debug)console.log(bttn, "bttn")
    const handleSubmit = (event) => {


        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        if (error) {
            elements.getElement('card').focus();
            return;
        }

        if (cardComplete) {
            //  setProcessing(true);
        }
        if(window.debug)console.log(bttn.props.match);
        if (!bttn.props.register.buttoncheckout) {


            bttn.props.history.push('#checkout');

            bttn.props.isRegister(['buttoncheckout', 1]);
            return;
        }

        //  bttn.props.isRegister(['loading', 1]);
        bttn.props.isRegister(['checkout', 1])
        bttn.props.isRegister(['error', '']);


        serverRequest('post', `qrdeliverpay/${bttn.props.match.params.appid}/${bttn.props.match.params.qrcode}`, {
            app: `${bttn.props.match.params.appid}`,
            type: 'fooi',
            qritem: `${bttn.props.match.params.qrcode}`,
            amount: bttn.getCartAmount(),
            cart: bttn.getCartProducts(),
              options: bttn.getOptions(),
            qty: 1,
            num: bttn.props.register.clientnum,
            postcode: bttn.props.register.postcode,
            voucher: bttn.props.register.voucher,
            street_name: bttn.props.register.street_name,
            house_number: bttn.props.register.house_number,
            house_number_addition: bttn.props.register.house_number_addition,
            city: bttn.props.register.city,
            email: bttn.props.register.email,
            country: bttn.props.register.country,
            comments: bttn.props.register.comments,
            pickup: bttn.props.register.pickup,
            preferred_from_time: bttn.props.register.preferred_from,
            pickup_preferred_from_time: bttn.props.register.pickup_preferred_from,
            utoken:bttn.props.register.utoken,

        }, (res) => {


            if (res.res && res.res.session && res.res.session.url) {
                if(window.debug)console.log('res', res.res.session.url, bttn.props.register.postcode);
                window.localStorage.setItem(bttn.props.register.QR_DELIVER._id, JSON.stringify(bttn.props.register));
                if (!bttn.props.register.payment_method || bttn.props.register.payment_method == 'card') {
                   window.location.href = res.res.session.url;
                   /* if(window.debug)console.log(res.res.session.url)
                    if(window.nativeqrdeliver){
                     setTimeout(function(){


                    },2000)
                    }*/
                      bttn.props.isRegister(['loading', 0]);
                          bttn.props.isRegister(['checkout', 0]);
                } else {
                    if(window.debug)console.log(res.res.session, "<<<_")

                    const sourceData = {
                        type: bttn.props.register.payment_method,
                        amount: res.res.session.amount_total,
                        currency: bttn.props.register.QR_DELIVER.currency,
                        owner: {
                            name: bttn.props.register.name,
                            email: bttn.props.register.email,
                            address: {
                                country: bttn.props.register.country,
                                city: bttn.props.register.city,
                                postal_code: bttn.props.register.postcode
                            }
                        },
                        metadata: {
                            appid: `${bttn.props.register.QR_DELIVER.user.appid}`,

                            account_id: `${bttn.props.register.QR_DELIVER.user.account_id}`,
                            code: bttn.props.match.params.qrcode,
                            token: res.res.session.token

                        },
                        redirect: {
                            return_url: res.res.session.return_url,
                        },
                        statement_descriptor: bttn.props.register.QR_DELIVER.user.company_desc
                    };
                    if (bttn.props.register[bttn.props.register.payment_method + '_bankSelection']) {
                        sourceData[bttn.props.register.payment_method] = {
                            bank: bttn.props.register[bttn.props.register.payment_method + '_bankSelection']
                        }
                    } else {
                        sourceData[bttn.props.register.payment_method] = {
                            statement_descriptor: bttn.props.register.QR_DELIVER.statement_descriptor
                        }
                    }
                    const payload = stripe
                        .createSource(sourceData)
                        .then(function (payload) {
                            if (payload && payload.source && payload.source.redirect) {
                                window.location.href = payload.source.redirect.url;
                                 if(window.debug)console.log(res.res.session.url)
                                    if(window.nativeqrdeliver){
                                        setTimeout(function(){

                      bttn.props.isRegister(['loading', 0]);
                          bttn.props.isRegister(['checkout', 0]);
                    },2000)
                                        }
                            } else if (payload.error) {
                                bttn.props.isRegister(['checkout', 0])
                                bttn.props.isRegister(['error', payload.error.message]);
                                bttn.props.isRegister(['loading', false]);
                            }

                        }).catch((err) => {
                            if(window.debug)console.log(err)
                            bttn.props.isRegister(['checkout', 0])
                            bttn.props.isRegister(['error', err.message]);
                            bttn.props.isRegister(['loading', false]);
                        });
                }


            } else if (res.res && res.res.msg) {
                if(window.debug)console.log("???????")
                bttn.props.isRegister(['checkout', 0])
                bttn.props.isRegister(['error', res.res.msg]);
                bttn.props.isRegister(['loading', false]);
                if(res.res.msg&&res.res.msg=='captcha_is_not_correct'){
                    window.location.reload();
                }
            }


        });

        // serverRequest('post',"addtocart",{
        //  app:`${bttn.props.match.params.appid}`,
        //  type:'fooi',
        //  qr:`${bttn.props.match.params.qrcode}`,
        //  amount:(Number(bttn.props.register.amount)),
        //  qty:1,
        //  num:bttn.props.register.clientnum,qrdeliver:true
        // },(res)=>{
        //         if(res.status==1&&res.res&&!res.res.error){
        //           var order =res.res.order;
        //             const sourceData = {
        //                 type: 'ideal',
        //                 amount: ((Number(bttn.props.register.amount))*100)+10,
        //                 currency: 'eur',
        //                 owner: {
        //                     name:"test",
        //                     email:"admin@chatysop.com",
        //                 },
        //                 redirect: {
        //                     return_url: `http://localhost:3000/qrpay/`,
        //                 },
        //                 statement_descriptor: "QRDELIVER"
        //             };
        //             const payload =  stripe
        //             .createSource(sourceData)
        //             .then(function(payload) {
        //                 // Handle result.error or result.source
        //
        //
        //                 if(payload.source.redirect){
        //                     window.location.href=payload.source.redirect.url;
        //
        //                 }
        //
        //             });
        //         }else{
        //
        //           if(res.error){
        //               bttn.props.isRegister(['status','order_error'])
        //           }else
        //           if(res.res.error){
        //               bttn.props.isRegister(['status',res.res.error])
        //           }
        //
        //         }
        //
        //
        //
        //     });


        //
        // serverRequest('post', `qrdeliverpay/${bttn.props.match.params.appid}/${bttn.props.match.params.qrcode}`, {
        //     app: `${bttn.props.match.params.appid}`,
        //     type: 'fooi',
        //     qritem: `${bttn.props.match.params.appcode}`,
        //     amount: (Number(bttn.props.register.amount)),
        //     qty: 1,
        //     num: bttn.props.register.clientnum,
        // }, (res) => {
        //
        //     if(window.debug)console.log('res', res.res.session.url);
        //     window.location.href = res.res.session.url;
        //
        //
        // });


        // const sourceData = {
        //                     type: 'ideal',
        //                     amount: ((Number(bttn.props.register.amount))*100)+10,
        //                     currency: 'eur',
        //                     owner: {
        //                         name:"test",
        //                         email:"admin@chatysop.com",
        //                     },
        //                     redirect: {
        //                         return_url: `http://localhost:3000/qrpay/`,
        //                     },
        //                     statement_descriptor: "QRDELIVER"
        //                 };
        //                 const payload =  stripe
        //                 .createSource(sourceData)
        //                 .then(function(payload) {
        //                     // Handle result.error or result.source
        //
        //
        //                     if(payload.source.redirect){
        //                         window.location.href=payload.source.redirect.url;
        //
        //                     }
        //
        //                 });


// if(window.debug)console.log(bttn.props.register.bankselection)
//       const sourceData = {
//           type: 'ideal',
//           amount: ((Number(bttn.props.register.amount))*100)+10,
//           currency: 'eur',
//           owner: {
//               name:"test",
//               email:"admin@chatysop.com",
//           },
//           metadata: {
//
//               account_id: `${bttn.props.register.QR_DELIVER.user.account_id}`,
//               appid: `${bttn.props.register.QR_DELIVER.user.appid}`,
//           },
//           redirect: {
//               return_url: `http://localhost:3000/qrpay/`,
//           },
//           ideal:{bank:bttn.props.register.bankselection},
//           statement_descriptor: bttn.props.register.QR_DELIVER.user.name
//       };
//       const payload =  stripe
//           .createSource(sourceData)
//           .then(function(payload) {
//               // Handle result.error or result.source
//
//
//               if(payload.source.redirect){
//                   window.location.href=payload.source.redirect.url;
//
//               }
//
//           });
    };

    const reset = () => {
        setError(null);
        //setProcessing(false);
        setPaymentMethod(null);
        setBillingDetails({
            email: '',
            phone: '',
            name: '',
        });
    };
    const me = this;
    const opt = {

        iconStyle: 'solid',
        style: {
            base: {
                color: '#000',
                lineHeight: '30px',
                cursor: 'pointer!important',
                fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                fontWeight: 'bold',
                '::placeholder': {
                    color: '#000',
                }, borderRadius: '20px', overflow: 'hidden!important', padding: '8px',
                background: '#ccc',
            },
            invalid: {
                color: '#B71C1C',
                iconColor: '#B71C1C',
            },

        }, theme: 'black',
        variables: {
            colorText: '#3c372f',
        },
        hidePostalCode: true,
        classes: {
            base: 'field',
            complete: 'complete',
            empty: 'is-empty',
            focus: 'is-focused',
            focus: 'is-focused',
            focus: 'is-focused',
            invalid: 'is-invalid',
            webkitAutofill: 'webkit-autofill',
        },
        hideIcon: false,
    }
    const getPaymentBank = () => {
        if (bttn.canCheckoutForm() || bttn.getCartAmount() == 0) return null;
        if (bttn.props.register.lang && bttn.props.register.payment_method == 'ideal') {
            return (
                <IdealBankElement iconStyle={'default'} onChange={(ev) => {
                    if(window.debug)console.log(ev, bttn.props.register.bankSelection)
                    bttn.props.isRegister(['bankSelection', ev.value]);
                    bttn.props.isRegister(['ideal_bankSelection', ev.value]);
                }} onLoad={(evt) => {
                    evt.target.value = bttn.props.register.bankSelection
                }}

                                  value={bttn.props.register.bankSelection} options={opt}/>
            )
        } else
        // if(bttn.props.register.payment_method=='p24'){
        //     return(
        //         <P24BankElement onChange={(ev) => {
        //             if(window.debug)console.log(ev)
        //             bttn.props.isRegister(['p24_bankSelection', ev.value]);
        //         }} options={opt}/>
        //     )
        //     return null
        // }else
        // if(bttn.props.register.payment_method=='eps'){
        //     return(<EpsBankElement onChange={(ev) => {
        //         if(window.debug)console.log(ev)
        //         bttn.props.isRegister(['eps_bankSelection', ev.value]);
        //     }} options={opt}/>)
        // }else
        if (bttn.props.register.lang && bttn.props.register.payment_method == 'card') {
            return (<div className={'payment-explain'}>
                {getKey(bttn.props.register.lang.shop_data.qrdeliver, 'card_redirect')}
                {/*<CardElement option={opt}/>*/}
            </div>)
        }
        return null
    }


    return paymentMethod ? (
        <div className="Result">
            <div className="ResultTitle" role="alert">
                Payment successful
            </div>
            <div className="ResultMessage">
                Thanks for trying Stripe Elements. No money was charged, but we
                generated a PaymentMethod: {paymentMethod.id}
            </div>
        </div>
    ) : (
        <form className="Form" onSubmit={(event) => {

            event.preventDefault();
            /*
                        bttn.props.isRegister(['command',handleSubmit])
                        bttn.recaptcha.execute();*/
            handleSubmit(event)
        }}>
            {error && <ErrorMessage>{error.message}</ErrorMessage>}


            {getPaymentBank()}
            <SubmitButton processing={processing} error={error} disabled={bttn.getPayDisabled() || !stripe}>
                {bttn.getButtonTitle()}
            </SubmitButton>

        </form>
    );
};

class Game extends Component {

    constructor(props) {

        super(props);
        this.animatedAmount = React.createRef();

        this.voucherInput = React.createRef();
        this.productList = React.createRef();
         this.deliveryBox = React.createRef();
         this.delivery_canvas = React.createRef();
         this.messagesEndRef  = React.createRef();
         this.rating_input  = React.createRef();
         this.searchfield  = React.createRef();
         this.mainproduct  = React.createRef();

         this.schemaIframe  = React.createRef();

        this.emailInput = React.createRef();


    }
     simpleLoader(){


                 return (
                <div className="lds-grid">

                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            )

     }

    getLoader(checkout) {
        if (!checkout) {
//            return (
//                <div className="lds-grid">
//
//                    <div></div>
//                    <div></div>
//                    <div></div>
//                    <div></div>
//                    <div></div>
//                    <div></div>
//                    <div></div>
//                    <div></div>
//                    <div></div>
//                </div>
//            )
return( <div className="full-page-loader">

                  <img width="100" src={animatedLogo} alt="QRDeliver" /></div>)
        } else {
            return (

                <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>)
        }
    }
    loadShop(){


if(window.appid&&window.qrcode){
this.props.match.params={appid: window.appid, qrcode:window.qrcode,url_key:this.props.match.params.url_key}
this.forceUpdate();
this.start();
}else{
this.props.history.replace('/');
}
/*
     serverRequest('post', `qrpayroutes`, {key:this.props.match.params.url_key}, (res) => {

//
//            this.props.history.replace({
//            pathname: generatePath(this.props.match.path, )
//            });

this.props.match.params={appid: "61daa9a28a1c28003cfa659f", qrcode: "62c52825d05217003c6cf027",url_key:this.props.match.params.url_key}
this.forceUpdate();
this.start();
        });
        */
    }
    start() {
   // window.debug=true;
if(this.props.match.params.url_key&&!this.props.match.params.appid&&!this.props.match.params.qrcode){

this.loadShop();
return;
}
        if (this.props.register.QR_DELIVER) return;
        var me = this;
        const params = queryString.parse(this.props.history.location.search);
        me.props.isRegister(['loading', 1]);
        me.props.isRegister(['qr_view', 0]);
        var storageItem = null;
        // window.localStorage.setItem(this.props.match.params.qrcode, null);
        if (window.localStorage.getItem(this.props.match.params.qrcode) && window.localStorage.getItem(this.props.match.params.qrcode) != '') {
            try {
                storageItem = JSON.parse(window.localStorage.getItem(this.props.match.params.qrcode));
                if(window.debug)console.log("storage item", storageItem);
                if (storageItem) {
                    if (storageItem.name && storageItem.name != '') {
                        me.props.isRegister(['name', storageItem.name]);
                    }
                    if (storageItem.lastname && storageItem.lastname != '') {
                        me.props.isRegister(['lastname', storageItem.lastname]);
                    }
                    if (storageItem.postcode && storageItem.postcode != '') {
                        me.props.isRegister(['postcode', storageItem.postcode]);
                    }
                    if (storageItem.country && storageItem.country != '') {
                        me.props.isRegister(['country', storageItem.country]);
                    }
                    if (storageItem.street_name && storageItem.street_name != '') {
                        me.props.isRegister(['street_name', storageItem.street_name]);
                    }
                    if (storageItem.house_number && storageItem.house_number != '') {
                        me.props.isRegister(['house_number', storageItem.house_number]);
                    }


                    if (storageItem.house_number_addition && storageItem.house_number_addition != '') {
                        me.props.isRegister(['house_number_addition', storageItem.house_number_addition]);
                    }

                    if (storageItem.comments && storageItem.comments != '') {
                        me.props.isRegister(['comments', storageItem.comments]);
                    }

                    if (storageItem.pickup_preferred_from && storageItem.pickup_preferred_from != '') {
                        me.props.isRegister(['pickup_preferred_from', storageItem.pickup_preferred_from]);
                    }
                    if (storageItem.preferred_from && storageItem.preferred_from != '') {
                        me.props.isRegister(['preferred_from', storageItem.preferred_from]);
                    }
                    if (storageItem.pickup && storageItem.pickup != '') {
                        me.props.isRegister(['pickup', storageItem.pickup]);
                    }
                    if (storageItem.city && storageItem.city != '') {
                        me.props.isRegister(['city', storageItem.city]);
                    }

                    if (storageItem.postcode && storageItem.postcode != '') {
                        me.props.isRegister(['postcode', storageItem.postcode]);
                    }

                    if (storageItem.phone && storageItem.phone != '') {
                        me.props.isRegister(['phone', storageItem.phone]);
                    }

                    if (storageItem.email && storageItem.email != '') {
                        me.props.isRegister(['email', storageItem.email]);
                    }
                    if (storageItem.payment_method && storageItem.payment_method != '') {
                        me.props.isRegister(['payment_method', storageItem.payment_method]);
                    }
                    if (storageItem.payment_method && storageItem.payment_method != '') {
                        me.props.isRegister(['payment_method', storageItem.payment_method]);
                    }
                    if (storageItem.bankSelection && storageItem.bankSelection != '') {
                        me.props.isRegister(['bankSelection', storageItem.bankSelection]);
                    }
                    if (storageItem.orders && storageItem.orders != '') {
                        me.props.isRegister(['orders', JSON.parse(storageItem.orders)]);
                    }
                    if(window.debug)console.log(storageItem)

                }
            } catch (err) {
if(window.debug)console.log(err)
            }
        }
        var post = {token: me.props.register.captcha_token};
        if (storageItem && storageItem.postcode) {
            post = {postcode: storageItem.postcode, token: me.props.register.captcha_token, t: 9991};
        }

        if(params.token){
            post.order_token=params.token;

        }
         if(params.merchant_token){
            post.merchant_token=params.merchant_token;

        }
        if(params.delivery_token){
            post.delivery_token=params.delivery_token;

        }
        //in progress
        if(window.qrdeliveraccount){
            post.qrdeliveraccount=window.qrdeliveraccount;

        }
        if(window.account){
            post.qrdeliveraccount=window.account;

        }
        if(params.shipped){
            post.shipped=true;

        }

        if(window.qrdelivertoken){
            post.utoken=window.qrdelivertoken;
        }
        serverRequest('post', `fooiappmobile/${this.props.match.params.appid}/${this.props.match.params.qrcode}`, post, (res) => {
            //var shop=window.localStorage.setItem('user', JSON.stringify(person));

          if(window.debug)console.log(res,'wtf',`fooiappmobile/${this.props.match.params.appid}/${this.props.match.params.qrcode}`);
         // alert(JSON.stringify(post));

                if(res.res&&res.res.msg&&res.res.msg=='captcha_is_not_correct'){
             //  return window.location.reload();

                me.props.isRegister(['msg', res.res.msg]);
              }

            if (res.res && res.res.status && res.res.code) {

                    if(window.nativeqrdeliver){
                                 const meta = document.createElement('meta'); meta.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no'); meta.setAttribute('name', 'viewport'); document.getElementsByTagName('head')[0].appendChild(meta);
                                document.body.style.backgroundColor='transparent';
                                document.documentElement.style.backgroundColor='transparent';
                             try{

                                  window.qrdeliver.postMessage(escape(JSON.stringify({order:params.merchant_token&&!params.delivery_token?null:res.res.order,url:document.location.toString(),code:res.res.code,params})));


                            }catch(err){
if(window.debug)console.log(err)
                            }
                    }







                window.document.title = `${res.res.code.user.name}${res.res.order?'- #'+res.res.order.increment_id:''}` ;
                window.document.description = res.res.code.user.company_desc;

                const accounts = [
                    {
                        trackingId: 'UA-133687885-9',
                        gaOptions: {
                            name: 'qrdeliver'

                        }
                    }
                ];
                if (res.res.code.analatics) {
                    accounts.push(
                        {
                            trackingId: res.res.code.analatics,
                            gaOptions: {name: 'merchant'}
                        })
                }


                ReactGA.initialize(
                    accounts,
                    { alwaysSendToDefaultTracker: true}
                );
                ReactGA.pageview(`${this.props.match.params.appid}/${this.props.match.params.qrcode}`, ['merchant', 'qrdeliver']);

                if(window.debug)console.log(storageItem, "<<")
                me.props.isRegister(['gg_accounts', accounts]);



                me.props.isRegister(['total_amount', 0]);
                me.props.isRegister(['lang', res.res.language]);

                //const params = queryString.parse(me.props.history.location.search);

                if(window.debug)console.log(params);
                //https://localhost:3000/recharge?client_secret  =src_client_secret_iY68jaTOt9ehUtpN0uSKrMcf&livemode=true&redirect_status=succeeded&source=src_1IJbhaLRDgjS7ksTuMCZSRP2
                if (params.redirect_status) {
                    me.props.isRegister(['status', params.redirect_status])
                }

                if(res.res.code.resricted_shipping){
                    me.props.isRegister(['postcode', res.res.code.postcode]);
                }
                if(res.res.code.user.fixed){
                    me.props.isRegister(['amount', res.res.code.user.amount]);
                }else{
                    me.props.isRegister(['amount', 0]);
                }
                //uncomment impoprt r.j
                //stripePromise = loadStripe(res.res.code.pk);
                me.props.isRegister(['QR_DELIVER', res.res.code]);
if(res.res.order){


if( this.checkorder){


clearInterval( this.checkorder);
}
if(params.shipped&&params.delivery_token){
this.deliveryUpdate(true)
}
  me.props.isRegister(['last_post', post]);
              //if(!res.res.order.shipped){

              if(params.delivery_token){


               this.checkorder = setInterval(this.deliveryUpdate.bind(this), 10000);

              }else{


               this.checkorder = setInterval(this.checkOrder.bind(this), 10000);


              }


              //}

              if(params.show_messages||this.props.location.hash.messages){
               this.props.isRegister(['show_messages', true])
                            setTimeout(()=>{

           if(me.messagesEndRef.current)me.messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
},2000)
              }
              if(params.show_rating){

               this.props.isRegister(['show_rating', true])
               setTimeout(()=>{

           if(me.rating_input.current)me.rating_input.current.scrollIntoView({ behavior: "smooth" })
},2000)
              }
                me.addOrder(res.res);
                me.props.isRegister(['order', res.res.order]);
                    me.props.isRegister(['long_delivery_token', res.res.delivery_token]);
                    // if(!window.nativeqrdeliver){
 const os=getOS();


                  if(res.res.order&&res.res.order.uhash&&!window.nativeqrdeliver&&!window.qrdelivertoken){
//if(os=='Android'){
//
//         try{
//          window.location=`qrdeliver://autourl=${encodeURIComponent(window.location.toString.replace(/utoken/g,'uhashed'))}`;
//
//         }catch(err){
//
//         }
//         }

                   }

              }else if( params.redirect_status=='failed'|| params.redirect_status=='success'){
var uri=`https://www.qrdeliver.com/qrpay/${this.props.match.params.appid}/${this.props.match.params.qrcode}`;

              window.location.href=uri;
return;
              }
                if(res.res.code.background_image){
                  const wind= window.innerWidth;
                    const height= window.innerHeight;
                        var styles={backgroundPosition:"center",backgroundImage:`url(${res.res.code.background_image})`,backgroundRepeat:res.res.code.background_repeat?"repeat":"no-repeat"};

                    if(wind>height){
                      styles['backgroundSize']='100% auto';
                    }else{
                      styles['backgroundSize']=' auto 100%';
                    }
                    ;
                    document.getElementById('bg').style.backgroundImage = `url(${res.res.code.background_image})`;
                    document.getElementById('bg').style.backgroundSize = styles['backgroundSize'];
                    document.getElementById('bg').style.backgroundRepeat = styles['backgroundRepeat'];
                    document.getElementById('bg').style.backgroundPosition= styles['backgroundPosition'];

                }

                if (!res.res.code.stripe_checkout && paymentMethods[res.res.code.currency.toLowerCase()]) {
                    if(window.debug)console.log('binnst')
                    if (this.props.register.payment_method && paymentMethods[res.res.code.currency.toLowerCase()].indexOf(this.props.register.payment_method) !== -1) {

                    } else if (res.res.code.preferred_method && paymentMethods[res.res.code.currency.toLowerCase()].indexOf(res.res.code.preferred_method) !== -1) {
                        this.props.isRegister(['payment_method', res.res.code.preferred_method]);
                    } else {
                        this.props.isRegister(['payment_method', paymentMethods[res.res.code.currency.toLowerCase()][0]]);
                    }

                }
me.props.register.QR_DELIVER=res.res.code;
                if(window.debug)console.log(params,res.res.code)
                if(me.props.register.QR_DELIVER.options){
                  if(params.option){
                    const arr=me.props.register.QR_DELIVER.options.split(",");


                    arr.map((item,index)=>{
                    if(window.debug)console.log(md5(me.props.register.QR_DELIVER._id+item),"ihtm")
                      if(md5(me.props.register.QR_DELIVER._id+item)==params.option){
                            if(window.debug)console.log("match option");
                            me.props.isRegister(['option', index]);
                      }

                    })
                  }

                }
                //

                if (!res.res.order) {
                if(this.props.register.QR_DELIVER.shipping_type==2){
 if ((this.props.register.QR_DELIVER.pickup_enabled||this.props.register.QR_DELIVER.delivery_enabled)&&this.props.register.QR_DELIVER.preferred_time){

if(this.canDeliver()&&this.canPickup()){
     this.props.isRegister(['shop_closed', true]);
 //return;
}
}
}
                    me.props.isRegister(['cart', {products: {}}]);
                    if (storageItem && storageItem.cart) {
                        me.props.isRegister(['cart', storageItem.cart]);
                    }

                    //

                    serverRequest('post', `qrpayproducts/${me.props.register.QR_DELIVER.app}/${me.props.register.QR_DELIVER._id}`, {token: me.props.register.captcha_token}, (res) => {

                        ReactGA.ga('merchant.send', 'event',`qrpayproducts/${me.props.register.QR_DELIVER.app}/${me.props.register.QR_DELIVER._id}`, 'menu_loaded');
                        me.props.isRegister(['loading', 0]);
                        me.props.isRegister(['step', 0]);

                        if (res.res) {
if(!res.res.products){
res.res.products=[];
}
//console.log(window.product)
  if(window.product){
                        var exists=false;
                        for(var i=0;i<res.res.products.length;i++){

                        if(window.product._id==res.res.products[i]._id){

                        exists=true;
                        break;
                        }
                        }

                        if(!exists)res.res.products.unshift(window.product)
                        console.log("whinsssssss")

                        }
                                            if(params.option){

                                              res.res.products.map((item,index)=>{
                                                const arr=item.options.split(",");


                                                      arr.map((pitem,pindex)=>{
                                                        if(md5(item._id+pitem)==params.option){
                                                              var json = JSON.parse(JSON.stringify(me.props.register.cart));
                                                              if (!json.products[item._id]) {
                                                                  json.products[item._id] = item;
                                                                  json.products[item._id].qty=0;
                                                              }

                                                                  json.products[item._id].option =pindex;

                                                              me.props.isRegister(['cart', json]);

                                                              if(window.debug)console.log("match product option",me.props.register.cart,pindex)
                                                        }

                                                      })

                                              })



                                            }



                                const bkpProducts=JSON.parse(JSON.stringify(res.res.products));
                                 me.props.isRegister(['origin_products', res.res.products]);
                                 me.props.isRegister(['categories', res.res.categories]);
                             var json = JSON.parse(JSON.stringify(me.props.register.cart));
                             const newcart={};
                                                Object.keys(json.products).map((item)=>{
                                                            const citen=json.products[item];
 if(window.debug)console.log(citen.qty,"citroem<<<<<<<<<<")


                                                        res.res.products.map((p)=>{
                                                        if(p._id==item){
                                                        newcart[p._id]=p;
                                                        newcart[p._id].qty=json.products[item].qty
                                                        newcart[p._id].option =json.products[item].option

                                                        }
  if(citen.subid){
  console.log(p._id,citen.subid,p._id==citen.subid)
                                                            if(p._id==citen.subid){

                                                                const rvert=JSON.parse(JSON.stringify(p))
                                                                    rvert._id=citen._id;
                                                                rvert.subid=p._id;
                                                                bkpProducts.push(rvert)

                                                            }
 }
                                                        })






                                                })
                               me.props.isRegister(['products', bkpProducts]);
                              // json.products=newcart;
                                //me.props.isRegister(['cart',json]);
                               if(window.debug)console.log("stop",res.res.headers)
                            me.filterMenus(res.res.headers)
                            if(res.res.msg&&res.res.msg=='captcha_is_not_correct'){
                              //  window.location.reload();
                            }

                        }


                    });

                } else {
                    me.props.isRegister(['loading', 0]);
                }

            } else if(res.res&&res.res.msg){


            me.props.isRegister(['shop_msg', res.res.msg]);
                setTimeout(()=>{
                    window.location = 'https://www.qrdeliver.com';
                },10000)
            } else{
              //  window.location = 'https://www.qrdeliver.com';
            }
        });
    }
    renderOrders(){
 const storageItem = JSON.parse(window.localStorage.getItem(this.props.register.QR_DELIVER._id+"_orders"));
if(!storageItem){

return null;
}

        return(
<div className="order-holder">
          <div className="orders" onClick={()=>{



          this.props.isRegister(['viewOrders',!this.props.register.viewOrders])
          }}>{getKey(this.props.register.lang,'orders')} <div className={"material-icons orderslabel"}>{!this.props.register.viewOrders?'arrow_drop_down':'arrow_drop_up'}</div></div>



</div>

)
    }

    showOrders(count){
            try{
                const storageItem = JSON.parse(window.localStorage.getItem(this.props.register.QR_DELIVER._id+"_orders"));
if(!storageItem){

return null;
}
                if(!storageItem.orders){
                storageItem.orders={};
                 }
if(storageItem.orders){

return this.getOrdersItems(storageItem.orders)
             return  Object.keys(storageItem.orders).map((item) => {
try{
  const order=storageItem.orders[item].order;
  if(count)return 1;
                        return <MenuItem>{order.order.increment_id}</MenuItem>
}catch(err){
if(window.debug)console.log(storageItem.orders[item]);
return null;
}




                });
            }

            }catch(err){
if(window.debug)console.log(err)

            }
return 0;


    }
    addOrder(order){
    if(!order.order)return;
        const cart_order=order.order;
                try{


                var storageItem = JSON.parse(window.localStorage.getItem(order.code._id+'_orders'));
                if(!storageItem){
                storageItem={};
                 }
                if(!storageItem.orders){
                storageItem.orders={};
                 }

                storageItem.orders[cart_order._id]=order;
                window.localStorage.setItem(order.code._id+'_orders',JSON.stringify(storageItem));

                if(window.debug)console.log(storageItem.orders)


}catch(err){

if(window.debug)console.log(err,'adderror')
}


    }
    componentWillUnmount(){



    clearInterval(this.checkorder);

    }
     deliveryNativeUpdate(cb){

     if(window.nativeqrdeliver){

cb();
     }else{
          if ("geolocation" in navigator) {
             if(window.debug)console.log("Available");
            navigator.geolocation.getCurrentPosition(function(position) {
                  if(window.debug)console.log("Latitude is :", position.coords.latitude);
                  if(window.debug)console.log("Longitude is :", position.coords.longitude);
                  window.delivery_gps=`${position.coords.latitude},${position.coords.longitude}`;
                  cb();
                },(err)=>{

                });
cb();

            } else {
             cb();
             if(window.debug)console.log("Not Available");
        }


     }



     }


    deliveryUpdate(shipped){
//    if(this.props.register.rand){
//
//window.delivery_gps="52.4913473,4.5942088";
//}else{
//
//
//window.delivery_gps='52.3726582,4.8352937';
//}
//
//this.props.isRegister(['rand', !this.props.register.rand])
this.deliveryNativeUpdate(()=>{
if(!this.props.register.last_post)return;
this.props.register.last_post.long_delivery_token=this.props.register.long_delivery_token;
this.props.register.last_post.delivery_token=undefined;

if(window.delivery_gps){
this.props.register.last_post.lat=Number(window.delivery_gps.split(',')[0]);
this.props.register.last_post.lon=Number(window.delivery_gps.split(',')[1]);

}

if(window.estimation){
this.props.register.last_post.estimation=window.estimation;

}

           const post=JSON.parse(JSON.stringify(this.props.register.last_post));

if(shipped){
            post.shipped=true;

        }
      serverRequest('post', `fooiappmobile/${this.props.match.params.appid}/${this.props.match.params.qrcode}`, post, (res) => {

                if(res.res.msg&&res.res.msg=='captcha_is_not_correct'){

window.location.reload();

                }

//this.props.register.order.courrier_gps=window.delivery_gps;
//
//const map = useMap();
//  map.panTo(this.props.register.order.courrier_gps);
 //window.scope_instance.center=window.delivery_gps.split(",");

//
//
//window.scope_instance.getWaypoints();
//window.scope_instance.setWayPoints([
//
//      window.L.latLng(Number(this.props.register.QR_DELIVER.delivery_from_gps.split(',')[0]),Number(this.props.register.QR_DELIVER.delivery_from_gps.split(',')[1])),
//       window.L.latLng(Number(this.props.order.gps.lat),Number(this.props.order.gps.lon)),
//    ])
if(window.debug)console.log(window.scope_instance,"mahm")
if(res.res.order){
this.addOrder(res.res);
this.props.isRegister(['order', res.res.order]);
}
try{
if(this.props.register.map){
if(window.debug)console.log("mapfly")
this.props.register.map.target.flyTo(res.res.order.courrier_gps.split(","))
}

}catch(err){
if(window.debug)console.log(err,this.props.register.map.target,"<<<<")
}

                })

}).bind(this)


     }
    checkOrder(msg){

        const post=JSON.parse(JSON.stringify(this.props.register.last_post));

      serverRequest('post', `fooiappmobile/${this.props.match.params.appid}/${this.props.match.params.qrcode}`, post, (res) => {

                if(res.res.msg&&res.res.msg=='captcha_is_not_correct'){

window.location.reload();

                }

if( res.res.order){
try{

if(res.res.order.messages.length>0&&(this.props.register.order.messages.length>0&&res.res.order.messages[0][0].date!=this.props.register.order.messages[0][0].date)){
  this.props.isRegister(['show_messages', true])
this.messagesEndRef.current.scrollIntoView({ behavior: "smooth" });

  audio.play();

}else{
if(window.debug)console.log('res.res.order.messages.length>0&&(this.props.register.order.messages.length>0&&res.res.order.messages[0].msg!=this.props.register.order.messages[0].msg')
}
if(this.props.register.order&&this.props.register.order.updatedAt!=res.res.order.updatedAt){
//audio.play();

}
if(res.res.order.messages.length>0&&(this.props.register.order.messages.length==0)){
  this.props.isRegister(['show_messages', true])
this.messagesEndRef.current.scrollIntoView({ behavior: "smooth" });

}else{
if(window.debug)console.log('res.res.order.messages.length>0&&(this.props.register.order.messages.length==0)')
}

}catch(err){


if(window.debug)console.log(err)
}
this.addOrder(res.res);
this.props.isRegister(['order', res.res.order]);
try{
if(this.props.register.map){
if(window.debug)console.log("mapfly")
this.props.register.map.target.flyTo(res.res.order.courrier_gps.split(","))
}

}catch(err){
if(window.debug)console.log(err,this.props.register.map.target,"<<<<")
}
}


      });


    }
    sendMessage(msg){
       this.props.isRegister(['message_sending',true]);
        const post=JSON.parse(JSON.stringify(this.props.register.last_post));

        if(post.merchant_token){
        post.admin_message=msg;
        }else{
        post.message=msg;
        }
      serverRequest('post', `fooiappmobile/${this.props.match.params.appid}/${this.props.match.params.qrcode}`, post, (res) => {
            //var shop=window.localStorage.setItem('user', JSON.stringify(person));
            if(window.debug)console.log(res.res);
            this.props.isRegister(['message_sending',false])
                if(res.res.msg&&res.res.msg=='captcha_is_not_correct'){

window.location.reload();

                }

if( res.res.order){
this.props.isRegister(['order', res.res.order]);
 this.messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
}


      });


    }
    deleteRating(){
     this.props.isRegister(['sending_rate',true])
        const post=JSON.parse(JSON.stringify(this.props.register.last_post));
        post.deleterating=true;


      serverRequest('post', `fooiappmobile/${this.props.match.params.appid}/${this.props.match.params.qrcode}`, post, (res) => {
            //var shop=window.localStorage.setItem('user', JSON.stringify(person));
            if(window.debug)console.log(res.res);
            this.props.isRegister(['sending_rate',false])
                if(res.res.msg&&res.res.msg=='captcha_is_not_correct'){

window.location.reload();

                }

if( res.res.order){
this.addOrder(res.res);
this.props.isRegister(['order', res.res.order]);
this.messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
}


      });





    }
    sendRating(msg){
       this.props.isRegister(['sending_rate',true])
        const post=JSON.parse(JSON.stringify(this.props.register.last_post));
        post.rating=true;
        post.stars=Number((this.props.register.stars/100*5).toFixed(2));

post.nick_name=this.props.register.nick_name;
          post.rating_message=this.props.register.rating_message;


      serverRequest('post', `fooiappmobile/${this.props.match.params.appid}/${this.props.match.params.qrcode}`, post, (res) => {
            //var shop=window.localStorage.setItem('user', JSON.stringify(person));
            if(window.debug)console.log(res.res);
            this.props.isRegister(['sending_rate',false])
                if(res.res.msg&&res.res.msg=='captcha_is_not_correct'){

window.location.reload();

                }

if( res.res.order){
this.addOrder(res.res);
this.props.isRegister(['order', res.res.order]);
 //this.messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
}


      });


    }
    getAutocomplete(){
            findAddress('l',null,null,(results)=>{

            if(window.debug)console.log(results)
            })

    }
    componentWillUnmount() {
  document.getElementById('root').removeEventListener('scroll', this.handleScroll);
}

handleScroll(event) {

            const length=document.getElementsByClassName("productrow").length;
            if(length>0){



            }
            const checkoutbutton=document.getElementById("main-submit");
             const minicart=document.getElementById("mini-cart");


             if(checkoutbutton){

if(window.debug)console.log(checkoutbutton.getBoundingClientRect().top+window.innerHeight-30)
if(checkoutbutton.getBoundingClientRect().top-window.innerHeight-30<0){
minicart.style.top=(checkoutbutton.getBoundingClientRect().top-window.innerHeight)+"px"

}else{


minicart.style.top="30px"
}

             }



            this.resetImg()

//    this.setState({
//      transform: itemTranslate
//    });
}
    componentDidMount() {


//window.localStorage.setItem('something','xxx')

document.getElementById('root').addEventListener('scroll', this.handleScroll.bind(this));
        var me = this;
       // console.clear();
        this.props.history.listen(location => {


            if (this.props.register.loading || this.props.register.checkout) return;
            if (this.props.register.QR_DELIVER && me.props.location.hash == '' && this.props.register.buttoncheckout) {
                if(window.debug)console.log(me.props, "<<mounted", me.props.location.hash == '', me.props.register.buttoncheckout)
                me.props.isRegister(['buttoncheckout', 0])
                me.props.isRegister(['cbuttoncheckout', 1])
            }

            if (this.props.register.QR_DELIVER && me.props.location.hash == '#checkout' && !this.getPayDisabled() && !this.props.register.buttoncheckout) {
                if(window.debug)console.log(me.props, "<<mounted", me.props.location.hash == '', me.props.register.buttoncheckout)
                me.props.isRegister(['buttoncheckout', 1])
                me.props.isRegister(['cbuttoncheckout', 0])
            }

        });
        if(window.debug)console.log(this.props.location.hash == '#checkout', !this.props.register.buttoncheckout, this.props.location.hash, "<<dimount")
        if (this.props.location.hash == '#checkout' && !this.props.register.buttoncheckout) {
            window.location = `/qrpay/${this.props.match.params.appid}/${this.props.match.params.qrcode}`;
        }
        if(window.debug)console.log("mount2")

        if (!this.props.register.QR_DELIVER) {

            // this.props.isRegister(['command',this.start])
            // this.recaptcha.execute();
        } else {

        }
        var count=0;
        waitForWebfonts(fonts, function() {
            // Will be called as soon as ALL specified fonts are available

            me.props.history.listen(location => {

        //&me.props.location.hash

            });
            //if(window.debug)console.log(this.props.location.hash=='#checkout',!this.props.register.buttoncheckout,this.props.location.hash,"<<dimount")
            // if(this.props.location.hash=='#checkout'&&!this.props.register.buttoncheckout){
            //     window.location = `/qrpay/${this.props.match.params.appid}/${this.props.match.params.qrcode}`;
            // }

          count+=1;
            if(count==fonts.length){
                 if(window.nativeqrdeliver&&window.qrdelivertoken){
                           me.props.isRegister(['utoken',window.qrdelivertoken]);
                     me.props.isRegister(['fonts_loaded',1]);

                        me.start();
                    }else{
                    if(window.debug)console.log(")___")
                     me.props.isRegister(['fonts_loaded',1]);


                    }
            }


        });


        //UA-133687885-8
setTimeout(()=>{

const params = queryString.parse(this.props.history.location.search);
 if(!window.nativeqrdeliver){
 const os=getOS();
         if(!window.nativeqrdeliver){
          if(os=='iOS'||os=='Android'){

                    if(params.utoken){
         return window.location=`qrdeliver://autourl=${encodeURIComponent(document.location.toString().replace(/utoken/g,'uhashed'))}`;
//return `qrdeliver://autourl=${encodeURIComponent(document.location.toString().replace(/utoken/g,'uhashed'))}`;
                    }
            }

         }


 }

},0)
    }

    componentDidUpdate(prevProps) {

        if(window.debug)console.log("mount2", this.props.history);
        if (!window.qrdelivertoken&&this.props.register.lang&&this.props.register.lang!=prevProps.register.lang) {
            if(window.nativeqrdeliver)return;
            this.setCaptchaLang(document.getElementById("recaptcha-container"), this.props.register.lang.key.split('_')[0]);

        }
        if(!this.props.register.loading&&window.qrdelivertoken&&window.nativeqrdeliver&&this.props.register.utoken&&!this.props.register.loading&&this.props.register.utoken!=prevProps.register.utoken){
          document.body.style.backgroundColor='transparent';
                                document.documentElement.style.backgroundColor='transparent';
            this.props.isRegister(['utoken',window.qrdelivertoken]);

            // this.start();
        }
        if((this.props.register.loading&&this.props.register.checkout!=prevProps.register.loading)||(this.props.register.checkout&&this.props.register.checkout!=prevProps.register.checkout)){

        document.getElementById("bg").classList.remove("loaded")
        }else if((!this.props.register.loading&&this.props.register.loading!=prevProps.register.loading)||(!this.props.register.checkout&&this.props.register.checkout!=prevProps.register.checkout)){

 document.getElementById("bg").classList.remove("loaded")
        document.getElementById("bg").classList.add("loaded")
        }
    if(this.props.register.show_messages&&this.props.register.show_messages!=prevProps.register.show_messages){


    }
    // if (this.props.register.products&&this.props.register.products!=prevProps.register.products) {
            this.resetImg()
      //  }
if(this.mainproduct&&this.mainproduct.current&&!this.scrolled){



                        this.mainproduct.current.scrollIntoView({ behavior: "smooth" });
                        this.scrolled=true;

     }
    }
    resetImg(){
             const floatsearch=document.getElementById("float-search");
                   const searchinput=document.getElementById("search-input");

          if(floatsearch&&searchinput){



    if(window.debug)console.log(searchinput.getBoundingClientRect().top<0)
    if(searchinput.getBoundingClientRect().top<0){
    floatsearch.style.display="inherit"

    }else{


    floatsearch.style.display="none"
    }

             }

             const els=document.getElementsByClassName("productrow");
            for (var i = 0; i < els.length; i++) {
                const lastItem=document.getElementsByClassName("productrow")[i];
                const img=lastItem.getElementsByTagName("img")[0];

                if(!img)continue;
                if(lastItem.getBoundingClientRect().top-window.innerHeight>0&&lastItem.getBoundingClientRect().top>0){
                lastItem.style.opacity=0;
              //  img.setAttribute('src',lastItem.getAttribute('src'));
             img.style.display='none';
             lastItem.style.contentVisibility='hidden';
                }else{
                lastItem.style.opacity=1;
if(img.getAttribute('src')!=lastItem.getAttribute('src')){

               img.setAttribute('src',lastItem.getAttribute('src'));
               }
             img.style.display='revert';
             lastItem.style.contentVisibility='visible';
                }
            if(window.debug)console.log(window.innerHeight, lastItem.getBoundingClientRect().top-window.innerHeight,lastItem.parentElement.parentElement.offsetTop,'item trans')
            }
    }
    reloadVoucher(p) {
        var me = this;
        const params = queryString.parse(this.props.history.location.search);
        this.props.isRegister(['loading_voucher', true]);


        window.localStorage.setItem(this.props.register.QR_DELIVER._id, JSON.stringify({
            postcode: p.postcode,
            code:this.props.register.QR_DELIVER,
            cart: this.props.register.cart,
            total_amount: this.props.register.total_amount

        }));
        if (this.props.register.QR_DELIVER.voucher == p.voucher) {
            return
        }
        p.utoken=this.props.register.utoken;
//        p.token=this.recaptcha.getResponse();
        ReactGA.ga('merchant.send', 'event', `qrpayproducts/${me.props.match.params.appid}/${me.props.match.params.qrcode}_voucher_loading`, p.voucher);
        ReactGA.ga('qrdeliver.send', 'event', `qrpayproducts/${me.props.match.params.appid}/${me.props.match.params.qrcode}_voucher_loading`, p.voucher);
p.utoken=this.props.register.utoken;

        serverRequest('post', `fooiappmobile/${this.props.match.params.appid}/${this.props.match.params.qrcode}`, p, (res) => {


            if (res.res && res.res.status) {
                me.props.isRegister(['amount', 1]);
                me.props.isRegister(['total_amount', 1 + Number(Config.fee)]);
                const params = queryString.parse(me.props.history.location.search);
                if(window.debug)console.log(params);
                //https://localhost:3000/recharge?client_secret  =src_client_secret_iY68jaTOt9ehUtpN0uSKrMcf&livemode=true&redirect_status=succeeded&source=src_1IJbhaLRDgjS7ksTuMCZSRP2
                if (params.redirect_status) {
                    me.props.isRegister(['status', params.redirect_status])
                }


                //stripePromise = loadStripe(res.res.code.pk);
                me.props.isRegister(['QR_DELIVER', res.res.code]);
            }

            this.props.isRegister(['loading_voucher', false]);


        });
    }
    getMobileLink(){


        const params = queryString.parse(this.props.history.location.search);
        const os=getOS();
         if(window.nativeqrdeliver) return;
        if(os!=='iOS'&&os!='Android')return null;
         if(window.debug)console.log(os,"<<<<<");
     return(
   <div>



                                 <div className={"material-icons phone share"} onClick={() => {

                                    document.location=`qrdeliver://autourl=${encodeURIComponent(document.location.toString())}`;
                                    setTimeout(function(){

                                        if(getOS()=='iOS'){
                                        document.location=`qrdeliver://autourl=${encodeURIComponent(document.location.toString())}`;

                                        }else if(getOS()=='Android'){

                                        document.location=`qrdeliver://autourl=${encodeURIComponent(document.location.toString())}`;

                                        }else{

                                        document.location=`https://www.qrdeliver.com`;

                                        }

                                    },1000)
                                }
                                }>offline_share</div></div> )






    }
    getFooterNative(){

            if(!window.nativeqrdeliver){


            return(
<div>
             <div  className={"home-item"}> {Parser(getKey(this.props.register.lang.shop_data.qrdeliver, 'download_img'))}</div>

</div>

)


            }
return null;
    }
    reload(p) {
        var me = this;
        const params = queryString.parse(this.props.history.location.search);
        this.props.isRegister(['loading', true]);

        ReactGA.ga('merchant.send', 'event', `qrpayproducts/${me.props.match.params.appid}/${me.props.match.params.qrcode}_postcode_loading`, p.postcode);
        ReactGA.ga('qrdeliver.send', 'event', `qrpayproducts/${me.props.match.params.appid}/${me.props.match.params.qrcode}_postcode_loading`, p.postcode);


        window.localStorage.setItem(this.props.register.QR_DELIVER._id, JSON.stringify({
            postcode: p.postcode,

            code:this.props.register.QR_DELIVER,
            cart: this.props.register.cart,
            total_amount: this.props.register.total_amount,
            token: this.props.register.captcha_token
        }));
p.utoken=this.props.register.utoken;
        serverRequest('post', `fooiappmobile/${this.props.match.params.appid}/${this.props.match.params.qrcode}`, p, (res) => {

            ReactGA.ga('merchant.send', 'event', `qrpayproducts/${me.props.register.QR_DELIVER.app}/${me.props.register.QR_DELIVER._id}_postcode_loaded`, p.postcode);
            ReactGA.ga('qrdeliver.send', 'event', `qrpayproducts/${me.props.register.QR_DELIVER.app}/${me.props.register.QR_DELIVER._id}_postcode_loaded`, p.postcode);


            if (res.res && res.res.status) {
                me.props.isRegister(['amount', 1]);
                me.props.isRegister(['total_amount', 1 + Number(Config.fee)]);
                const params = queryString.parse(me.props.history.location.search);
                if(window.debug)console.log(params);
                //https://localhost:3000/recharge?client_secret  =src_client_secret_iY68jaTOt9ehUtpN0uSKrMcf&livemode=true&redirect_status=succeeded&source=src_1IJbhaLRDgjS7ksTuMCZSRP2
                if (params.redirect_status) {
                    me.props.isRegister(['status', params.redirect_status])
                }

               // stripePromise = loadStripe(res.res.code.pk);
                me.props.isRegister(['QR_DELIVER', res.res.code]);
            }
            if (me.props.register.QR_DELIVER.is_menu) {

                me.props.isRegister(['cart', {products: {}}]);
                me.props.isRegister(['step', 0]);
                serverRequest('post',`qrpayproducts/${me.props.register.QR_DELIVER.app}/${me.props.register.QR_DELIVER._id}`, {
                    token: me.props.register.captcha_token,
                    t: 999
                }, (res) => {

                    if (res.res && res.res.products) {


                        me.props.isRegister(['products', res.res.products]);
                          me.filterMenus(res.res.headers)

                    }

                    if (me.props.register.QR_DELIVER.request_postcode) {
                        me.props.isRegister(['postcoderequest', true]);
                    }
                    if (res.res.msg) {
                        me.props.isRegister(['error', res.res.msg])
                    }
                    this.props.isRegister(['loading', false]);

                });
            } else {
                this.props.isRegister(['loading', false]);
            }


        });
    }

    setAmount(val) {
        val = String(val).replace(',','')
if(val.split('.').length>1){
    val = Number(String(val).replace('.',''));
}else{
            val=val*100
}

        this.props.isRegister(['amount', val]);
        this.props.isRegister(['total_amount', Number(val)]);


    }

    getAmount() {

        if (this.props.register.QR_DELIVER.user.fixed) {
            return (this.props.register.QR_DELIVER.user.amount)
        }
        if (this.props.register.amount) {
            return this.props.register.amount
        }
        return 0.00;
    }

    handleScriptCreate() {

    }

    handleScriptError() {

    }

    handleScriptLoad() {

    }

    getNumValue() {
        if (this.props.register.clientnum) {
            return this.props.register.clientnum
        }
        return null;
    }
    getRate() {
        return 0.1;
    }

    currentAction() {

    }

    getCurrency(code) {
        return new Intl.NumberFormat(code.language.replace("_",'-'), {
            style: 'currency',
            currency: this.props.register.QR_DELIVER.user.currency
        }).format(this.props.register.amount);
    }

    getCurrencyAmount(amount,code) {
            if(!code){
            code=this.props.register.QR_DELIVER
            }
        return new Intl.NumberFormat(code.language.replace("_",'-'), {
            style: 'currency',
            currency: code.user.currency
        }).format(amount);
    }

    getAmountField() {
        if (this.props.register.QR_DELIVER.user.fixed) {
            return (<div
                className="form-outline recharge-amount fixed"> {this.getPriceFormated(this.getCartAmount())}
            </div>);
        }
        return (
            <div className="form-outline recharge-amount">

<input disabled={this.props.register.checkout} onChange ={(evt)=>{

  if(this.props.register.amountTimeout){
    clearTimeout(this.props.register.amountTimeout)
  }
  var me=this;
  var evt_=evt;
  evt.persist()
    const time = setTimeout(function(){
          var val=  replaceAmount(evt_.target.value).replace(',','').replace(/ /g,'');


            if(!isNaN(Number(val))){
              val=  String(Number(val).toFixed(2)).replace('.','').replace(/ /g,'');
              if(window.debug)console.log(val);

            }else{
              val = 0.00;
            }


          if(!isNaN(val)){
            me.props.isRegister(['amount',String(val)])
          }else{
            val=0;
            me.props.isRegister(['amount',0])
          }
          evt.target.value=Number(replaceAmount(evt_.target.value)).toFixed(2);
    },1000);
    clearTimeout(this.props.register.amountTimeout);
    this.props.isRegister(['amountTimeout',time])
}
} />

{this.getInc(this.props.register.QR_DELIVER)}
            </div>
        )
    }

    getPriceFormated(amount) {
        if(window.debug)console.log(this.props.register.lang.key)
        return new Intl.NumberFormat(this.props.register.lang.key.split('_')[0], {
            style: 'currency',
            currency: this.props.register.QR_DELIVER.user.currency
        }).format(amount / 100)
    }

    getCountryRestriction(item) {
        if(window.debug)console.log(this.props.register.lang.shop_data.qrdeliver)
        if (item.restrict_shipping) {

            return (
                <div>  {getKey(this.props.register.lang.shop_data.qrdeliver.restricted_shipping_types, item.shipping_type)}
                    <br/>
                    <ReactCountryFlag
                        countryCode={item.shipping_country}/>{this.getCountryName(item.shipping_country)}-{`${item.shipping_city}`}</div>
            )
        }
        return <div>&#127758;</div>;
    }

    getShippingHeader(item) {

        const params = queryString.parse(this.props.history.location.search);
return null;
        if (!this.props.register.buttoncheckout && !this.props.register.menuFlipped && !params.redirect_status && item.restrict_shipping) {
            return (
                <div><ReactCountryFlag
                    countryCode={item.shipping_country}/>{`${item.shipping_country} ${item.shipping_city}`}</div>
            )
        }
        return null;
    }
    filterMenus(menusRes){

    var menus=[];
    var langs=[];
    var currencies=[];
    var countries=[];
     var cities=[];
     var mix={};
 if(window.debug)console.log(menusRes,"shop countroe");
    menusRes.map((item) => {

if(item.locale!=this.props.register.QR_DELIVER.locale&&item.group==this.props.register.QR_DELIVER.group&&item.scope==this.props.register.QR_DELIVER.scope){

      langs.push(item)




      }else{


      menus.push(item)
      }

 if(item.currency!=this.props.register.QR_DELIVER.currency&&item.group==this.props.register.QR_DELIVER.group){
      currencies.push(item)

      }

       if(item.shipping_country!=this.props.register.QR_DELIVER.shipping_country&&item.group==this.props.register.QR_DELIVER.group){
        countries.push(item)

      }
       if(item.shipping_country==this.props.register.QR_DELIVER.shipping_country&&item.shipping_city!=this.props.register.QR_DELIVER.shipping_city&&item.group==this.props.register.QR_DELIVER.group){
        cities.push(item);
      }
      });

 if(window.debug)console.log("????",countries)
   this.props.isRegister(['shop_languages', langs]);
   this.props.isRegister(['menus', menus]);
   this.props.isRegister(['shop_currencies', currencies]);

    this.props.isRegister(['shop_cities', cities]);
    this.props.isRegister(['shop_countries', countries]);
    }
    getMenusItems(ps) {
if(window.debug)console.log('ps',ps,"<< meusssss");
var mix={};
        return ps.map((item) => {
if(mix[`${item.app}_${item.scope}_{item.group}_{item.currency}`]){
return null;
}
mix[`${item.app}_${item.scope}_{item.group}_{item.currency}`]=1;
console.log(`${item.app}_${item.scope}_{item.group}_{item.currency}`)
            return <MenuItem key={item._id}  onClick={() => {

                                    window.location = '/qrpay/' + item.app + "/" + item._id

                                }
                                }>

                    <table width="100%" style={{position:'relative'}}><tbody><tr><td><div className={`productimg ${this.getItemBG(item)}`} style={{width:40,height:40}}>
                                    <img src={item.image} onLoad={()=>{

                                    this.props.isRegister([`img_${md5(item.image)}`, 1]);
if(window.debug)console.log("image loaded",`img_${md5(item.image)}`);

                                    }} alt={item.user.name}/>

                                </div></td><td width="100%">{item.user.name}<br/> {item.currency.toUpperCase() + " " + getSymbolFromCurrency(item.currency)}{this.getCountryRestriction(item)}</td><td><div className="material-icons delivery">
                    shopping_cart_checkout
                </div>{item.locale}</td></tr></tbody></table>
                {/*<div colSpan="3" className="product">
                    <table>
                        <tbody>
                        <tr className="head-bttn" onClick={() => {

                                    window.location = '/qrpay/' + item.app + "/" + item._id

                                }
                                }>
                            <td>
                                <div className={`productimg ${this.getItemBG(item)}`} >
                                    <img src={item.image} onLoad={()=>{

                                    this.props.isRegister([`img_${md5(item.image)}`, 1]);
if(window.debug)console.log("image loaded",`i   mg_${md5(item.image)}`);

                                    }} alt={item.user.name}/>

                                </div>

                            </td>
                            <td className={"productname"}>{item.user.name}
                                <br/> {item.currency.toUpperCase() + " " + getSymbolFromCurrency(item.currency)}{this.getCountryRestriction(item)}
                            </td>
                            <td>
                                <div className={"goto material-icons shop-bttn"} >arrow_circle_right</div>
                            </td>
                        </tr>

                        </tbody>
                    </table>

                </div>*/}
            </MenuItem>


        })
    }
getOrdersItems(ps) {
if(window.debug)console.log('ps',ps,"<< itemmeusssss");


const list =ps;
var sorted = underscore.sortBy(list, function(item){
console.log(item.order.createdAt)
    return - moment(item.order.createdAt); // parse date with moment >> format to UNIX timestamp
});
console.log(sorted)
        return sorted.map((item) => {


            if(window.debug)console.log(item.code.image,"<< itemmeusssss,,",item.order._id);

            return <div key={item.order ._id}>





<SubMenu onMouseEnter={()=>{



}} onClick={() => {

                                    window.location = '/qrpay/' + item.code.app + "/" + item.code._id+"?redirect_status=success&token="+item.order.token

                                }
                                } label={<table onClick={() => {

                                    window.location = '/qrpay/' + item.code.app + "/" + item.code._id+"?redirect_status=success&token="+item.order.token

                                }
                                }>
<tbody>
<tr>
<td>
<div className="material-icons delivery">
                    shopping_cart_checkout
                </div>
                </td>
                <td>
                {moment(item.order.createdAt).format('YYYY-MM-DD')}<br/>
                </td>
                <td>
                {this.getCurrencyAmount(item.order.checkout_session.amount_total/100,item.code)}
                 </td>
                </tr>
                </tbody>
</table>}>

{this.getCheckoutItems(item.order)}



</SubMenu>
</div>




        })
    }
    getShippingInc(item){


     if (item.shipping_costs) {


      return (<code className="vat">inc {this.getPriceFormated(this.props.register.QR_DELIVER.shipping_costs)}</code>);
     }

     return null;


    }

    getInc(item) {
        if (this.props.register.QR_DELIVER.tax) {

            if (this.props.register.QR_DELIVER.tax_included) {
 var tax_amount = Number((item.user.amount * (this.props.register.QR_DELIVER.tax / 100)).toFixed(0));

                if (tax_amount) {

                    return (<code className="vat">inc {this.getPriceFormated(tax_amount)}</code>);
                }
            } else {
                var tax_amount = Number((item.user.amount * (this.props.register.QR_DELIVER.tax / 100)).toFixed(0));

                return (<code className="vat">excl {this.getPriceFormated(tax_amount)}</code>);
            }


        }

    }

    getItemBG(item){

    if(this.props.register[`img_${md5(item.image)}`]){
    if(window.debug)console.log("style set")
    return "loaded"
    }
    return '';
    }
    getTotalStock(item){


        var default_stock=item.stock;

        if(this.props.register.cart&&this.props.register.cart.products){
                Object.keys(this.props.register.cart.products).map((item_)=>{
                    const citem=this.props.register.cart.products[item_];
                        if((citem._id!==item._id&&citem.subid===item._id)){

                                default_stock-=citem.qty;


                        }
//
//                         if((citem._id===item._id)){
//
//
//                                default_stock-=citem.qty;
//
//
//                        }
                })

        }


return default_stock;
    }
    getProducts(ps,subs,itemId,cart) {
if(window.debug)console.log(ps);

        return ps.map((item,index) => {
        if(!cart&&this.props.register.search_qry){

if(item.user.name.toLowerCase().split(this.props.register.search_qry.toLowerCase()).length==1){
return null;
}
}
//
            if (this.props.register.cart.products[item._id] && item.manage_stock && this.props.register.cart.products[item._id].qty > this.getTotalStock(item)) {
                this.props.register.cart.products[item._id].qty = this.getTotalStock(item);
            }
            if(subs&&!item.subid){
            return null;
            }
            if(!subs&&item.subid){
            return null;
            }
            if(subs&&item.subid&&item.subid!==itemId){
            return null;
            }
            const special=item.old_price>0&&item.old_price>item.user.amount;
            const delay=0;
            return  <tr
                       className={"productrow " } ref={ref=>{
                       if(window.product&&item._id==window.product._id){

                       this.mainproduct=ref;
//                       console.log('main ref set');


                       }

                       }}style={{contentVisibility:'hidden',transitionDelay: `${delay}ms`}} src={item.image}key={item._id+'_'+index}>
                <td colSpan="3" className={"product " + ((item.manage_stock && this.getTotalStock(item) <= 0) ? 'outofstock' : '')}>
                    <table>
                        <tbody>
                        <tr>
                             {item.image&&item.image.split('61bebcb3c3b0342fad4f4f7a').length==1&&<td className="productimgtd">

                                 {!cart&&<div className={`productimg ${this.getItemBG(item)}`}  onClick={()=>{

                                        this.props.isRegister([`lightbox`,item]);
                                }}>
                                      <img onLoad={(  )=>{

                                    this.props.isRegister([`img_${md5(item.image)}`, 1]);
if(window.debug)console.log("image loaded",`img_${md5(item.image)}`)
                                    }} alt={item.user.name}/>


                                </div>}
 {cart&&<div className="material-icons cart-delete" onClick={()=>{



const json=JSON.parse(JSON.stringify(this.props.register.cart));
delete json.products[item._id];
item.qty=0;
this.props.isRegister(['cart',json])
this.props.isRegister(['lightbox',null])
 }}>{'delete'}</div>}
                            </td>}
                            {!cart&&<td  colSpan={(item.image&&item.image.split('61bebcb3c3b0342fad4f4f7a').length==1)? 1:2} width="100%"><div className={"productname"}>{item.user.name}</div><div className={"productdesc"}>{item.user.company_desc}</div></td>}
                            {cart&&<td  colSpan={(item.image&&item.image.split('61bebcb3c3b0342fad4f4f7a').length==1)? 1:2} width="100%"><div className={"productname"}>{item.user.name}</div></td>}

                            {<td className="pricetd">{special&&<div className="old-price">{item.old_price>0&&this.getPriceFormated(item.old_price)}</div>}<div className={special?"special-price":""}>{this.getPriceFormated(item.user.amount)}{/*this.getShippingInc(item)*/}{/*this.getInc(item)*/}</div>
<br/>


                            </td>}
                        </tr>

                        <tr>
                            <td colSpan={3}>
                                <table className="productActions">
                                    <tbody>
                                    <tr>
                                            <td colSpan="3">

                                              {this.getOptionsDropdown(item)}
                                            </td>

                                    </tr>
                                    <tr><td height="20"></td></tr>
 <tr>


                </tr>
                                    </tbody>

                                </table>
                            </td>

                        </tr>
                         <tr><td colSpan="3">
                          <table style={{width:'70%'}}>

                          <tbody>


                            <tr>
                            <td >
                            {this.props.register.currentProductGroup&&this.props.register.currentProductGroup_==item._id&&this.props.register.currentProductGroup&&
                            <table className="subholder"><tbody>{this.getOptionsSubGroups(item)}</tbody></table>}
                            </td>

                            </tr>

                          </tbody>

                          </table>
                            </td></tr>

   {cart&&this.getProducts(ps,true,item._id,cart)}
   <tr><td colSpan="3">
    {!cart&&<div className={"material-icons bubbly-button"} style={{float:'right',bottom:14,right:4,fontSize:30,width:40}}onClick={()=>{


  if(item.subGroup&&item.subGroup!=this.props.register.currentProductGroup){
 this.props.isRegister(['currentProductGroup_',item._id]);
   this.props.isRegister(['currentProductGroup',item.subGroup]);
      this.props.isRegister(['carttemp',{}]);

   return;
  }

                        var item_=JSON.parse(JSON.stringify(item));

                        if(!item_.subid){
                        item_.subid=item._id;
                        item_.qty=1;
                        }
                       // item_.qty=1;
                        item_._id=mongoObjectId()+"_"+item._id;
                         var json = JSON.parse(JSON.stringify(this.props.register.cart));
                         json.products[item._id] = {...item,qty:0};


    item_.option=json.products[item._id].option

                                                if (!json.products[item_._id]) {
                                                    json.products[item_._id] = item_;
                                                }
                                                 json.products[item._id] = {...item,qty:0};




                                                 this.props.isRegister(['cart',json]);

                                           this.addSubItems(item);



                    }}>{!this.props.register.currentProductGroup&&item.subGroup? 'add_circle':'add_shopping_cart'}</div>}
                    </td></tr>
                        </tbody>
                    </table>


                    {item.subid&&<div className={"material-icons"} style={{position:'absolute',top:4,right:4,fontSize:30}}onClick={()=>{
var products=JSON.parse(JSON.stringify(this.props.register.products));
                    const cart=JSON.parse(JSON.stringify(this.props.register.cart))

                      delete cart.products[item._id];

                        this.props.isRegister(['cart',cart]);
                        var product_list=[];
                        products.map((pitem)=>{

                            if(pitem._id!==item._id){
                            product_list.push(pitem)
                            }

                        })
                         this.props.isRegister(['products',product_list]);
                    }}>{'delete_sweep'}</div>}



                </td>

            </tr>

        })
    }
    addSubItems(p){

          if(p._id==this.props.register.currentProductGroup_){
            var json = JSON.parse(JSON.stringify(this.props.register.cart));
    var keys=Object.keys(this.props.register.carttemp)
  keys.map((k)=>{
const item=this.props.register.carttemp[k];
 var item_=JSON.parse(JSON.stringify(item));
if(item_.qty>0){
                        if(!item_.subid){
                        item_.subid=item._id;
                        item_.qty=item_.qty;
                        }
                       // item_.qty=1;
                        item_._id=mongoObjectId()+"_"+item._id;

                         json.products[item._id] = {...item,qty:0};


                        item_.option=json.products[item._id].option

                            if (!json.products[item_._id]) {
                                json.products[item_._id] = item_;
                            }
                             json.products[item._id] = {...item,qty:0};

}








    })
this.props.isRegister(['cart',json]);
    }
    this.props.isRegister(['currentProductGroup_',null]);
   this.props.isRegister(['currentProductGroup',null]);
    }
    getSubids(code){






    }
    getOptionsSubGroups(product){

    var headers={};
    var html=[];
    const arr=this.props.register.currentProductGroup.split(",");
this.props.register.products.map((pitem,index)=>{
console.log(arr,arr.indexOf(pitem._id),pitem._id.toString())
                           if(arr.indexOf(pitem._id.toString())!==-1){


                                 if(!pitem.category){

                                pitem.category='  '
                                }
                                if(window.debug)console.log(pitem,"cattie")
                                if (!headers[pitem.category]) {
                                    headers[pitem.category] = [];
                                }
                                if (pitem.category&&headers[pitem.category].length<10) {
                                    headers[pitem.category].push(pitem)
                                }
                           }

})


var karr=Object.keys(headers);

 karr.map((k)=>{

console.log(k)
 html.push(<tr key={k}><td className="subcat" colSpan="5">{k}</td></tr>)
 var col=false;
                     headers[k].map((pitem,index)=>{
                     col=!col;
const arr=this.props.register.currentProductGroup.split(",");

console.log(pitem.category,"catgeor",pitem.category==this.props.register.currentProductGroup)
                            html.push(<tr key={index} ><td width="100%">{pitem.user.name}</td><td>{this.getPriceFormated(pitem.user.amount)}</td><td className="qty"><div className="material-icons" onClick={()=>{


  var item_=JSON.parse(JSON.stringify(pitem));
                                        if(!this.props.register.carttemp[pitem._id]){
                                        this.props.register.carttemp[pitem._id]=item_;
                                        }
                                        if(!this.props.register.carttemp[pitem._id].qty){
                                        this.props.register.carttemp[pitem._id].qty=0;
                                        }else{

                                        if(this.props.register.carttemp[pitem._id].qty-1>=0){

                                        this.props.register.carttemp[pitem._id].qty -=1;
                                        }

                                        }
                                        if( this.props.register.carttemp[pitem._id].qty>pitem.stock){

                                        this.props.register.carttemp[pitem._id].qty=pitem.stock;
                                        }

                                    this.props.isRegister(['carttemp',this.props.register.carttemp]);



    }}>indeterminate_check_box</div></td><td className="qty"><input value={this.props.register.carttemp&&this.props.register.carttemp[pitem._id]?this.props.register.carttemp[pitem._id].qty:0} onChange={(e)=>{
                                        var item_=JSON.parse(JSON.stringify(pitem));
                                        if(!this.props.register.carttemp[pitem._id]){
                                        this.props.register.carttemp[pitem._id]=item_;
                                        }
                                        if(!this.props.register.carttemp[pitem._id].qty){
                                        this.props.register.carttemp[pitem._id].qty=0;
                                        }
                                        if(e.target.value>0&&e.target.value<=pitem.stock){
                                       this.props.register.carttemp[pitem._id].qty=Number(e.target.value)
                                        }else if(e.target.value>0&&e.target.value>pitem.stock){
                                       this.props.register.carttemp[pitem._id].qty=Number(pitem.stock)
                                        }else{
                                        this.props.register.carttemp[pitem._id].qty=0
                                        }
                                    this.props.isRegister(['carttemp',this.props.register.carttemp]);

                            }} onFocus={e => e.target.select()}/>
                            </td><td className="qty"><div className="material-icons" onClick={()=>{


  var item_=JSON.parse(JSON.stringify(pitem));
                                        if(!this.props.register.carttemp[pitem._id]){
                                        this.props.register.carttemp[pitem._id]=item_;
                                        }
                                        if(!this.props.register.carttemp[pitem._id].qty){
                                        this.props.register.carttemp[pitem._id].qty=1;
                                        }else{

                                        this.props.register.carttemp[pitem._id].qty+=1;

                                        }
                                        if( this.props.register.carttemp[pitem._id].qty>pitem.stock){

                                        this.props.register.carttemp[pitem._id].qty=pitem.stock;
                                        }

                                    this.props.isRegister(['carttemp',this.props.register.carttemp]);



    }}>add_box</div></td></tr>)


                        })

})
if(html){
return html;
}
return null;
    }
    getOptionsDropdown(product){

          const query = queryString.parse(this.props.history.location.search);

      if(product&&product.options){
          if(!product.is_menu&&!product.individual){


                return (

                    <div className={'optionsHolder'}>
                        <div className="optionsHolderName" onClick={() => {
                            if(this.props.register['select_index_option']){
                              this.props.isRegister(['select_index_option', null])
                              return;
                            }
                            this.props.isRegister(['select_index_option', product._id])
                        }
                        }>
                        {this.getSelectedOption(product)}<div className={'optionHolder'}>{this.getProductOptionsItems(product)}</div>
                    </div>
                    </div>
                )
          }else{

            return (

                <div className={'optionsHolder'}>
                    <div className="optionsHolderName" onClick={() => {
                        if(this.props.register['select_index_option']){
                          this.props.isRegister(['select_index_option', null])
                          return;
                        }
                        this.props.isRegister(['select_index_option', product._id])
                    }
                    }>
                    {this.getSelectedOption(product)}<div className={'optionHolder'}>{this.getProductOptionsItems(product)}</div>
                </div>
                </div>
            )
          }
      }
      return null;
    }
     getSelectedOptionFromIndex(product){
       if(!product.options){

       return '';
       }
        const arr=String(product.options).split(",");

     if(this.props.register.cart.products[product._id]){
     return arr[this.props.register.cart.products[product._id].option]
     }
     return '';
     }
    getSelectedOption(product){
     if(product.is_menu&&!product.show_menu_options){
        return null
      }
      const arr=String(product.options).split(",");

        var cart = {};

        try{
          cart=JSON.parse(JSON.stringify(this.props.register.cart));
        }catch(err){
          cart = {};
        }

        if(product.is_menu||product.individual){
          if(window.debug)console.log("komt here",this.props.register.option)
          if(this.props.register.option){

            return(
            <div className="optionItem">   <div className="optionText">{`${arr[this.props.register.option]}`}{product.show_menu_options&&<div className="material-icons ">
                  arrow_drop_down
              </div>}</div> </div>
            )
          }else if(this.props.register.show_menu_options){
            <div className="optionItem">   <div className="optionText">{`${arr[0]}`}<div className="material-icons ">
                  arrow_drop_down
              </div></div> </div>
          }
        }
      if(cart.products&& cart.products[product._id]&&cart.products[product._id].option){

        return(
        <div className="optionItem">   <div className="optionText">{`${arr[cart.products[product._id].option]}`}<div className="material-icons ">
              arrow_drop_down
          </div></div> </div>
        )
      }else if(arr.length>0){
        return(
        <div className="optionItem"><div className="optionText">{`${arr[0]}`}{this.getOptionDropdown(product)}</div> </div>
        )
      }
      return null;
    }
    getOptionDropdown(product){
      if(product.is_menu&&!product.show_menu_options){
        return null
      }

      return(
        <div className="material-icons ">
              arrow_drop_down
          </div>
      )
    }
    getProductOptionsItems(product){
      if(product.is_menu&&!product.show_menu_options){
        return null
      }
        var cart = {};
        try{
          JSON.parse(JSON.stringify(this.props.register.cart));
        }catch(err){
          cart = {};
        }

        if(this.props.register['select_index_option']!==product._id){
          return null;
        }

      const arr=String(product.options).split(",");


      if(product&&product.options){

          return arr.map((index,item)=>{
            if(index!==''&&String(index).trim()!==''){
              return <div key={index} className="optionItem" onClick={
                ()=>{
                  if(!product.is_menu){

                    var json = JSON.parse(JSON.stringify(this.props.register.cart));
                    if (!json.products[product._id]) {
                        json.products[product._id] = product;
                        json.products[product._id].qty=0;
                    }


                        json.products[product._id].option =item;

                    this.props.isRegister(['cart', json])
                    if(product.is_menu||product.individual){
                        this.props.isRegister(['option', item]);

                      }
                    this.props.isRegister(['select_index_option', null])
                  }else{

                    this.props.isRegister(['option', item])

                    this.props.isRegister(['select_index_option', null])
                  }

                }
              }><div className="optionText">{`${index}`}</div></div>;
            }

          })
      }
      return null
    }
    getCartCount(sku) {
        var cn = 0;
        var products = Object.keys(this.props.register.cart.products);
        products.map((item) => {


            if (sku && item == sku) {
                cn += this.props.register.cart.products[item].qty
            } else {
                cn += this.props.register.cart.products[item].qty
            }


        })
        return cn;
    }

    getHeaders(cart) {

        var headers = {};
        if(!cart){
        this.props.register.products.map((item) => {
        if(item.enabled){

         if(cart&&!this.props.register.cart.products[item._id])return;
        // if(cart&&this.props.register.cart.products[item._id]&&this.props.register.cart.products[item._id].qty==0)return;
            if(!item.category){

            item.category='  '
            }
            if(window.debug)console.log(item,"cattie")
            if (!headers[item.category]) {
                headers[item.category] = [];
            }
            if (item.category) {
                headers[item.category].push(item)
            }
        }


        })
        }else{
          Object.keys(this.props.register.cart.products).map((it) => {
          const item=this.props.register.cart.products[it];
          console.log(item,item.category,it,'<<<<he')
            if(!item.category){

            item.category='  '
            }
            console.log(item,"cattie")
            if (!headers[item.category]) {
                headers[item.category] = [];
            }
            if (item.category&&item.qty>0) {
                headers[item.category].push(item)
            }

        })
console.log(headers,"<<<<he")
        }
       if(window.debug)console.log(headers,'headers')
        return Object.keys(headers).map((index) => {
           if(window.debug)console.log(index, "<<________", headers[index]);
        var match=0;

       if(this.props.register.search_qry){


   headers[index].map((item,index) => {
        if(!cart&&this.props.register.search_qry){

if(item.user.name.toLowerCase().split(this.props.register.search_qry.toLowerCase()).length>1){
match+=1;
}
}
});
 }
 if(this.props.register.search_qry&&match==0&&!cart)return null;
 if(!cart&&this.props.register.filter_category&&this.props.register.filter_category!==index)return;
            return (

                <div className="cat-name " key={index}>

                    {(!cart&&(this.props.register.search_qry&&match>0||!this.props.register.search_qry))&&<div className="category_headers">{index} ({this.props.register.search_qry?match:this.getCatCount(index)})</div>}

                    <table className="productsTable">
                        <tbody>{this.getProducts(headers[index],null,null,cart)}</tbody>
                    </table>

                    {!cart&&!this.props.register.search_qry&&this.getMoreButton(index,headers[index])}

                </div>



            );
        })


    }
    isLoadedCat(key){
var loaded=false
    this.props.register.categories.map((item,index)=>{

     if(item._id==key&&item.loaded){
     loaded=true;

     }


    })
     return loaded;
    }
    getCatCount(key){
    var total=0;
    if(this.props.register.categories){

        this.props.register.categories.map((item,index)=>{

            if(item._id==key){

            total=item.count
            }



        })
    }
    return total;
    }
    getMoreButton(key,headers){
        if(!this.isLoadedCat(key)&&this.getCatCount(key)>headers.length){
if(this.props.register.loading_page){

return (
                <div className="lds-grid">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            )

}
        return <div onClick={()=>{

if(!this.props.register.loading_page){
       this.loadPage(key,this.getCatCount(key),headers)

}


        }} className={'loadmore '+(this.props.register.loading_page?'loading':'')} ><div>{key}</div><div className="material-icons morebutton">
                       pending
                    </div></div>

        }
return null;
    }
    getRealTotal(cat){
    var ll=[];
        this.props.register.products.map((item) => {
            if(!item.category){

            item.category='  '
            }
            if(cat==item.category){

ll.push(item)
            }

        })
        return ll.length;
    }
    getMenuHeaders() {
        var headers = {};
        if(!this.props.register.menus)return null;
        this.props.register.menus.map((item) => {
        if(item.category==''){
        item.category=' '
        }
            if (!headers[item.category]) {
                headers[item.category] = [];
            }
            if (item.category) {
                headers[item.category].push(item)
            }

        })
        if(window.debug)console.log(headers)
        return Object.keys(headers).map((index) => {
            if(window.debug)console.log(index, "<<", headers[index]);
                if(index.trim()==''){
                return this.getMenusItems(headers[index])

                }
            return (

                <SubMenu key={index} label={index}>





                      {this.getMenusItems(headers[index])}


                    </SubMenu>


            );
        })


    }

    getCartProducts() {
        var products = Object.keys(this.props.register.cart.products);
        var totalAmount = 0;
        var me = this;
        var cart = {};
        if(window.debug)console.log(products)
        products.map((item) => {
            cart[item] = this.props.register.cart.products[item].qty;
        })

        return cart;
    }
    getOptions() {
      if(!this.props.register.cart||!this.props.register.cart.products)return null;
        var products = Object.keys(this.props.register.cart.products);
        var totalAmount = 0;
        var me = this;
        var cart = {};
        if(window.debug)console.log(products)
        products.map((item) => {
            cart[item] = this.props.register.cart.products[item].option;
        })

        return cart;
    }
    getCartAmount(reversed, tax_) {
        if(!this.props.register.QR_DELIVER.is_menu){


                        return this.props.register.amount?this.props.register.amount:0;



        }
        var products = Object.keys(this.props.register.cart.products);
        var totalAmount = 0;
        var me = this;
        var taxAmount = 0;
        products.map((item) => {
            if(window.debug)console.log(item, "<<<")
            var p_amount = Number((this.props.register.cart.products[item].user.amount * (me.props.register.QR_DELIVER.tax / 100)));


            if (me.props.register.QR_DELIVER.tax&&me.props.register.QR_DELIVER.tax_included) {

                const exclusivePrice=this.props.register.cart.products[item].user.amount/((me.props.register.QR_DELIVER.tax+100)/100);
                const taxamount=this.props.register.cart.products[item].user.amount-exclusivePrice;
          //  console.log(exclusivePrice,((me.props.register.QR_DELIVER.tax+100)/100))
              totalAmount += this.props.register.cart.products[item].qty * exclusivePrice;
           // totalAmount +=this.props.register.cart.products[item].qty *taxamount;
            taxAmount += this.props.register.cart.products[item].qty *taxamount;


            }else{
            taxAmount += this.props.register.cart.products[item].qty * p_amount;
              totalAmount += this.props.register.cart.products[item].qty * this.props.register.cart.products[item].user.amount;

            }


        })

        if (me.props.register.QR_DELIVER.voucher_valid && me.props.register.QR_DELIVER.voucherData && me.props.register.QR_DELIVER.voucherData.coupon) {
            var amount = 0;
            const cp = me.props.register.QR_DELIVER.voucherData.coupon;
            if(window.debug)console.log(totalAmount, "tax")
            if (cp.amount_off) {
                if (totalAmount > cp.amount_off) {
                    amount = totalAmount - cp.amount_off
                } else {
                    amount = totalAmount
                }
            } else if (cp.percent_off && (taxAmount + totalAmount) * (cp.percent_off / 100) >= 0) {
                amount = (taxAmount + totalAmount) * (cp.percent_off / 100);
            }
            if (reversed) {
                return amount;
            } else {
                totalAmount -= amount;
            }

        }
        if (!this.props.register.pickup&&this.getTotalShippingCosts()>0) {
            totalAmount += this.getTotalShippingCosts(); //this.props.register.QR_DELIVER.shipping_costs
        }
        if(window.debug)console.log(totalAmount, "tax", taxAmount)
        if (tax_) return taxAmount;
        return totalAmount + taxAmount;
    }

    getTotalCartCount() {
        var products = Object.keys(this.props.register.cart.products);
        var totalAmount = 0;
        var me = this;
        var totalItems = 0;
        products.map((item) => {

            totalItems += this.props.register.cart.products[item].qty;


        })

        return totalItems;
    }


    getShippingsField() {
        if (this.props.register.QR_DELIVER.restricted_shipping) {

            if (this.props.register.QR_DELIVER.city) {

            }
            return (<div>
                    <ReactCountryFlag
                        countryCode={this.props.register.QR_DELIVER.shipping_country}/>{`${this.props.register.QR_DELIVER.shipping_country} ${this.props.register.QR_DELIVER.shipping_city}`}
                </div>
            )
        }

        return null;
    }

    getStreetAndNum() {

        if (this.props.register.QR_DELIVER.shipping_required&&this.props.register.QR_DELIVER.stripe_checkout) {
            return (


                <div>{this.getShippingsField()}
                    <div
                        className={'label'}>{getKey(this.props.register.lang.shop_data.qrdeliver, 'shipping_address')} </div>

                    {this.getCountry()}
                    {this.getHelper()}

                    <input className="housenr-field"
                           value={this.props.register.house_number ? this.props.register.house_number : ""}
                           onChange={(evt) => {
                               this.props.isRegister(['house_number', !isNaN(evt.target.value.replace(/^\D+/g, '')) ? Number(evt.target.value.replace(/^\D+/g, '')) : null])

                           }} placeholder={getKey(this.props.register.lang.shop_data.qrdeliver, 'house_number_hint')}
                           name="huisnumber" type="text" pattern="[0-9]"/>


                    <input className="housenr-field addition"
                           value={this.props.register.house_number_addition ? this.props.register.house_number_addition : ""}
                           onChange={(evt) => {
                               this.props.isRegister(['house_number_addition', evt.target.value])

                           }}
                           placeholder={getKey(this.props.register.lang.shop_data.qrdeliver, 'house_number_addtion_hint')}
                           name="huisnumber-addtion" type="text" pattern="[0-9a-zA-Z]*"/>


                    {this.getRestrictedPostcode()}
                    {this.getRestrictedCity()}

                </div>)
        }
    }

    getRestrictedPostcode() {
        if (this.props.register.QR_DELIVER.shipping_required && !this.props.register.QR_DELIVER.restricted_shipping) {
            return (
                <input className="postcode-field addition"
                       value={this.props.register.postcode ? this.props.register.postcode : ""}
                       onChange={(evt) => {
                           this.props.isRegister(['postcode', evt.target.value])

                       }}
                       placeholder={getKey(this.props.register.lang.shop_data.qrdeliver, 'postcode_hint')}
                       name="postcode-addtion" type="text" pattern="[0-9a-zA-Z]*"/>
            )
        }
        return null
    }

    getRestrictedCity() {
        if (this.props.register.QR_DELIVER.shipping_required && !this.props.register.QR_DELIVER.restricted_shipping) {
            return (
                <input className="postcode-field city"
                       value={this.props.register.city ? this.props.register.city : ""}
                       onChange={(evt) => {
                           this.props.isRegister(['city', replaceStr(evt.target.value,14)])

                       }}
                       placeholder={getKey(this.props.register.lang.shop_data.qrdeliver, 'city_hint')}
                       name="city-field" type="text" pattern="[0-9a-zA-Z]*"/>
            )
        }
        return null
    }

    getComments() {

        return (
            <div>
                <div>{getKey(this.props.register.lang.shop_data.qrdeliver, 'comments')} </div>

                <textarea className="street-field comments "
                          value={this.props.register.comments ? this.props.register.comments : ""} onChange={(evt) => {
                    this.props.isRegister(['comments', replaceStr(evt.target.value,100)])

                }} placeholder={getKey(this.props.register.lang.shop_data.qrdeliver, 'comments_hint')} name="comments"
                          type="text" />

            </div>)

    }

    getPhone() {
        if (!this.props.register.checkout&&!this.props.register.stripe_checkout && this.props.register.buttoncheckout) {
            if (this.props.register.QR_DELIVER.phone_required) {
                return (
                    <div>
                        <div
                            className={"label"}>{getKey(this.props.register.lang.shop_data.qrdeliver, 'phone_nr')} </div>

                        <PhoneInput
                            country={this.props.register.QR_DELIVER.shipping_country.toLowerCase()}
                            value={this.props.register.phone ? this.props.register.phone : ''}
                            onChange={phone => this.props.isRegister(['phone', phone])}
                        />
                    </div>)
            }
        }
        return null;
    }

    preReduction() {
        if (this.props.register.QR_DELIVER.voucherData) {
            return (<div
                className={"reduction-label"}>{getKey(this.props.register.lang.shop_data.qrdeliver, 'reduction')}: {this.getPriceFormated(-this.getCartAmount(true))} </div>)
        }
        return null;
    }

    getCurrentVoucher() {
        if (this.props.register.QR_DELIVER.voucher_valid) {
            return (<div className="voucher-check" onClick={() => {
                    var json = JSON.parse(JSON.stringify(this.props.register.QR_DELIVER));
                    json.voucher_valid = false;

                    this.props.isRegister(['QR_DELIVER', json]);
                    this.props.isRegister(['voucher', null]);
                }}>
                    <input disabled={true} className="housenr-field voucher"
                           value={this.props.register.voucher ? this.props.register.voucher : ""} onChange={(evt) => {

                        var me = this;
                        if(window.debug)console.log("????")
                        if (me.props.register.voucher_timeout) {
                            clearTimeout(me.props.register.voucher_timeout);

                        }

                        var timer = setTimeout(function () {
                            me.reloadVoucher({

                                postcode: me.props.register.postcode,
                                voucher: me.props.register.voucher,
                                street_name: me.props.register.street_name,
                                house_number: me.props.register.house_number,
                                house_number_addition: me.props.register.house_number_addition,
                                app: `${me.props.register.QR_DELIVER.app}`,
                                type: 'fooi',
                                qritem: `${me.props.register.QR_DELIVER._id}`,
                                amount: me.getCartAmount(),
                                cart: me.getCartProducts(),
                                options: me.getOptions(),
                                option:me.props.register.option,
                                qty: 1,
                                num: me.props.register.clientnum,
                                postcode: me.props.register.postcode

                            })
                            // me.props.isRegister(['command',()=>{
                            //     me.reloadVoucher({
                            //
                            //         postcode: me.props.register.postcode,
                            //         voucher: me.props.register.voucher,
                            //         street_name: me.props.register.street_name,
                            //         house_number: me.props.register.house_number,
                            //         house_number_addition: me.props.register.house_number_addition,
                            //         app: `${me.props.match.params.appid}`,
                            //         type: 'fooi',
                            //         qritem: `${me.props.match.params.qrcode}`,
                            //         amount: me.getCartAmount(),
                            //         cart: me.getCartProducts(),
                            //         qty: 1,
                            //         num: me.props.register.clientnum,
                            //         postcode: me.props.register.postcode
                            //
                            //     })
                            // }])
                            //me.recaptcha.reset();
                            // me.recaptcha.execute();

                        }, 1000)
                        this.props.isRegister(['voucher_timeout', timer]);
                        this.props.isRegister(['voucher', evt.target.value]);
                    }} placeholder={getKey(this.props.register.lang.shop_data.qrdeliver, 'voucher_hint')}
                           name="huisnumber-addtion" type="text" pattern="[0-9]*"/>
                    <div className="material-icons voucher-icon">
                        flaky
                    </div>
                    <div className={"reduction-amount"}>
                        <div className="material-icons reduction">
                            label
                        </div>
                        {this.preReduction()}</div>
                </div>
            )
        } else {
            return (
                <input ref={this.voucherInput} className="housenr-field voucher"
                       value={this.props.register.voucher ? this.props.register.voucher : ""}
                       onChange={(evt) => {
   this.props.isRegister(['msg',null])
                                this.props.isRegister(['error',null])
                           var me = this;
                           if(window.debug)console.log("????")

                           if (me.props.register.voucher_timeout) {
                               clearTimeout(me.props.register.voucher_timeout);

                           }
                           var timer = setTimeout(function () {
                               me.reloadVoucher({

                                   postcode: me.props.register.postcode,
                                   voucher: me.props.register.voucher,
                                   street_name: me.props.register.street_name,
                                   house_number: me.props.register.house_number,
                                   house_number_addition: me.props.register.house_number_addition,
                                   app: `${me.props.match.params.appid}`,
                                   type: 'fooi',
                                   qritem: `${me.props.match.params.qrcode}`,
                                   amount: me.getCartAmount(),
                                   cart: me.getCartProducts(),
                                     options: me.getOptions(),
                                     option:me.props.register.option,
                                   qty: 1,
                                   num: me.props.register.clientnum,
                                   postcode: me.props.register.postcode

                               })
                               // me.props.isRegister(['command',()=>{
                               //
                               // }])
                               //
                               // me.recaptcha.execute();

                           }, 1000)
                           this.props.isRegister(['voucher_timeout', timer]);
                           this.props.isRegister(['voucher', evt.target.value]);
                       }} placeholder={getKey(this.props.register.lang.shop_data.qrdeliver, 'voucher_hint')}
                       onBlur={(evt) => {

                           var me = this;
                           if(window.debug)console.log("????")
                           if (me.props.register.voucher_timeout) {
                               clearTimeout(me.props.register.voucher_timeout)
                           }
                           var timer = setTimeout(function () {
                               me.reloadVoucher({

                                   postcode: me.props.register.postcode,
                                   voucher: me.props.register.voucher,
                                   street_name: me.props.register.street_name,
                                   house_number: me.props.register.house_number,
                                   house_number_addition: me.props.register.house_number_addition,
                                   app: `${me.props.match.params.appid}`,
                                   type: 'fooi',
                                   qritem: `${me.props.match.params.qrcode}`,
                                   amount: me.getCartAmount(),
                                   cart: me.getCartProducts(),
                                     options: me.getOptions(),
                                     option:me.props.register.option,
                                   qty: 1,
                                   num: me.props.register.clientnum,
                                   postcode: me.props.register.postcode,
                                   token: me.props.register.captcha_token

                               })
                               // me.props.isRegister(['command',()=>{
                               //     me.reloadVoucher({
                               //
                               //         postcode: me.props.register.postcode,
                               //         voucher: me.props.register.voucher,
                               //         street_name: me.props.register.street_name,
                               //         house_number: me.props.register.house_number,
                               //         house_number_addition: me.props.register.house_number_addition,
                               //         app: `${me.props.match.params.appid}`,
                               //         type: 'fooi',
                               //         qritem: `${me.props.match.params.qrcode}`,
                               //         amount: me.getCartAmount(),
                               //         cart: me.getCartProducts(),
                               //         qty: 1,
                               //         num: me.props.register.clientnum,
                               //         postcode: me.props.register.postcode,
                               //         token:me.props.register.captcha_token
                               //
                               //     })
                               // }])
                               //
                               // me.recaptcha.execute();

                           }, 1000)
                           this.props.isRegister(['voucher_timeout', timer]);
                           if (evt.target.value == '') {
                               this.props.isRegister(['voucher_enabled', false])
                           }
                           this.props.isRegister(['voucher', evt.target.value]);

                       }} placeholder={getKey(this.props.register.lang.shop_data.qrdeliver, 'voucher_hint')}
                       name="huisnumber-addtion" type="text" pattern="[0-9]*"/>


            )
        }
    }

    setCaptchaLang(recaptchaContainer, lang) {
        if (!recaptchaContainer) return;
        lang = lang || "en";

        // 1. Search for the ReCaptcha iframe
        const iframeGoogleCaptcha = recaptchaContainer.querySelector('iframe');
if(!iframeGoogleCaptcha)return;
        // 2. Retrieve the current language
        const currentLang = iframeGoogleCaptcha.getAttribute("src").match(/hl=(.*?)&/).pop();

        // 3. Verify if the language that you want to set is different to the current one
        if (currentLang !== lang) {
            // 4. If it is, change it
            iframeGoogleCaptcha.setAttribute(
                "src",
                iframeGoogleCaptcha.getAttribute("src").replace(
                    /hl=(.*?)&/,
                    'hl=' + lang + '&'
                )
            );
        }
    }

    addVoucher() {
        if (this.props.register.checkout || this.props.register.loading) return null

        if (!this.props.register.stripe_checkout) {
            if (this.props.register.voucher_enabled) {

                return (
                    <div>
                        <div>{getKey(this.props.register.lang.shop_data.qrdeliver, 'voucher')} </div>

                        {this.getCurrentVoucher()}
                    </div>)
            } else if (this.props.register.QR_DELIVER.voucher_enabled) {


                return (
                    <div className="voucher-label" onClick={() => {

                            this.props.isRegister(['voucher_enabled', !this.props.register.voucher_enabled]);

                        }
                        }>
                        <div className="material-icons">label</div>
                        <div className="add-voucher-link" >{getKey(this.props.register.lang.shop_data.qrdeliver, 'add_voucher')} </div>


                    </div>)

            }
        }
        return null


    }
    getCountryName(country){
      return Object.keys(currencies).map((item) => {
        if(window.debug)console.log(item==country,currencies[item].name,"<<<<<<<<<<country")
          if (item == country) {

              return currencies[item].name
          }
      });
    return country;
    }
    getCountries() {
        const current = this.props.register.country ? this.props.register.country : this.props.register.QR_DELIVER.shipping_country
        return Object.keys(currencies).map((item) => {
            if (item != current) {

                return (<div key={item} className="cc" onClick={() => {
                    this.props.isRegister(['select_country', false]);
                    this.props.isRegister(['country', item]);


                }}>
                    <div className="drop-country-flag"><ReactCountryFlag
                        countryCode={item}/></div>
                    <div className={"cc-name"}>{currencies[item].name}</div>
                </div>);
            }
        })
        return null;
    }

    getCountryLabel() {
        if (this.props.register.country) {
            return (
                currencies[this.props.register.country].name
            )
        }
        if (this.props.register.QR_DELIVER.shipping_country) {
            return (
                currencies[this.props.register.QR_DELIVER.shipping_country].name
            )
        }
        return getKey(this.props.register.lang.shop_data.qrdeliver, 'select_country')
    }

    getMinimumAmount() {
        if (!this.props.register.checkout && !this.props.register.loading && this.props.register.QR_DELIVER.minimum_amount) {
            return (<div
                className={"minimum-price-amount"}>{getKey(this.props.register.lang.shop_data.qrdeliver, 'minimum_order_amount_required')}: {this.getPriceFormated(this.props.register.QR_DELIVER.minimum_amount)}</div>)
        }
        return null;
    }

    getCountry() {
        if (!this.props.register.QR_DELIVER.restricted_shipping && this.props.register.QR_DELIVER.shipping_required) {
            return (

                <div className={'countryHolder'}>
                    <div className="selectedName" onClick={() => {

                        this.props.isRegister(['select_country', !this.props.register.select_country])
                    }
                    }><ReactCountryFlag
                        countryCode={this.props.register.country ? this.props.register.country : this.props.register.QR_DELIVER.shipping_country}/>{this.getCountryLabel()}
                    </div>
                    <div
                        className={this.props.register.select_country ? 'countriesHolder' : 'countriesHolder closed'}>{this.getCountries()}</div>
                </div>
            )
        }
        return;
    }

    getHelper() {
        if (this.props.register.QR_DELIVER.gg_id) {
            if (this.props.register.street_name) {
                return (<input className="street-field"
                               value={this.props.register.street_name ? this.props.register.street_name : ""}
                               onChange={(evt) => {
                                   this.props.isRegister(['street_name', evt.target.value])

                               }}
                               placeholder={getKey(this.props.register.lang.shop_data.qrdeliver, 'house_number_hint')}
                               name="huisnumber" type="text" pattern="[0-9]"/>
                )
            }
            return (<div><Autocomplete
                apiKey={this.props.register.QR_DELIVER.gg_id}
                style={{
                    width: "90%",
                    padding: '10px',
                    border: '1px solid #000',
                    borderRadius: '4px',
                    fontSize: '14px',
                    fontFamily: 'Nunito'
                }}
                onPlaceSelected={(place) => {
                    if(window.debug)console.log(place)
                    var add = {street: ''};
                    if (place && place.address_components) {

                        place.address_components.map((item) => {
                            if (item.types[0] == 'route') {
                                add.street = item.long_name;
                            }
                            if (item.types[0] == 'street_number') {
                                add.street_number = item.long_name;
                            }
                            if(window.debug)console.log(item.types[0])
                            if (item.types[0] == 'locality') {
                                add.city = item.long_name;
                            }
                            if (item.types[0] == 'postal_code') {
                                add.postal_code = item.long_name;
                            }
                            if (item.types[0] == 'country') {
                                add.country = item.short_name;
                            }
                        })
                        if (add.street) {


                            this.props.isRegister(['street_name', add.street])
                        }
                        if (add.city) {
                            if(window.debug)console.log(add.city, "<><><><><><>!")

                            this.props.isRegister(['city', add.city])
                        }
                        if (add.postal_code) {
                            if(window.debug)console.log(add.postal_code, "<><><><><><>!")

                            this.props.isRegister(['postcode', add.postal_code])
                        }
                        if (add.street_number) {
                            if(window.debug)console.log(add.street_number, "<><><><><><>!")
                            if(window.debug)console.log(add.street_number, "<><><><><><>!")

                            this.props.isRegister(['house_number', add.street_number.toString()])
                        }
                    } else {

                    }


                }}
                inputAutocompleteValue={this.props.register.street_name}
                language={this.props.register.QR_DELIVER.locale}
                options={{

                    types: ["address"],
                    componentRestrictions: {country: this.props.register.country ? this.props.register.country : this.props.register.QR_DELIVER.shipping_country},
                }}

                defaultValue={this.props.register.street_name ? this.props.register.street_name : ''}
            /><input className="street-field"
                     value={this.props.register.street_name ? this.props.register.street_name : ""}
                     onChange={(evt) => {
                         this.props.isRegister(['street_name', evt.target.value])

                     }}
                     placeholder={getKey(this.props.register.lang.shop_data.qrdeliver, 'street_name_hint')}
                     name="street_name" type="text"/></div>)
        } else {
            return (<input className="street-field"
                           value={this.props.register.street_name ? this.props.register.street_name : ""}
                           onChange={(evt) => {
                               this.props.isRegister(['street_name', evt.target.value])

                           }} placeholder={getKey(this.props.register.lang.shop_data.qrdeliver, 'house_number_hint')}
                           name="huisnumber" type="text" pattern="[0-9]"/>
            )
        }
        return null;
    }

    getShippingFields() {
        if (!this.props.register.QR_DELIVER.stripe_checkout) {
            return (<div className="shippingform">

                    {this.getStreetAndNum()}


                </div>
            )
        }
        return null;
    }

    getCardIcon(index) {
        if (index == 'card') {
            return (
                <div className="material-icons cc">
                    credit_card
                </div>
            );

        }
        return null;
    }

    getNameField() {
        if (this.props.register.payment_method == 'eps' || this.props.register.payment_method == 'p24') {
            return (<input className="street-field"
                           value={this.props.register.name ? this.props.register.name : ""}
                           onChange={(evt) => {
                               this.props.isRegister(['name', evt.target.value])

                           }}
                           placeholder={getKey(this.props.register.lang.shop_data.name, 'name_hint')}
                           name="huisnumber" type="text" pattern="[0-9]"/>
            )
        }
        return null;
    }

    getEmailField() {
        if (this.props.register.payment_method == 'eps' || this.props.register.payment_method == 'p24') {
            return (<input className="street-field"
                           value={this.props.register.name ? this.props.register.name : ""}
                           onChange={(evt) => {
                               this.props.isRegister(['name', evt.target.value])

                           }}
                           placeholder={getKey(this.props.register.lang.shop_data.name, 'name_hint')}
                           name="huisnumber" type="text" pattern="[0-9]"/>
            )
        }
        return null;
    }

    getPaymentMethods() {
        if (this.canCheckoutForm() || this.getCartAmount() == 0) return null;
        var ps = [];

        if (paymentMethods[this.props.register.QR_DELIVER.currency]) {
            paymentMethods[this.props.register.QR_DELIVER.currency].map((index) => {
                if(window.debug)console.log(this.props.register.payment_method, "<<___-")
                if (this.props.register.payment_method == index) {


                    ps.push(<div style={{backgroundImage: `url(${background})`} } key={index}
                                 className={`payment-method p-${index}`} onClick={() => {
                        this.props.isRegister(['select_method', !this.props.register.select_method])

                    }
                    }>
                        {this.getCardIcon(index)}
                        <div className={"pm-name"}>{index}</div>
                        <div className="material-icons ">
                            arrow_drop_down
                        </div>
                    </div>);
                }

            })
            if (this.props.register.select_method) {
                paymentMethods[this.props.register.QR_DELIVER.currency].map((index) => {

                    if (this.props.register.payment_method != index) {

                        ps.push(<div style={{backgroundImage: `url(${background})`}} key={index}
                                     className={`payment-method p-${index}`} onClick={() => {
                            this.props.isRegister(['payment_method', index]);
                            this.props.isRegister(['select_method', !this.props.register.select_method])

                        }
                        }>
                            {this.getCardIcon(index)}
                            <div className="material-icons ">
                                arrow_right
                            </div>
                            <div className={"pm-name"}>{index}</div>
                        </div>);
                    }

                })
            }

        }
        return ps
    }

    checkoutAction() {
        var bttn = this;
        var today = new Date();
        bttn.props.isRegister(['checkout', 1])
        ReactGA.ga('qrdeliver.send', 'event', `qrpayproducts/${bttn.props.match.params.appid}/${bttn.props.match.params.qrcode}_checkoutAction`, bttn.getPriceFormated(bttn.getCartAmount()));
        ReactGA.ga('merchant.send', 'event', `qrpayproducts/${bttn.props.match.params.appid}/${bttn.props.match.params.qrcode}_checkoutAction`, bttn.getPriceFormated(bttn.getCartAmount()));
        var time =  today.getHours() + ":" + today.getMinutes()



        serverRequest('post', `qrdeliverpay/${bttn.props.match.params.appid}/${bttn.props.match.params.qrcode}`, {
            app: `${bttn.props.match.params.appid}`,
            type: 'fooi',
            qritem: `${bttn.props.match.params.qrcode}`,
            amount: bttn.getCartAmount(),
            email: bttn.props.register.email,
            cart: bttn.getCartProducts(),
            option:bttn.props.register.option,
              options: bttn.getOptions(),
            qty: 1,
            num: bttn.props.register.clientnum,
            postcode: bttn.props.register.postcode,
            voucher: bttn.props.register.voucher,
            street_name: bttn.props.register.street_name,
            house_number: bttn.props.register.house_number,
            house_number_addition: bttn.props.register.house_number_addition,
            city: bttn.props.register.city,
            email: bttn.props.register.email,
            country: bttn.props.register.country,
            comments: bttn.props.register.comments,
            pickup: bttn.props.register.pickup,
            utoken:bttn.props.register.utoken,
            preferred_from_time: bttn.props.register.preferred_from?bttn.props.register.preferred_from:time,
            pickup_preferred_from_time: bttn.props.register.pickup_preferred_from?bttn.props.register.pickup_preferred_from:time,
        }, (res) => {
            ReactGA.ga('qrdeliver.send', 'event', `qrpayproducts/${bttn.props.match.params.appid}/${bttn.props.match.params.qrcode}_checkoutAction_loaded`, bttn.getPriceFormated(bttn.getCartAmount()));
            ReactGA.ga('merchant.send', 'event', `qrpayproducts/${bttn.props.match.params.appid}/${bttn.props.match.params.qrcode}_checkoutAction_loaded`, bttn.getPriceFormated(bttn.getCartAmount()));


            if (res.res && res.res.session && res.res.session.url) {
                if(window.debug)console.log('res', res.res.session.url, bttn.props.register.postcode);
                window.localStorage.setItem(this.props.register.QR_DELIVER._id, JSON.stringify({
                    postcode: bttn.props.register.postcode,
                    cart: bttn.props.register.cart,

            code:bttn.props.register.QR_DELIVER,
                    total_amount: this.props.register.total_amount
                }));
  bttn.props.isRegister(['loading', 0]);
  //bttn.props.isRegister(['redirect_now', res.res.session.url])
           window.location.href = res.res.session.url;
             if(window.debug)console.log(res.res.session.url)
            if(window.nativeqrdeliver){


                   setTimeout(function(){

                      bttn.props.isRegister(['loading', 0]);
                          bttn.props.isRegister(['checkout', 0]);
                    },2000)

            }

            } else if (res.res && res.res.msg) {
                  bttn.props.isRegister(['checkout', 0])
                bttn.props.isRegister(['error', res.res.msg])
            }


        });
    }

    getPaymentMethodsHolder() {
        if (!this.props.register.buttoncheckout || this.props.register.checkout || this.props.register.loading) return null
        return (
            <div className={`payment-methods `}>
                {this.getPaymentMethods()}
            </div>
        )
    }

    getCheckoutForm() {
        if (!this.props.register.QR_DELIVER.stripe_checkout) {
            return (<Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
                {this.getPaymentMethodsHolder()}
                <CheckoutForm getPriceFormated={this.getPriceFormated.bind(this)} recaptcha={this.recaptcha}
                              getCartProducts={this.getCartProducts.bind(this)}
                              getCartAmount={this.getCartAmount.bind(this)}
                              canCheckoutForm={this.canCheckoutForm.bind(this)} props={this.props}
                              getButtonTitle={this.getButtonTitle.bind(this)}
                              getPayDisabled={this.getPayDisabled.bind(this)}/>
            </Elements>)
        }
        if (!this.props.register.QR_DELIVER.stripe_checkout) return;
        return (<SubmitButton disabled={this.getPayDisabled()} onClick={() => {
            // this.props.isRegister(['command',this.checkoutAction.bind(this)])
            // this.recaptcha.execute();
            this.checkoutAction();
        }}> <input
            type="hidden"
            onChange={() => {

            }}
            value={this.getCartAmount()}
        />
            {this.getButtonTitle()}
        </SubmitButton>)
    }

    getShippingCostsAmount() {
        const params = queryString.parse(this.props.history.location.search);
        if (this.props.register.QR_DELIVER.shipping_required || this.props.register.QR_DELIVER.restricted_shipping||this.props.register.QR_DELIVER.pickup_enabled) {
//className={`shipping-price-amount ${this.canCheckoutForm() ? 'hidden' : ''}`}
            return (<div  >
                {this.props.register.QR_DELIVER.pickup_enabled&&this.getPickup()}
                {this.props.register.QR_DELIVER.delivery_enabled&&this.getpreferredDelivery()}


                {this.getDeliveryTimeMsg()}

                {this.getShippingFields()}
                {this.getComments()}


                <div></div>
            </div>)
        }
        return null;
    }
    getDeliveryTimeMsg(){
      if (this.props.register.QR_DELIVER.pickup_enabled && this.props.register.pickup) {
          return (<div></div>)
      }
      return null;
    }
    getpreferredDelivery()
     {
      if (!this.props.register.QR_DELIVER.shipping_required || !this.props.register.QR_DELIVER.restrict_shipping) {
if(window.debug)console.log(this.props.register.QR_DELIVER.shipping_required ,"_____", !this.props.register.QR_DELIVER.restricted_shipping)
return '';

}
        if (this.props.register.QR_DELIVER.pickup_enabled && this.props.register.pickup) {
            return null
        }
        var today = new Date();
        var time =  today.getHours() + ":" + today.getMinutes()

        if(Number(this.props.register.QR_DELIVER.preferred_from.replace(":",''))>Number(today.getHours() + today.getMinutes())){
          time = this.props.register.QR_DELIVER.preferred_from;
        }
        if (this.props.register.QR_DELIVER.preferred_time) {

            if(window.debug)console.log(this.props.register.preferred_from,"_____________<<")


            return (<div className="preferred-delivery">
{this.props.register.QR_DELIVER.delivery_enabled&&
                <div className={'pickup-check'} >

                    {this.props.register.QR_DELIVER.pickup_enabled&&<div className={'material-icons '} onClick={() => {
                    this.props.isRegister(['pickup', true])
                }}>pin_drop toggle_off</div>}
                    {getKey(this.props.register.lang.shop_data.qrdeliver, 'delivery')}
                     {this.props.register.QR_DELIVER.preferred_from&&this.props.register.QR_DELIVER.preferred_to&&<div>{getKey(this.props.register.lang.data, 'from')} {`${this.props.register.QR_DELIVER.preferred_from} - ${getKey(this.props.register.lang.data, 'to')} ${this.props.register.QR_DELIVER.preferred_to}`}
                </div>}
                </div>
            }
                <div>{getKey(this.props.register.lang.shop_data.qrdeliver, 'preferred_delivery_time')}

                </div>

<input type="time"  className={'dtime'} value={this.getDeliveryTime()} onChange={(val) => {
                      if(window.debug)console.log(val.target.value,">>>",this.props.register.preferred_from)
                        this.props.isRegister(['preferred_from', val.target.value])

                    }
                    } id="appt" name="appt"
       min={this.props.register.QR_DELIVER.preferred_from ? this.props.register.QR_DELIVER.preferred_from : ""}
                    max={this.props.register.QR_DELIVER.preferred_to ? this.props.register.QR_DELIVER.preferred_to : ""}
                     />

                </div>)
        }
    }
    canDeliver(){


        var today = new Date();
       var time_ =  today.getHours() + ":" + today.getMinutes()

        var time=this.props.register.preferred_from ? this.props.register.preferred_from:time_;
         const date=moment().format('YYYY-MM-DD');

          var timeAndDate = moment(date + ' ' + this.props.register.QR_DELIVER.preferred_from);

            var now =moment();
        var duration = moment.duration(now.diff(timeAndDate));
 if(window.debug)console.log(duration,"_____?!<")
       if(this.props.register.QR_DELIVER.shipping_type==2&&duration._milliseconds<0){

              //return true;
        }
        timeAndDate = moment(date + ' ' + this.props.register.QR_DELIVER.preferred_to );
        duration = moment.duration(now.diff(timeAndDate));
        if(this.props.register.QR_DELIVER.shipping_type==2&&duration._milliseconds>0){

         return true;
        }


        return false;;
    }
    canPickup(){


        var today = new Date();
       var time_ =  today.getHours() + ":" + today.getMinutes()

        var time=this.props.register.preferred_from ? this.props.register.pickup_preferred_from:time_;
         const date=moment().format('YYYY-MM-DD');

          var timeAndDate = moment(date + ' ' + this.props.register.QR_DELIVER.pickup_preferred_from);

            var now =moment();
        var duration = moment.duration(now.diff(timeAndDate));
if(window.debug)console.log(duration,"_____<")
       if(this.props.register.QR_DELIVER.shipping_type==2&&duration._milliseconds<0){

            //  return true;
        }
        timeAndDate = moment(date + ' ' + this.props.register.QR_DELIVER.pickup_preferred_to );
        duration = moment.duration(now.diff(timeAndDate));

        if(this.props.register.QR_DELIVER.shipping_type==2&&duration._milliseconds>0){
if(window.debug)console.log(duration,"_____!<")
         return true;
        }


        return false;;
    }
    getDeliveryTime(){

 var today = new Date();
            var time_ =  today.getHours() + ":" + today.getMinutes()

        const time=this.props.register.preferred_from ? this.props.register.preferred_from:time_;
    const date=moment().format('YYYY-MM-DD')
   var timeAndDate = moment(date + ' ' + this.props.register.QR_DELIVER.preferred_from);
            if(window.debug)console.log(timeAndDate)
            var now =moment(date + ' ' + time);
        var duration = moment.duration(now.diff(timeAndDate));

if(window.debug)console.log(duration,time,this.props.register.QR_DELIVER.shipping_type,duration._milliseconds)
        if(this.props.register.QR_DELIVER.shipping_type==2&&duration._milliseconds<0){
if(window.debug)console.log("did change...")
        return this.props.register.QR_DELIVER.preferred_from;
        }
        timeAndDate = moment(date + ' ' + this.props.register.QR_DELIVER.preferred_to );
        duration = moment.duration(now.diff(timeAndDate));
        if(this.props.register.QR_DELIVER.shipping_type==2&&duration._milliseconds>0){
if(window.debug)console.log("did change...")
        return this.props.register.QR_DELIVER.preferred_to;
        }
        return time;
    }
     getPickupTime(){

 var today = new Date();
            var time_ =  today.getHours() + ":" + today.getMinutes()

        const time=this.props.register.pickup_preferred_from ? this.props.register.pickup_preferred_from:time_;
    const date=moment().format('YYYY-MM-DD')
   var timeAndDate = moment(date + ' ' + this.props.register.QR_DELIVER.pickup_preferred_from );
            if(window.debug)console.log(timeAndDate)
            var now =moment(date + ' ' + time);
        var duration = moment.duration(now.diff(timeAndDate));

if(window.debug)console.log(duration,time,this.props.register.QR_DELIVER.shipping_type,duration._milliseconds)
        if(this.props.register.QR_DELIVER.shipping_type==2&&duration._milliseconds<0){
if(window.debug)console.log("did change...")
        return this.props.register.QR_DELIVER.pickup_preferred_from;
        }
        timeAndDate = moment(date + ' ' + this.props.register.QR_DELIVER.pickup_preferred_to );
        duration = moment.duration(now.diff(timeAndDate));
        if(this.props.register.QR_DELIVER.shipping_type==2&&duration._milliseconds>0){
if(window.debug)console.log("did change...")
        return this.props.register.QR_DELIVER.pickup_preferred_to;
        }
        return time;
    }
    getPickup() {
        if (!this.props.register.QR_DELIVER.pickup_enabled) return null
        if (this.props.register.QR_DELIVER.pickup_enabled && (this.props.register.pickup||!this.props.register.QR_DELIVER.delivery_enabled)) {
            var today = new Date();
            var time =  today.getHours() + ":" + today.getMinutes()

            if(this.props.register.QR_DELIVER.preferred_from&&Number(this.props.register.QR_DELIVER.preferred_from.replace(":",''))>Number(today.getHours() + today.getMinutes())){
              time = this.props.register.QR_DELIVER.preferred_from;
            }
            if (this.props.register.QR_DELIVER.pickup_enabled) {
                return (<div><div className="preferred-delivery">

                    <div className={'pickup-check'} >

                        {this.props.register.QR_DELIVER.pickup_enabled&&this.props.register.QR_DELIVER.delivery_enabled&&<div onClick={() => {
                        this.props.isRegister(['pickup', false])
                    }} className={'material-icons '}>delivery_dining toggle_on</div>}
                        {getKey(this.props.register.lang.shop_data.qrdeliver, 'pickup')} {this.props.register.QR_DELIVER.pickup_preferred_from&&this.props.register.QR_DELIVER.pickup_preferred_to&&<div>{getKey(this.props.register.lang.data, 'from')} {`${this.props.register.QR_DELIVER.pickup_preferred_from} - ${getKey(this.props.register.lang.data, 'to')} ${this.props.register.QR_DELIVER.pickup_preferred_to}`}
                </div>}
                    </div>

{this.props.register.QR_DELIVER.preferred_time&&
                    <div
                        className={""}>{getKey(this.props.register.lang.shop_data.qrdeliver, 'preferred_pickup_time')}

                    </div>}


                    {this.props.register.QR_DELIVER.preferred_time&&<input type="time" className="dtime" onChange={(val) => {
                      if(window.debug)console.log(val,">>>", val.target.value,this.props.register.pickup_preferred_from)
                        this.props.isRegister(['pickup_preferred_from', val.target.value])

                    }
                    } id="appt" name="appt"
        min={this.props.register.QR_DELIVER.pickup_preferred_from ? this.props.register.QR_DELIVER.pickup_preferred_from :time}
                        max={this.props.register.QR_DELIVER.pickup_preferred_to ? this.props.register.QR_DELIVER.pickup_preferred_to : ""}
                        value={this.getPickupTime()}
                    />} </div></div>)
            }
        }


    }

    getPayDisabled() {
      if(this.props.register.checkout){
        return true;
      }
       if(this.props.register.QR_DELIVER.shipping_type==2){
        if (!this.props.register.pickup&&this.props.register.QR_DELIVER.delivery_enabled&&this.props.register.QR_DELIVER.preferred_time){

       if(this.canDeliver()){

       return true;
       }
        }else {
        if(window.debug)console.log("hiphop")

         if(this.canPickup()){

       return true;
       }
        }


}
  if(window.debug)console.log(" drama2",this.props.register.QR_DELIVER.shipping_type)

      if(!this.props.register.QR_DELIVER.is_menu){
        if(window.debug)console.log(this.props.register.amount)
            if(this.props.register.amount<=0){
              return true;
            }
            return false;
      }

        const amount = this.getCartAmount();
        if(window.debug)console.log(this.getCartCount(), '<<<<<')
        if (this.props.register.QR_DELIVER.voucher_valid && this.props.register.QR_DELIVER.voucherData && amount == 0 && Object.keys(this.props.register.cart.products).length > 0) {

            if (!this.props.register.buttoncheckout) return false;
            if (this.getCartCount() == 0) return true;
            if (this.props.register.QR_DELIVER.shipping_required && !this.props.register.postcode) return true;
            if (this.props.register.QR_DELIVER.shipping_required && !this.props.register.house_number) return true;
            if (this.props.register.QR_DELIVER.shipping_required && !this.props.register.street_name) return true;
            return false;
        }
        if (this.props.register.checkout || this.props.register.loading_voucher || Object.keys(this.props.register.cart.products).length == 0 || amount == 0 || amount < this.props.register.QR_DELIVER.minimum_amount) {


            return true;
        }
        if(window.debug)console.log(this.props.register.QR_DELIVER.postcode, this.props.register.street_name, 'slurf', amount)
        if (this.getCartCount() == 0) return true;
        if (!this.props.register.buttoncheckout) return false;
        if (this.props.register.QR_DELIVER.shipping_required && !this.props.register.postcode) return true;
        if (this.props.register.QR_DELIVER.shipping_required && !this.props.register.house_number) return true;
        if (this.props.register.QR_DELIVER.shipping_required && !this.props.register.street_name) return true;
        if (this.props.register.QR_DELIVER.phone_required && !this.props.register.phone) return true;
        if (!this.props.register.QR_DELIVER.stripe_checkout && !this.props.register.name) return true;
        if (!this.props.register.QR_DELIVER.stripe_checkout && (!this.props.register.email || !this.emailValidation(this.props.register.email))) return true;



        return false;
    }

    canCheckoutForm() {
        const amount = this.getCartAmount();
        if(window.debug)console.log("????_")

        if (this.props.register.QR_DELIVER.voucher_valid && this.props.register.QR_DELIVER.voucherData && amount == 0 && Object.keys(this.props.register.cart.products).length > 0) {
            if (!this.props.register.buttoncheckout || this.props.register.checkout) {
                return true;
            }

            return false;
        }
        if (!this.props.register.buttoncheckout || this.props.register.checkout || Object.keys(this.props.register.cart.products).length == 0 || amount == 0 || amount < this.props.register.QR_DELIVER.minimum_amount) {


            return true;
        }

        return false;
    }

    getName() {
        if (this.props.register.checkout) {
            return null
        }
        if (!this.props.register.stripe_checkout && this.props.register.buttoncheckout) {


            return (
                <div className="name-holder">
                    <div
                        className="label">{getKey(this.props.register.lang.shop_data.qrdeliver, 'name_and_lastname')}</div>
                    <input className="street-field name"
                           value={this.props.register.name ? this.props.register.name : ""}
                           onChange={(evt) => {
                               this.props.isRegister(['name', evt.target.value])

                           }}
                           placeholder={getKey(this.props.register.lang.shop_data.name, 'name_hint')}
                           name="name" type="text" pattern="[0-9]"/>
                    <input className="street-field lastname"
                           value={this.props.register.lastname ? this.props.register.lastname : ""}
                           onChange={(evt) => {
                               this.props.isRegister(['lastname', evt.target.value])

                           }}
                           placeholder={getKey(this.props.register.lang.shop_data.qrdeliver, 'lastname_hint')}
                           name="lastname" type="text" pattern="[0-9]"/></div>
            )
        }
        return null;
    }

    emailValidation(email) {
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!email || regex.test(email) === false) {

            return false;
        }
        return true;
    }

    getEmail() {
        if (this.props.register.checkout) {
            return null
        }
        if (!this.props.register.stripe_checkout && this.props.register.buttoncheckout) {

            return (
                <div className="email-holder">
                    <div className="label">{getKey(this.props.register.lang.shop_data.qrdeliver, 'email')}</div>
                    <input className="street-field email"
                           value={this.props.register.email ? this.props.register.email : ""}
                           onChange={(evt) => {

                               this.props.isRegister(['email', evt.target.value])

                           }}
                           placeholder={getKey(this.props.register.lang.shop_data.qrdeliver, 'email_hint')}
                           name="email" type="text"/>
                </div>

            )


        }
    }

    getTotalButton() {
        const params = queryString.parse(this.props.history.location.search);
        if (this.props.register.loading || !this.props.register.cart || this.props.register.menuFlipped ||
            this.props.register.QR_DELIVER.request_postcode ||
            !this.props.register.products ||
            this.props.register.products.length == 0) return null;
        if (params.redirect_status) {
            return null;
        }
const ps=Object.keys(this.props.register.cart.products);
var count=0;
ps.map((k,ind)=>{


if(this.props.register.cart.products[k].qty>0){

count+=this.props.register.cart.products[k].qty;
}
})

        return (
            <div className={`checkout-form ${this.props.register.minicheckout?'':''}`}  id={"main-submit"} ref={this.deliveryBox} onClick={() => {
                if (this.props.register.climateinfo) {
                    this.props.isRegister(['climateinfo', 0])
                }
            }}>
            {<div className="title-container" >
                <h1 className="title checkout-mini"  >{getKey(this.props.register.lang,'checkout')}</h1></div>}
             {this.props.register.minicheckout&&<div onClick={()=>{


                this.props.isRegister(['minicheckout',false]);
                 this.resetImg();
                       this.productList.current.scrollIntoView({ behavior: "auto" });
this.props.isRegister(['show_minis',false])
this.props.isRegister(['lightbox',null])
                }} className={"material-icons checkout-close"}>arrow_back</div>}
 {!this.props.register.minicheckout&&<div onClick={()=>{

                             this.productList.current.scrollIntoView({ behavior: "auto" });
                             this.searchfield.current.focus()


                    }}


                    className={'material-icons foating-search'} id={"float-search"} >search</div>}
                    <div className="mini-cart-holder">
                 <div className="gear-count"><div className={'material-icons'} style={{fontSize:40}} onClick={() => {
const me=this;

this.props.isRegister(['minicheckout',true])
this.props.isRegister(['show_minis',!this.props.register.show_minis])
setTimeout(()=>{
    me.deliveryBox.current.scrollIntoView({ behavior: "smooth" })
   },300)
//this.resetImage()

                     }} >settings</div><div className="count">{count}</div></div>
                     {this.props.register.show_minis&&<div className="floating-cart">{this.getCartItems()}</div>}

                     </div>


                {this.getName()}
                {this.getEmail()}
                <div className="phoneform">
                    {this.getPhone()}
                </div>

                  {this.getOptionsDropdown(this.props.register.QR_DELIVER)}




                {this.getShippingCostsAmount()}

                <div className={"details"}>
                  {this.getShippingMsg()}
                   {this.getVoucherHolder()}
                  {this.getTaxAmount()}
                  </div>
                   {this.getError()}
                {this.getCheckoutForm()}
                {this.getMinicart()}

                {this.getMinimumAmount()}


            </div>
        )


    }
    getMinicart(){


        if(this.props.register.cart&&this.props.register.cart.products){

const ps=Object.keys(this.props.register.cart.products);
var count=0;
ps.map((k,ind)=>{


if(this.props.register.cart.products[k].qty>0){

count+=this.props.register.cart.products[k].qty;
}
})
                return <div disabled={this.getPayDisabled()} id={"mini-cart"} className={!this.getPayDisabled()?"":"disabled"} onClick={() => {
            // this.props.isRegister(['command',this.checkoutAction.bind(this)])
            // this.recaptcha.execute();
         //   this.checkoutAction();
         if(!this.getPayDisabled()){
const me=this;
this.props.isRegister(['minicheckout',true])
   setTimeout(()=>{
      me.deliveryBox.current.scrollIntoView({ behavior: "smooth" })
   },300)
         }
        }}> <input
            type="hidden"
            onChange={() => {

            }}
            value={this.getCartAmount()}
        />
          {!this.props.register.error&&<div className={'material-icons mini'}>shopping_cart_checkout</div>}<div className="mini-counter">  {count}</div>
          {this.getMiniButtonTitle()}
          <div className={'material-icons cart-button'}>arrow_drop_down</div>
          {this.props.register.error&&<div className={'material-icons mini'} style={{color:"#ed7d31"}}>production_quantity_limits</div>}
        </div>

        }

return null;

    }
    getCartItemsCheckout(){

console.log(Object.keys(this.props.register.cart.products))
   return Object.keys(this.props.register.cart.products).map((k)=>{
const it =this.props.register.cart.products[k]
console.log(it,'cattei',it.qty)
if(!it.subid)return
    return [<tr key={k+"c"}><td width="100%">
    {it.user.name} {this.getSelectedOptionFromIndex(it)}
    </td>
                           <td width="25%" className="qty" colSpan="3">{this.getPriceFormated(it.user.amount*it.qty)}</td>


    </tr>,<tr key={k} >
    <td width="100%"><div onClick={()=>{

                        const item=it;
                        var json = JSON.parse(JSON.stringify(this.props.register.cart));
                                                if (json.products[item._id]) {
                                                    delete json.products[item._id];
                                                     this.props.isRegister(['cart', json])

                                                }


                        }} className="material-icons delete_">{'delete'}</div></td>

    <td className="qty"  onClick={()=>{

                        const item=it;
                        var json = JSON.parse(JSON.stringify(this.props.register.cart));
                                                if (!json.products[item._id]) {
                                                    json.products[item._id] = item;
                                                }


                                                var current = json.products[item._id].qty ? json.products[item._id].qty : 0;
//                                                 Object.keys(this.props.register.cart.products).map((item_)=>{
//                                                    const citem=this.props.register.cart.products[item_];
//                                                        if((citem._id!==item._id&&citem.subid===item._id)){
//                                                                if(citem.qty>=0){
//
//                                                                current=citem.qty;
//                                                                }
//
//                                                        }
//                                                })
                                                if ((current - 1) >= 0) {
                                                    current -= 1;
                                                }
                                                json.products[item._id].qty = Number(current);

                                                this.props.isRegister(['cart', json])
                                                this.props.isRegister(['buttoncheckout', 0]);
                        }}><div className="material-icons">indeterminate_check_box </div></td>
    <td className="qty" width="30">
                       <input label={'amount'}min={this.getTotalStock(it)&&this.getTotalStock(it)>1 ? this.getTotalStock(it) : 1} max={this.getTotalStock(it)&&this.getTotalStock(it)>1 ? this.getTotalStock(it) : 9999} width="100"
                                                   type="number"
                                                   value={this.props.register.cart.products[it._id]&&this.props.register.cart.products[it._id].qty ? this.props.register.cart.products[it._id].qty : this.props.register.step}
onFocus={e => e.target.select()}
                                                   onChange={(e) => {
const item=it;
                                                       var json = JSON.parse(JSON.stringify(this.props.register.cart));
                                                       if (!json.products[item._id]) {
                                                           json.products[item._id] = item;
                                                       }
                                                       if (item.manage_stock && e.target.value >= this.getTotalStock(item)) {
                                                           e.target.value = this.getTotalStock(item);
                                                       }
                                                       if(Number(e.target.value)>0){

                                                       json.products[item._id].qty = Number(e.target.value);
                                                       }else{
                                                       json.products[item._id].qty = 1;

                                                       }
                                                       this.props.isRegister(['cart', json])
                                                       this.props.isRegister(['buttoncheckout', 0]);
                                                   }
                                                   }

                                            /></td>

                        <td width="30" className="qty"><div className="material-icons" onClick={()=>{



      var json = JSON.parse(JSON.stringify(this.props.register.cart));


                                                var current = json.products[it._id].qty ? json.products[it._id].qty : 0;

                                                if(window.debug)console.log(this.getTotalStock(json.products[it._id]), it.manage_stock)
                                                if (it.manage_stock && this.getTotalStock(it) >= (current + 1)) {
                                                    current += 1;
                                                } else if (!it.manage_stock) {
                                                    current += 1;
                                                }

                                                json.products[it._id].qty = Number(current);
                                                this.props.isRegister(['cart', json])
                                                this.props.isRegister(['buttoncheckout', 0]);





    }}>add_box</div></td>
    </tr>]
   })

    }
    getSubGroup(){





    }
    getCartHolder(){
console.log(Object.keys(this.props.register.cart.products))
    return <table className="carttable"><tbody>{this.getCartItemsCheckout()}</tbody></table>


    }
    getCartItems(){

    if (!this.props.register.minicheckout) return null;

        if (!this.props.register.loading && !this.props.register.status && this.props.register.products && this.props.register.products.length && !this.props.register.menuFlipped) {

            return (this.getCartHolder());

        }
        if (!this.props.register.loading) {
            return (<div className="results">
                <div className="form-label noproducts"
                     htmlFor="typeNumber">{getKey(this.props.register.lang.shop_data.qrdeliver, 'no_products')}</div>


            </div>)

        }

        return <div className="loader">{this.getLoader()}</div>;
    }
    getVoucherHolder() {
        if (this.props.register.loading || this.props.register.checkout) return null;
        return (
            <div className="phoneform voucher">

                {this.addVoucher()}
            </div>
        )
    }

    getButtonTitle() {
        if (this.props.register.checkout || this.props.register.loading_voucher) {
            return (this.getLoader(true))

        }
        return (<div className="paytext">
            <div className={'material-icons pay'}>shopping_cart_checkout</div>
            <div className="translated-text-pay"><AnimatedNumber complete={() => {

            }}

                                                                 value={this.getCartAmount()}
                                                                 formatValue={this.getPriceFormated.bind(this)}
            /></div>
        </div>)
    }
getMiniButtonTitle() {
        if (this.props.register.checkout || this.props.register.loading_voucher) {
            return (this.getLoader(true))

        }
        return (<AnimatedNumber className={"mini-count"} complete={() => {

            }}

                                                                 value={this.getCartAmount()}
                                                                 formatValue={this.getPriceFormated.bind(this)}
            />)
    }
    getProductsMenu() {
         if (this.props.register.minicheckout) return null;
        if (this.props.register.precheckout) return null;
        if (this.props.register.menuFlipped) return null;
        if (!this.props.register.loading && !this.props.register.status && this.props.register.products && this.props.register.products.length && !this.props.register.menuFlipped) {

            return (this.getHeaders());

        }
        if (!this.props.register.loading) {
            return (<div className="results">
                <div className="form-label noproducts"
                     htmlFor="typeNumber">{getKey(this.props.register.lang.shop_data.qrdeliver, 'no_products')}</div>


            </div>)
        }

        return <div className="loader">{this.getLoader()}</div>;

    }

    getMenus() {

        if (!this.props.register.status && this.props.register.menus && this.props.register.menuFlipped&& !this.props.register.reviews) {

            return (<div className="shop-menu">{this.renderOrders()}{this.getMenuHeaders()}</div>);

        }

         if (!this.props.register.status && this.props.register.menuFlipped&&this.props.register.reviews) {

            return (<div className="shop-menu">{this.getReviews()}</div>);

        }
        return null;

    }
    getReviews(){
    if(this.props.register.loading_reviews){


            return this.simpleLoader();
    }else if(this.props.register.reviews_data){


           return <div className={'reviews'}>


<div>{getKey(this.props.register.lang.shop_data.qrdeliver, 'Reviews')} ({this.props.register.QR_DELIVER.AverageRating[0].count})</div>

{this.renderReviews()}
<div className="material-icons reload" onClick={()=>{




 this.props.isRegister(['loading_reviews',true]);
 serverRequest('post', `fooiappmobile/${this.props.match.params.appid}/${this.props.match.params.qrcode}?reviews=1`,{},(res) => {




 this.props.isRegister(['reviews_data',res.res.reviews]);
 this.props.isRegister(['loading_reviews',false]);
 });



}}>sync</div>

<button disabled={this.props.register.loading_reviews}
                       className={`SubmitButton order-again`}
                       type="submit"

                       onClick={() => {

                    this.props.isRegister(['menuFlipped', !this.props.register.menuFlipped]);
 this.props.isRegister(['reviews',false]);
 this.props.isRegister(['loading_reviews',false]);
  const me=this;
                        setTimeout(()=>{


                                me.productList.current.scrollIntoView({ behavior: "auto" })

                        },0)
                       }}
                   > {getKey(this.props.register.lang.shop_data.qrdeliver, 'go_back')}
                   </button>
           </div>


    }


    }
    getL(c){return new Intl.DisplayNames(['en'], {type: 'language'}).of(c)}
    getShopLanguages(){
const in_list={};
return this.props.register.shop_languages.map(((shop,index)=>{

var uri=`/${shop.url_key}`;

if(in_list[shop.language.split("_")[0]])return null;
in_list[shop.language.split("_")[0]]=1;
return <MenuItem key={index}><a href={uri} >{this.getL(shop.language.split("_")[0])}</a></MenuItem>


}))

    }
    getShopCities(){
    if(!this.props.register.shop_cities)return null;
const in_list={};
        return this.props.register.shop_cities.map(((shop,index)=>{
var uri=`/${shop.url_key}`;

if(in_list[shop.shipping_city])return null;
in_list[shop.shipping_city]=1;

        return <MenuItem key={index}><a href={uri} >{shop.shipping_city}</a></MenuItem>


        }))

    }
    getShopCurrencies(){
    if(!this.props.register.shop_currencies)return null;
const in_list={};
if(window.debug)console.log(this.props.register.shop_currencies,"<<<currenc")
return this.props.register.shop_currencies.map(((shop,index)=>{
var uri=`/${shop.url_key}`;

if(in_list[shop.currency])return null;
in_list[shop.currency]=1;

return <MenuItem key={index}><a href={uri} >{getSymbolFromCurrency(shop.currency)}{shop.currency}</a></MenuItem>


}))
return null ;
    }
    getShopCountries(){
 if(!this.props.register.shop_currencies)return null;

    if(window.debug)console.log(this.props.register.shop_currencies,"<<<shop_countries")
return this.props.register.shop_countries.map(((shop,index)=>{

var uri=`/${shop.url_key}`;


return <MenuItem key={index}><table><tbody><tr><td><ReactCountryFlag
                        countryCode={shop.shipping_country}/></td><td><a href={uri} >{this.getCountryName(shop.shipping_country)}</a></td></tr></tbody></table></MenuItem>


}))
return null ;
    }
    getWeek(){
        if (this.props.register.menuFlipped) return;
        return(
        <div className={"shop_info"}>
{this.props.register.menus&&this.props.register.menus.length>0&&this.props.register.QR_DELIVER.show_child_menu&&<Menu className="language-main-menu" menuButton={ <div className={'material-icons'} onClick={() => {
//                this.props.isRegister(['reviews',false]);
//                    this.props.isRegister(['buttoncheckout', 0])
                }}>apps
                </div>}>{this.getMenuHeaders()}</Menu>}

          {this.props.register.QR_DELIVER.show_weekschema&&<table className="table table-striped table-responsive-md btn-table">

<thead>
        <tr>
        <td colSpan="2">


        {getKey(this.props.register.lang.data, "opening_hours")}
        </td>

        </tr>
        </thead>
      <tbody>
          {this.returnDays()}
      </tbody>

      </table>}
      {this.props.register.QR_DELIVER.pickup_location&&<table className="table table-striped table-responsive-md btn-table">
        <tbody>

                <tr>
            <td colSpan="2">
                <a href={`https://www.google.com/maps/dir/?api=1&destination=${this.props.register.QR_DELIVER.pickup_location}`} target={"_blank"}>
                    <div className={"plan-trip"}>{this.props.register.QR_DELIVER.pickup_location}</div>
                <div className={"material-icons maps"}>assistant_direction</div></a>
             </td>
             </tr>
             </tbody>
    </table>}
     {this.props.register.QR_DELIVER.phone_number&&<table className="table table-striped table-responsive-md btn-table">
<tbody>

        <tr>
        <td>

           {getKey(this.props.register.lang.data, "phone" )}:

        </td>
            <td>
                   <a href={`tel:${this.props.register.QR_DELIVER.phone_number}`}>{this.props.register.QR_DELIVER.phone_number}</a>
             </td>
             </tr>
               </tbody>
    </table>}
      </div>
        )
    }
    returnDays(){
    if(!this.props.register.QR_DELIVER.week_schema)return null;
                var x_=0;
                return [1,2,3,4,5,6,7].map((day,index)=>{


                  return (
                    <tr key={"day_key"+index} >

    <td>{getKey(this.props.register.lang.data, "wdays_"+day)}</td>
    <td>{!this.props.register.QR_DELIVER.week_schema[String(index+1)].enabled&&getKey(this.props.register.lang.data, 'closed')}{this.props.register.QR_DELIVER.week_schema[String(index+1)].enabled&&<div>{this.props.register.QR_DELIVER.week_schema[String(index+1)].from} - {this.props.register.QR_DELIVER.week_schema[String(index+1)].to}</div>}</td>

  </tr>

                  )




                })
    }

    renderReviews(){
    if(!this.props.register.reviews_data)return null;
        return this.props.register.reviews_data.map((item,index)=>{

                return <LazyLoad  key={index} className={'flip-card flipped'} style={{animationDelay:(Math.round(1*index))+'sec'}}><div  className={'review-item'}>
                <div className={'date'}>{moment(item.updatedAt).format('YYYY-MM-DD')}</div>
                <div className="material-icons review">
                    person
                </div><div className={'nick_name'}>{item.nick_name}</div>
                <div className={'review'}>{item.rating_message}</div>
                <Rating allowHover={false} allowHalfIcon={true} readonly={true} transition={true} initialValue={item.stars} size={30}/>

                </div></LazyLoad >


            })

    }
    getTaxAmount() {
        if (this.props.register.QR_DELIVER.tax && this.getCartAmount(false, true) > 0) {
            return (<div className={"tax-amount"}>

                <div className="material-icons " style={{fontSize:40}}>
                    percent
                </div>
                <div className="add-voucher-link">
                {this.props.register.QR_DELIVER.tax+" "}
                 {this.props.register.QR_DELIVER.tax_name}: {this.getPriceFormated(this.getCartAmount(false, true))}
             </div>
            </div>)
        }
        return null;
    }

    getPostcodeForm() {
        if (this.props.register.checkout) return null;
        if (this.props.register.minicheckout) return null;
        if (this.props.register.buttoncheckout) {
            return (
                <div className="label goback">

                    <div className="label">{getKey(this.props.register.lang.shop_data.qrdeliver, 'checkout')} </div>
                    <span className="material-icons edit-checkout" onClick={() => {
                        this.props.isRegister(['buttoncheckout', 0])
                    }}>
shopping_cart
</span>

                    <div className={'cartCount'} onClick={() => {

                        this.props.isRegister(['buttoncheckout', 0]);

                    }}>{this.getTotalCartCount()}</div>
                </div>
            )
        }
        ;

        if (this.props.register.QR_DELIVER.request_postcode && !this.props.register.QR_DELIVER.postcode && !this.props.register.menuFlipped) {

            return (
                <div className={"postcode-holder"}>
                    <div>{getKey(this.props.register.lang.shop_data.qrdeliver, 'fill_in_postcode')}</div>
                    <input className="postcode-field startscreen"
                           value={this.props.register.postcode ? this.props.register.postcode : ""} onChange={(evt) => {
                        this.props.isRegister(['postcode', replacePostcodeStr(evt.target.value)])

                    }} placeholder={getKey(this.props.register.lang.shop_data.qrdeliver, 'postcode_hint')} name="zip"
                           type="text" pattern="[0-9a-zA-Z]*"/>
                    <div>{this.props.register.QR_DELIVER.msg ? getKey(this.props.register.lang.shop_data.qrdeliver, this.props.register.QR_DELIVER.msg) : ""}</div>
                    <SubmitButton disabled={this.props.register.loading ? true : false} onClick={() => {

                        if (this.props.register.postcode) {
                            this.reload({postcode: this.props.register.postcode})
                        }


                    }}>{this.props.register.loading ? this.getLoader(true) : getKey(this.props.register.lang.shop_data.qrdeliver, 'submit')} </SubmitButton>
                </div>
            )
        }
        return this.getProductsMenu();
    }
    getTemplatorButton(){

      if(!this.props.register.order&&this.props.register.QR_DELIVER.template&&this.props.register.QR_DELIVER.pk.split("test").length>1&& window.nativeqrdeliver){


 return(
        <div className={'material-icons native rnshare'} onClick={()=>{
        const shop=JSON.stringify({shop:this.props.register.QR_DELIVER,products:this.props.register.products});
        window.qrdeliver.postMessage(
      `
      {
        "cmd": "clone",
        "shop": ${shop}
      }
      `
    );


        }}>system_update</div>

        )

    }
    return null;
    }
    getFooter() {
      if(this.props.register.menuFlipped)return null;
        if (this.props.register.QR_DELIVER.footer) {
            return (<div className={'footer'}>{this.props.register.QR_DELIVER.footer}



{this.getTemplatorButton()}


            </div>)
        }
        return null;
    }
    getNativeButton(){
            if(this.props.register.order){
            return null;
            }
            if(!window.nativeqrdeliver){

                return this.getShareLinks();

            }
          if(window.nativeqrdeliver){

        return(
        <div className={'material-icons native rnshare'} onClick={()=>{

        window.qrdeliver.postMessage(
      `
      {
        "cmd": "share",
        "url": "${document.location.toString()}"
      }
      `
    );


        }}>share</div>

        )

        }

        return null


    }
    getShareLinks(){
var uri=`https://www.qrdeliver.com/qrpay/${this.props.match.params.appid}/${this.props.match.params.qrcode}`;
      const params = queryString.parse(this.props.history.location.search);
      if(params.option){
        uri=`https://www.qrdeliver.com/qrpay/${this.props.match.params.appid}/${this.props.match.params.qrcode}/?option=${params.option}`;
      }
if(window.debug)console.log("_______________urls",this.props.match.params);
        if(this.props.match.params.url_key){


        uri=`https://www.qrdeliver.com/${this.props.match.params.url_key}`;
            if(params.option){
                uri=`https://www.qrdeliver.com/${this.props.match.params.url_key}/?option=${params.option}`;
            }
        }

if(this.props.register.QR_DELIVER.url_key){


        uri=`https://www.qrdeliver.com/${this.props.register.QR_DELIVER.url_key}`;
            if(params.option){
                uri=`https://www.qrdeliver.com/${this.props.register.QR_DELIVER.url_key}/?option=${params.option}`;
            }
        }


      return(
        <div className="share-links">

                    <EmailShareButton media={this.props.register.QR_DELIVER.image} url={uri}><EmailIcon disabled={false} size={32} round={true}  /></EmailShareButton>
                      <FacebookShareButton media={this.props.register.QR_DELIVER.image} url={uri} ><FacebookIcon size={32} round={true} /></FacebookShareButton>
                        <HatenaShareButton media={this.props.register.QR_DELIVER.image} url={uri}><HatenaIcon size={32} round={true}  /></HatenaShareButton>

                          <InstapaperShareButton media={this.props.register.QR_DELIVER.image} url={uri}><InstapaperIcon size={32} round={true}  /></InstapaperShareButton>
                            <LinkedinShareButton media={this.props.register.QR_DELIVER.image} url={uri}><LinkedinIcon size={32} round={true}  /></LinkedinShareButton>
                              <LivejournalShareButton media={this.props.register.QR_DELIVER.image} url={uri} ><LivejournalIcon size={32} round={true} /></LivejournalShareButton>
                                <OKShareButton media={this.props.register.QR_DELIVER.image} url={uri} ><OKIcon size={32} round={true} /></OKShareButton>

                                  <PinterestShareButton media={this.props.register.QR_DELIVER.image} url={uri} ><PinterestIcon size={32} round={true} /></PinterestShareButton>
                                    <PocketShareButton media={this.props.register.QR_DELIVER.image} url={uri} ><PocketIcon size={32} round={true} /></PocketShareButton>
                                        <TelegramShareButton media={this.props.register.QR_DELIVER.image} url={uri} ><TelegramIcon size={32} round={true} /></TelegramShareButton>
                                          <TumblrShareButton media={this.props.register.QR_DELIVER.image} url={uri} ><TumblrIcon size={32} round={true} /></TumblrShareButton>
                                            <TwitterShareButton media={this.props.register.QR_DELIVER.image} url={uri}><TwitterIcon size={32} round={true}  /></TwitterShareButton>
                                              <ViberShareButton media={this.props.register.QR_DELIVER.image} url={uri}><ViberIcon size={32} round={true}  /></ViberShareButton>
                                                <LineShareButton media={this.props.register.QR_DELIVER.image} url={uri}><LineIcon size={32} round={true}  /></LineShareButton>
                                                  <MailruShareButton media={this.props.register.QR_DELIVER.image} url={uri}><MailruIcon size={32} round={true}  /></MailruShareButton>
                                                    <RedditShareButton media={this.props.register.QR_DELIVER.image} url={uri}><RedditIcon size={32} round={true}  /></RedditShareButton>

                                                      <FacebookMessengerShareButton media={this.props.register.QR_DELIVER.image} url={uri}><FacebookMessengerIcon size={32} round={true}  /></FacebookMessengerShareButton>
                                                    <WhatsappShareButton media={this.props.register.QR_DELIVER.image} url={uri}><WhatsappIcon size={32} round={true}  /></WhatsappShareButton>
                                                      <WorkplaceShareButton media={this.props.register.QR_DELIVER.image} url={uri}><WorkplaceIcon size={32} round={true}  /></WorkplaceShareButton>
  {this.getFooterNative()}

</div>
      )
    }

    getQR(){
      var uri=`https://www.qrdeliver.com/qrpay/${this.props.match.params.appid}/${this.props.match.params.qrcode}`;
      const params = queryString.parse(this.props.history.location.search);
      var message=getKey(this.props.register.lang.shop_data.qrdeliver, 'scan_with_your_phone');;


      if(params.option){
        uri=`https://www.qrdeliver.com/qrpay/${this.props.match.params.appid}/${this.props.match.params.qrcode}/?option=${params.option}`;
      }

if(params.merchant_token&&params.redirect_status==='success'&&this.props.register.order){

message=getKey(this.props.register.lang.shop_data.qrdeliver, 'show_this_to_delivery');
          uri=`https://www.qrdeliver.com/qrpay/${this.props.match.params.appid}/${this.props.match.params.qrcode}/?redirect_status=success&delivery_token=${this.props.register.order.delivery_token}`;

      }
      if(params.delivery_token&&params.redirect_status==='success'&&this.props.register.order){

message=getKey(this.props.register.lang.shop_data.qrdeliver, 'confirm_with_client');
          uri=`https://www.qrdeliver.com/qrpay/${this.props.match.params.appid}/${this.props.match.params.qrcode}/?redirect_status=success&delivery_token=${this.props.register.order.delivery_token}`;

      }
  if(!params.delivery_token&&!params.merchant_token&&params.redirect_status==='success'&&this.props.register.order){


message=getKey(this.props.register.lang.shop_data.qrdeliver, 'show_this_to_delivery');

          uri=`${this.props.register.order.delivery_token}`;

      }


     if(this.props.register.buttoncheckout||this.props.register.loading||this.props.register.menuFlipped)return null
        if(this.props.register.qr_view){
            return(
                <div onClick={()=>{
                    this.props.isRegister(['qr_view',!this.props.register.qr_view]);
                }} className={"qrview"}>
<div>{message}</div>
{<QRCode value={uri} />}
{this.getNativeButton()}

                   </div>

            )
        }else{
            return(
                <div onClick={()=>{
                    this.props.isRegister(['qr_view',!this.props.register.qr_view]);
                }} className={"qrview"}><div className={'material-icons'}>qr_code_scanner</div></div>
            )
        }
    }
    getHeader() {
      if(this.props.register.menuFlipped)return null;
        if (this.props.register.QR_DELIVER.header) {
        if (this.props.register.checkout||this.props.register.minicheckout) return null;
            return (<div  className={"header"}>{this.props.register.QR_DELIVER.header}


            </div>)
        }
        return null;
    }

    qrdeliverForm() {
        const params = queryString.parse(this.props.history.location.search);
        if (!this.props.register.status) {
            if (this.props.register.QR_DELIVER.is_menu) {

                return (<div className={"flip-card " + (this.props.register.menuFlipped ? "flipped" : "")}>
                    <div className="flip-card-inner">
                        <div className="flip-card-front" >


                            {!this.props.register.minicheckout&&!this.props.register.menuFlipped&&!this.props.register.postcode_request&&<div className="searchbar">
                    {this.getSearchBar()}

                        </div>}
                        {this.getFilterMenu()}
                            {this.getPostcodeForm()}


                        </div>
                        <div className="flip-card-back">
                            {this.getMenus()}

                        </div>
                    </div>

                </div>)
            }
            return (
                <div>

                    <div className={"flip-card " + (this.props.register.menuFlipped ? "flipped" : "")}>
                        <div className="flip-card-inner">
                            <div className="flip-card-front">

                                {this.getProfile()}
                            </div>
                            <div className="flip-card-back">
                                      {this.getMenus()}
                            </div>

                        </div>
                    </div>
                </div>

            );
        } else if (params.redirect_status) {


            if(params.redirect_status&&this.props.register.order&&this.props.register.order.shipped&&params.delivery_token){


                return(
                <div style={{position:'relative'}}>

                 <div>{this.props.register.order.increment_id}</div>
                 <div className="tracknum">{this.props.register.order.tracknum}</div>
                {params.delivery_token&&this.getShippingAdrress()}
                <div className={`material-icons confirmed-shipping`} style={{fontSize:40,backgroundColor:'#78bdcc',color:"#FFF",border:'2px solid #fff'}}>
done
</div>

<div>{getKey(this.props.register.lang.shop_data.qrdeliver, 'confirmed_shipping')}</div>

<button
                       className={`SubmitButton order-again`}
                       type="submit"

                       onClick={() => {
                           window.location = `/qrpay/${this.props.match.params.appid}/${this.props.match.params.qrcode}`;
                       }}
                   > {this.getShopMerchantTitle()}


                   </button>
                </div>



                )



            }
            return (<div style={{position:'relative'}}>

                 <div>{this.props.register.order.increment_id}</div>
                 <div className="tracknum">{this.props.register.order.tracknum}</div>
                {params.delivery_token&&this.getShippingAdrress()}
                {!window.nativeqrdeliver&&params.delivery_token&&<div>
                    <a href={`https://www.google.com/maps/dir/?api=1&destination=${this.getShippingAdrressString()}`} target={"_blank"}>
                    <div className={"plan-trip"}>{getKey(this.props.register.lang.shop_data.qrdeliver, 'plan_your_trip')}</div>
                <div className={"material-icons maps"}>assistant_direction</div></a></div>}


{window.nativeqrdeliver&&params.delivery_token&&<div onClick={() => {
this.props.isRegister(['direction_open',!this.props.register.direction_open])
                            window.qrdeliver.postMessage(escape(`
              {
                "cmd": "direction",
                "address":"${this.getShippingAdrressString()}"
              }
              `))

                       }} className={`material-icons maps deliver ${this.props.register.direction_open?'direction_open':''}`}>assistant_direction</div>}


                 {!params.delivery_token&&!this.props.register.order.shipped&&this.props.register.QR_DELIVER.gg_id&&<Tracker order={this.props.register.order}/>}
                  {!params.delivery_token&&!this.props.register.QR_DELIVER.gg_id&&this.props.register.QR_DELIVER.shipping_type==2&&!this.props.register.order.checkout_session.metadata.pickup&&<TrackerLeaf courrier={params.delivery_token?true:false} order={this.props.register.order}/>}

                {!params.delivery_token&&this.getMessage()}
                {params.delivery_token&&<button
                    className={`SubmitButton confirm_shipping`}
                    type="submit"

                    onClick={() => {
                            this.confirmDelivery(true);

                    }}
                > {getKey(this.props.register.lang.shop_data.qrdeliver, 'confirm_shipping')}
                </button>}
                {params.delivery_token&&window.nativeqrdeliver&&window.scan_enabled&&<button
                    className={`SubmitButton scan_confirm_shipping`}
                    type="submit"

                    onClick={() => {
                            this.confirmDelivery();

                    }}
                > {getKey(this.props.register.lang.shop_data.qrdeliver, 'scan_confirm_shipping')}
                </button>}
 {this.getDeliveryQR()}
            </div>)

        } else {
            return (<div>


                {this.redirectMessage()}

            </div>)
        }
    }
    getSearchBar(){
     if (this.props.register.loading || !this.props.register.cart || this.props.register.menuFlipped ||
            this.props.register.QR_DELIVER.request_postcode) return null;

    return (
    <input id={"search-input"} ref={this.searchfield}className="postcode-field search-name"
                           value={this.props.register.search_qry ? this.props.register.search_qry : ""} onChange={(evt) => {
                        this.props.isRegister(['search_qry', replaceString(evt.target.value)]);

if(this.search_timeout){

clearTimeout(this.search_timeout)
}
const me=this;
this.search_timeout=setTimeout(()=>{

me.searchQuery();
},500)

                        }}
                        />)

    }
    getFilterMenu(){
      if (this.props.register.loading || !this.props.register.cart || this.props.register.menuFlipped ||
            this.props.register.QR_DELIVER.request_postcode) return null;

        if(!this.props.register.categories||this.props.register.categories.length==0){

        return null;


        }
        if(this.props.register.minicheckout)return null;

        return (

        <Menu className="filter-menu" menuButton={ <div className={'material-icons filter-icon'} onClick={() => {



        }}>{this.props.register.filter_category?'filter_alt_off':'filter_alt'}</div>} >

{this.getFilterItems()}

        </Menu>

        )
    }
    getFilterItems(){

     if(this.props.register.categories){

        return this.props.register.categories.map((item,index)=>{

           return(
           <MenuItem key={item._id} className={'filter-item'} onClick={()=>{
            if(this.props.register.filter_category&&this.props.register.filter_category==item._id){

             this.props.isRegister(['filter_category',null])
            }else{
             this.props.isRegister(['filter_category',item._id])
            }



           }}>  {item._id} ({item.count}) {this.props.register.filter_category==item._id&&<div  className={'material-icons filter-item-icon'}>filter_alt_off</div>}</MenuItem>
           )


        })
    }
    return null;;


    }
    getDeliveryQR(){

      const params = queryString.parse(this.props.history.location.search);
    if(params.merchant_token&&params.redirect_status==='success'&&this.props.register.order){
            const uri=`https://www.qrdeliver.com/qrpay/${this.props.match.params.appid}/${this.props.match.params.qrcode}/?redirect_status=success&delivery_token=${this.props.register.order.delivery_token}`;
return <QRCode value={uri} id={"delivery-qr"} />
      }


    return null;
    }
    getShippingAdrressString(){
if(!this.props.register.order.checkout_session.shipping)return '';
    return this.props.register.order.checkout_session.shipping.address.line1+' '+(this.props.register.order.checkout_session.shipping.address.line2?this.props.register.order.checkout_session.shipping.address.line2:'')+' '+ (this.props.register.order.checkout_session.shipping.address.postcode?this.props.register.order.checkout_session.shipping.address.postcode:'')+' '+ this.props.register.order.checkout_session.shipping.address.city;


    }
    searchQuery(){


            const me=this;


      var post={token: me.props.register.captcha_token,search:this.props.register.search_qry}
  ;

      serverRequest('post', `qrpayproducts/${me.props.register.QR_DELIVER.app}/${me.props.register.QR_DELIVER._id}`, post, (res) => {
            if(res.res.products.length>0){
            var nonexisting=[];
            res.res.products.map((product,index)=>{
            var exits=false;
            this.props.register.origin_products.map((opro)=>{

            if(opro._id==product._id){
            exits=true;
            }
            })

            if(!exits){

            nonexisting.push(product)

            }

            });
            nonexisting.reverse();
            this.props.register.origin_products=this.props.register.origin_products.concat(nonexisting);
            const bkpProducts=JSON.parse(JSON.stringify(this.props.register.origin_products))
             me.props.isRegister(['origin_products', this.props.register.origin_products]);
              me.props.isRegister(['products', bkpProducts]);
            }

    })



    }
    confirmDelivery(onlyconfirm){


        const params = queryString.parse(this.props.history.location.search);
            if(window.nativeqrdeliver&&!onlyconfirm){

            window.qrdeliver.postMessage(escape(
              `
              {
                "cmd": "confirm_scan",
                "text": "${md5(params.delivery_token)}",
                "app_url":"/qrpay/${this.props.match.params.appid}/${this.props.match.params.qrcode}/?delivery_token=${params.delivery_token}&shipped=1&redirect_status=success"
              }
              `)
            );
            }else{

                this.deliveryUpdate(true)

            }
    }
    getProfile(){
      if(this.props.register.menuFlipped)return null;
      return(
        <div>
        <div className="form-label tpu-amount"
             htmlFor="typeNumber">{this.props.register.QR_DELIVER.user.company_desc}</div>


        {this.getAmountField()}
{this.getOptionsDropdown(this.props.register.QR_DELIVER)}

        <SubmitButton disabled={this.getPayDisabled()}onClick={() => {
            var bttn = this;
            bttn.props.isRegister(['checkout',1])
            serverRequest('post', `qrdeliverpay/${bttn.props.match.params.appid}/${bttn.props.match.params.qrcode}`, {
                app: `${bttn.props.match.params.appid}`,
                type: 'fooi',
                qritem: `${bttn.props.match.params.qrcode}`,
                amount: bttn.props.register.amount,
                cart: null,
                  options: bttn.getOptions(),
                token: bttn.props.register.captcha_token,
                qty: 1,
                num: bttn.props.register.clientnum,
                postcode: bttn.props.register.postcode,
                voucher: bttn.props.register.voucher,
                street_name: bttn.props.register.street_name,
                house_number: bttn.props.register.house_number,
                house_number_addition: bttn.props.register.house_number_addition,
                city: bttn.props.register.city,
                country: bttn.props.register.country,
                comments: bttn.props.register.comments,
                email: bttn.props.register.email,
                pickup: bttn.props.register.pickup,
                preferred_from_time: bttn.props.register.preferred_from,
                pickup_preferred_from_time: bttn.props.register.pickup_preferred_from,
                option:bttn.props.register.option,
                utoken:bttn.props.register.utoken,



            }, (res) => {

bttn.props.isRegister(['checkout',0])

                if (res.res.session) {
                    if(window.debug)console.log('res', res.res.session.url);
                     bttn.props.isRegister(['loading', true]);
                 window.location.href = res.res.session.url;
                  if(window.debug)console.log(res.res.session.url)
                 if(window.nativeqrdeliver){
         setTimeout(function(){

                      bttn.props.isRegister(['loading', 0]);
                          bttn.props.isRegister(['checkout', 0]);
                    },2000)
            }
                } else if (res.res.msg) {
                    bttn.props.isRegister(['error', res.res.msg])
                    if(res.res.msg&&res.res.msg=='captcha_is_not_correct'){
                     //reload   window.location.reload();

                    }
                }


            });
        }}> {this.getButtonTitle()} </SubmitButton>



        </div>
      )
    }
    redirectMessage() {
        const params = queryString.parse(this.props.history.location.search);
        if (this.props.register.status == 'loading') {
            return (<div className="">
                <div className="form-label noproducts" htmlFor="typeNumber">Please wait...</div>

            </div>)
        } else if (this.props.register.status == 'redirecting') {
            return (<div className="">
                <div className="form-label noproducts" htmlFor="typeNumber">Your beeing redirected...</div>

            </div>)
        } else if (this.props.register.status == 'order_error') {
            return (<div className="">
                <div className="form-label noproducts" htmlFor="typeNumber">
                    <div>{getKey(this.props.register.lang.shop_data.qrdeliver, 'order_error')}</div>

                </div>
                <button
                    className={`SubmitButton`}
                    type="submit"

                    onClick={() => {
                        window.location = `/qrpay/${this.props.match.params.appid}/${this.props.match.params.qrcode}`;
                    }}
                > {getKey(this.props.register.lang.shop_data.qrdeliver, 'order_error')}
                </button>
            </div>)
        }

    return null;
    }
    getSummary(){
        if(this.props.register.order){

                return(
                <div>
<h2>{getKey(this.props.register.lang.shop_data.qrdeliver, 'summary')}</h2>
                <table className="summary_table">
                <thead/>
                <tbody>
                <tr><td colSpan={3}>{getKey(this.props.register.lang.shop_data.qrdeliver, 'order_items')}</td></tr>
                {this.getOrderItems()}

                {this.getTotals()}
                </tbody>
                </table>
                </div>
                )
        }
    return null;

    }
    getTotals(){


        return(
        [
        <tr key={'sub'}>

            <td colSpan={2} className="subtotal title">{getKey(this.props.register.lang.shop_data.qrdeliver, 'subtotal')}</td><td className="subtotal value">{this.getSubtotal()}</td>
        </tr>,
        <tr key={'voucher'}>

            <td colSpan={2} className="subtotal title">{getKey(this.props.register.lang.shop_data.qrdeliver, 'discount')}</td><td className="subtotal value">{this.getDiscount()}</td>
        </tr>,
        !this.props.register.order.checkout_session.metadata.pickup&&
        <tr key={'shipping'}>

            <td colSpan={2} className="subtotal title">{getKey(this.props.register.lang.shop_data.qrdeliver, 'shipping')}</td><td className="subtotal value">{this.getShipping()}</td>
        </tr>,<tr key={'tax'}>

            <td colSpan={2} className="subtotal title">{getKey(this.props.register.lang.shop_data.qrdeliver, 'tax')}</td><td className="subtotal value">{this.getTax()}</td>
        </tr>,
        <tr key={'total'}>

            <td colSpan={2} className="subtotal title">{getKey(this.props.register.lang.shop_data.qrdeliver, 'total_amount')}</td><td className="subtotal value">{this.getAmountTotalOrder()}</td>
        </tr>


        ]

        )


    }
    getAmountTotalOrder(){
        return this.getCurrencyAmount(this.props.register.order.checkout_session.amount_total/100);

    }
     getSubtotal(){
        return this.getCurrencyAmount(this.props.register.order.checkout_session.amount_subtotal/100);

    }
     getDiscount(){
        return this.getCurrencyAmount(-this.props.register.order.checkout_session.total_details.amount_discount/100);

    }
    getShipping(){
        return this.getCurrencyAmount(this.props.register.order.checkout_session.total_details.amount_shipping/100);

    }
    getTax(){
        return this.getCurrencyAmount(this.props.register.order.checkout_session.total_details.amount_tax/100);

    }
    getOrderItems(){
        return this.props.register.order.order.line_items.map((order,index)=>{


                return (
                        <tr key={index}><td  >{this.props.register.order.order.line_items[index].quantity}</td><td className={'pn'}>{this.props.register.order.order.line_items[index].images&&<img className="line-img"src={this.props.register.order.order.line_items[index].images[0]} width={30}/>}{this.props.register.order.order.line_items[index].name}</td><td>{this.getCurrencyAmount(this.props.register.order.order.line_items[index].amount/100)}</td></tr>
                )

        })

    }
    getCheckoutItems(order){
//return JSON.stringify(order.order.line_items);
        return order.order.line_items.map((item,index)=>{

return <MenuItem key={`${order._id}_${index}`}><table className="lineitem" width="100%"><tbody><tr><td><img className=""src={item.images[0]} width={30}/></td><td>{item.quantity}</td><td width="100%">{item.name}</td><td>{this.getCurrencyAmount(item.amount/100)}</td></tr></tbody></table></MenuItem>;


        })

    }

    getMessage() {
      if(this.props.register.tracking){
        return null;
      }
        const params = queryString.parse(this.props.history.location.search);
        if (params.redirect_status == 'failed') {

            return (<div className="">
                <div className="form-label noproducts" htmlFor="typeNumber">
                    <div>{getKey(this.props.register.lang.shop_data.qrdeliver, 'order_error')}</div>

                </div>

                <button
                    className={`SubmitButton`}
                    type="submit"

                    onClick={() => {
                        window.location = `/qrpay/${this.props.match.params.appid}/${this.props.match.params.qrcode}`;
                    }}
                > {getKey(this.props.register.lang.shop_data.qrdeliver, 'try_again')}
                </button>
            </div>)

        } else {
            return (<div className="results success">
                <div className="form-label success" htmlFor="typeNumber">Payment Success</div>

                {this.getSuccesStatusButton()}
                <div className="messages-title" onClick={()=>{
const me=this;

                     this.props.isRegister(['show_messages', !this.props.register.show_messages])
setTimeout(()=>{

           if(me.messagesEndRef.current)me.messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
},1000)

                }}><div className={`material-icons mail ${this.props.register.message_sending?'loading':''}`}>
mail
</div> {getKey(this.props.register.lang.shop_data.qrdeliver, 'messages')}{this.props.register.show_messages&&<div className={`material-icons arrow`}>
arrow_drop_up
</div>}
{!this.props.register.show_messages&&<div className={`material-icons arrow`}>
arrow_right
</div>}</div>
{this.getMessagesBox()}

{this.getRatingBox()}


                   <button
                       className={`SubmitButton order-again`}
                       type="submit"

                       onClick={() => {
                           window.location = `/qrpay/${this.props.match.params.appid}/${this.props.match.params.qrcode}`;
                       }}
                   > {this.getShopMerchantTitle()}
                   </button>
            </div>)
        }
    }
    getShopMerchantTitle(title){


        const params = queryString.parse(this.props.history.location.search);
    if(params.merchant_token||params.delivery_token){


     return getKey(this.props.register.lang.shop_data.qrdeliver, 'order_again');
     }

     return getKey(this.props.register.lang.shop_data.qrdeliver, 'order_again')

    }
    getMessagesBox(){
        if(!this.props.register.show_messages)return null;
        return(

        <div className={'messagebox'}>

                        <div className="messages-holder"  >


                            {this.getMessages(this.props.register.order)}
                                    <div  className="divider" ref={this.messagesEndRef} style={{opacity:0,height:200,paddingBottom:100,float:'left'}}></div>
                        </div>
                        <input className="messageinput "
                                  value={this.props.register.message ? this.props.register.message : ""} onChange={(evt) => {
                            this.props.isRegister(['message', replaceStr(evt.target.value,100)])

                        }} placeholder={getKey(this.props.register.lang.shop_data.qrdeliver, 'comments_hint')} name="comments"
                                  type="text" />


                    <button disabled={this.props.register.message&&this.props.register.message.length>0?false:true}
                    className={`SubmitButton`}
                    type="submit"

                    onClick={() => {

                        this.sendMessage(this.props.register.message);
                          this.props.isRegister(['message', ''])
                    }}
                > <div className={`material-icons send ${this.props.register.message_sending?'loading':''}`}>
send
</div>
                </button>

                    </div>
        )

    }


    getRatingBox(){


if(!this.props.register.order.shipped){


return
}

            return(

      <div className={"rainting-box"}>
<div className="messages-title"  ref={this.rating_input} onClick={()=>{

const me=this;
                     this.props.isRegister(['show_rating', !this.props.register.show_rating])
setTimeout(()=>{

           if(me.rating_input.current)me.rating_input.current.scrollIntoView({ behavior: "smooth" })
},300)

                }}><div className={`material-icons mail ${this.props.register.message_sending?'loading':''}`}>
star_half
</div> {getKey(this.props.register.lang.shop_data.qrdeliver, 'review')}{this.props.register.show_rating&&<div className={`material-icons arrow`}>
arrow_drop_up
</div>}
{!this.props.register.show_rating&&<div className={`material-icons arrow`}>
arrow_right
</div>}</div>

        {this.props.register.show_rating&&<div className={'ratingfields'}>
        <label  className="nickname">
           {getKey(this.props.register.lang.shop_data.qrdeliver, 'nick_name')}
        </label>
        <input  disabled={this.props.register.order.reviewed} className="street-field comments"
                           value={this.getNickName()}
                           onChange={(evt) => {
                           const nick = evt.target.value.replace(/a-zA-Z/g, '').slice(0, 20);
                               this.props.isRegister(['nick_name',nick])

                           }} placeholder={getKey(this.props.register.lang.shop_data.qrdeliver, 'nick_name_hint')}
                           name="nikc_name" type="text" pattern="[0-9]"/>
                           <label  className="nickname">
           {getKey(this.props.register.lang.shop_data.qrdeliver, 'review')}
        </label>
                           <textarea className="street-field comments "
                         disabled={this.props.register.order.reviewed} value={this.getReviewMessage()} onChange={(evt) => {
                    this.props.isRegister(['rating_message', replaceStr(evt.target.value,100)])

                }} placeholder={getKey(this.props.register.lang.shop_data.qrdeliver, 'review_hint')} name="rating_msg"
                          type="text" />
                           </div>}
                         {this.props.register.show_rating&&<div>
      <Rating allowHover={false} allowHalfIcon={true} readonly={this.props.register.order.reviewed}  onClick={(rating)=>{

this.props.isRegister(['stars', rating])

      }} transition={true}ratingValue={(rating)=>{
//this.props.isRegister(['stars', rating])



      }} />
  {!this.props.register.order.reviewed&&<button disabled={this.props.register.sending_rate}
                        className={`SubmitButton`}
                        type="submit"

                        onClick={() => {

                            if(!this.props.register.order.reviewed){
                                this.sendRating(this.props.register.rating_message);

                            }else{

 this.deleteRating(this.props.register.rating_message);
                            }



                        }}
                    >
{!this.props.register.order.reviewed&&<div className="material-icons resetrating ">
offline_pin
</div>}
{this.props.register.order.reviewed&&<div className="material-icons resetrating ">
unpublished
</div>}

                    {!this.props.register.order.reviewed&&getKey(this.props.register.lang.shop_data.qrdeliver, 'send_review')}{this.props.register.order.reviewed&&getKey(this.props.register.lang.shop_data.qrdeliver, 'reset_review')}
                    </button>}
      </div>}


      </div>

            );

    }
    replaceMessage(str,len=50) {
    if (str) {
        str = String(str).replace(/[^0-9a-zA-Z., :?!\/]/g, '');
        if (str.length > len) {
            str = str.substring(0, len);
        }
        if (str == '-') return '';
        return str;
    }


    return '';
}
getReviewMessage(){

    return this.props.register.order.reviewed&&this.props.register.order.rating_message ? this.props.register.order.rating_message :this.props.register.rating_message?this.props.register.rating_message:"";


}
getNickName(){

    return this.props.register.order.reviewed&&this.props.register.order.nick_name ? this.props.register.order.nick_name:this.props.register.nick_name?this.props.register.nick_name:"";


}
    getPepareTime(){
if(this.props.register.cart&&this.props.register.products){
    var time=0;
    Object.keys(this.props.register.products).map((index)=>{

        if(this.props.register.cart.products[this.props.register.products[index]._id.toString()]){

            time +=this.props.register.products[index].prepare_time;
        }
    })
    const ptime=time;
    if(this.props.register.QR_DELIVER.delivery_time){
        time +=this.props.register.QR_DELIVER.delivery_time;
    }



    if(window.debug)console.log("<______",time );
    const diff_ =60*2;
    if(this.props.register.QR_DELIVER){
        return null;

    }
}

    }
    getShippingIcon(){
            if(this.props.register.order.checkout_session.metadata.pickup){
             return 'assistant_direction'

            }
        if(this.props.register.QR_DELIVER.shipping_type==2){

            return 'delivery_dining'
        }else if(this.props.register.QR_DELIVER.shipping_type==1){

            return 'local_shipping'
        }else if(this.props.register.QR_DELIVER.shipping_type==3){

            return 'local_shipping'
        }

    }

    getProgress(){

        if(!this.props.register.order.printed&&!this.props.register.order.ready&&!this.props.register.order.shipped){

          return(<div className="progressHolder">
          <div className="progressbar" style={{width:"0%"}}></div>
          <div className="start"><div className="material-icons">query_builder</div></div>
          <div className="shipping"><div className="material-icons">{this.getShippingIcon()}</div></div>
          <div className="shipped"><div className=" material-icons">{this.props.register.QR_DELIVER.shipping_type==2?'published_with_changes':'offline_pin'}</div></div>

          </div>)


        }else  if(this.props.register.order.printed&&!this.props.register.order.ready&&!this.props.register.order.shipped){

 return(<div className="progressHolder">
          <div className="progressbar half" style={{width:"0%"}}></div>
          <div className="start active"><div className="material-icons">query_builder</div></div>
          <div className="shipping"><div className="material-icons">{this.getShippingIcon()}</div></div>
          <div className="shipped"><div className=" material-icons">{this.props.register.QR_DELIVER.shipping_type==2?'published_with_changes':'offline_pin'}</div></div>

          </div>)

        }else  if(this.props.register.order.printed&&this.props.register.order.ready&&!this.props.register.order.shipped){

 return(<div className="progressHolder">
          <div className="progressbar halffull" style={{width:"50%"}}></div>
          <div className="start active done"><div className="material-icons">query_builder</div></div>
          <div className="shipping active"><div className="material-icons">{this.getShippingIcon()}</div></div>
          <div className="shipped"><div className=" material-icons">{this.props.register.QR_DELIVER.shipping_type==2?'published_with_changes':'offline_pin'}</div></div>

          </div>)
        }else  if(this.props.register.order.printed&&this.props.register.order.ready&&this.props.register.order.shipped){
 return(<div className="progressHolder">
          <div className="progressbar" style={{width:"100%"}}></div>
          <div className="start active done"><div className="material-icons">query_builder</div></div>
          <div className="shipping active done"><div className="material-icons">{this.getShippingIcon()}</div></div>
          <div className="shipped active done "><div className="material-icons">{this.props.register.QR_DELIVER.shipping_type==2?'published_with_changes':'offline_pin'}</div></div>

          </div>)
        }else{

        return(<div></div>)
        }

    }
    searchItems(evt){

        const me=this;
//          serverRequest('post', `qrpayproducts/${me.props.register.QR_DELIVER.app}/${me.props.register.QR_DELIVER._id}`, post, (res) => {
//
//
//
//
//        });

    }
    loadPage(category,count,current){

        const me=this;

        const pages=Math.ceil(count/5);
         const currentp=Math.ceil(current.length/5);

        //console.log(pages,currentp)
      var post={token: me.props.register.captcha_token}
        post.page=currentp+1;
        post.category=category;
 this.props.isRegister(['loading_page', true]);
      serverRequest('post', `qrpayproducts/${me.props.register.QR_DELIVER.app}/${me.props.register.QR_DELIVER._id}`, post, (res) => {
if(!res&!res.res.products)return;
var products=[];
var added=0;
for(var i=0;i<res.res.products.length;i++){
var exist=false;
for(var z=0;z<this.props.register.origin_products.length;z++){

if(this.props.register.origin_products[z]._id==res.res.products[i]._id){
exist=true;

}

}
if(!exist){
added+=1;
products.push(res.res.products[i])
}

}
console.log(currentp,pages)
if(added==0&&currentp==pages-1){
var cats=[]
        me.props.register.categories.map((item,index)=>{
console.log(item,category,"<<<<")
            if(item._id==category){

          item.loaded=true
            }
cats.push(item)


        })

me.props.isRegister(['categories', cats]);
console.log("done loading",me.props.register.categories)
}
products.reverse();
    me.props.register.origin_products=me.props.register.origin_products.concat(products);
const bkpProducts=JSON.parse(JSON.stringify(me.props.register.origin_products))
 me.props.isRegister(['origin_products', this.props.register.origin_products]);
                             var json = JSON.parse(JSON.stringify(me.props.register.cart));
                             const newcart={};
                                                Object.keys(json.products).map((item)=>{
                                                            const citen=json.products[item];
 if(window.debug)console.log(citen.qty,"citroem<<<<<<<<<<")


                                                        products.map((p)=>{
                                                        if(p._id==item){
                                                        newcart[p._id]=p;
                                                        newcart[p._id].qty=json.products[item].qty
                                                        newcart[p._id].option =json.products[item].option

                                                        }
  if(citen.subid){
                                                            if(p._id==citen.subid){

                                                                const rvert=JSON.parse(JSON.stringify(p))
                                                                rvert._id=citen._id;
                                                                rvert.subid=p._id;
                                                              //  bkpProducts.push(rvert)

                                                            }
 }
                                                        })






                                                })
                               me.props.isRegister(['products', bkpProducts]);
                              // json.products=newcart;
                                //me.props.isRegister(['cart',json]);
 me.props.isRegister(['loading_page', false]);

    })



    }
    getProgressMessage(){

        if(!this.props.register.order.printed&&!this.props.register.order.ready&&!this.props.register.order.shipped){

          return(getKey(this.props.register.lang.shop_data.qrdeliver, 'waiting_for_confirmation'))


        }else  if(this.props.register.order.printed&&!this.props.register.order.ready&&!this.props.register.order.shipped){
 return(getKey(this.props.register.lang.shop_data.qrdeliver, 'preparing_your_order'))

        }else  if(this.props.register.order.printed&&this.props.register.order.ready&&!this.props.register.order.shipped){
 if(this.props.register.order.checkout_session.metadata.pickup){

 if(this.props.register.order.checkout_session.metadata.pickup_preferred_from_time
 &&this.props.register.order.checkout_session.metadata.pickup_preferred_from_time.split(":").length==2){

    const date=moment().format('YYYY-MM-DD')
   var timeAndDate = moment(date + ' ' + this.props.register.order.checkout_session.metadata.pickup_preferred_from_time);
    if(window.debug)console.log(timeAndDate)
    var now = moment(new Date()); //todays date
var duration = moment.duration(now.diff(timeAndDate));
 if(window.debug)console.log("???",duration)


    return <div>{date + ' ' + this.props.register.order.checkout_session.metadata.pickup_preferred_from_time    }<br/>{duration<0&&<Moment date={timeAndDate } format="hh:mm:ss" durationFromNow  onChange={(val) => { if(window.debug)console.log(Number(val),val,"<<<<<<<");
    if(Number(val)>0){







    } }}/>}</div>

    }

    return null

 }
 if(this.props.register.order.checkout_session.metadata.preferred_from_time
 &&this.props.register.order.checkout_session.metadata.preferred_from_time.split(":").length==2){


    const date=moment().format('YYYY-MM-DD')
   var timeAndDate = moment(date + ' ' + this.props.register.order.checkout_session.metadata.preferred_from_time);
    if(window.debug)console.log(timeAndDate)
    var now = moment(new Date()); //todays date
var duration = moment.duration(now.diff(timeAndDate));
 if(window.debug)console.log("???",duration)

if(duration<0){


 return(<div>

 {getKey(this.props.register.lang.shop_data.qrdeliver, 'your_order_will_be_delivered_at')}<br/>
 {timeAndDate.format('HH:MM')}<br/>
    <Moment date={timeAndDate } format="hh:mm:ss" durationFromNow  onChange={(val) => { if(window.debug)console.log(Number(val),val,"<<<<<<<");
    if(Number(val)>0){







    } }}/>
 </div>)

}

}
 return(getKey(this.props.register.lang.shop_data.qrdeliver, 'your_order_is_beeing_delivered'))
        }else  if(this.props.register.order.printed&&this.props.register.order.ready&&this.props.register.order.shipped){
 return(getKey(this.props.register.lang.shop_data.qrdeliver, 'your_order_is_complete'))
        }

    }
    getShippingAdrress(){
    if(this.props.register.order.checkout_session.metadata.pickup){

        return null

    }
            if(this.props.register.order.checkout_session.shipping){
                return(<div><h2>{getKey(this.props.register.lang.shop_data.qrdeliver, 'customer_address')}</h2>
                <address>
  <strong>{this.props.register.order.checkout_session.shipping.name}</strong><br/>
  {this.props.register.order.checkout_session.shipping.address.line1}<br/>
  {this.props.register.order.checkout_session.shipping.address.line2} {this.props.register.order.checkout_session.shipping.address.city}, {this.props.register.order.checkout_session.shipping.address.postcode}<br/>
{this.getPhoneCustomer()}
</address></div>
)
            }

    }
    getPhoneCustomer(){
        if(this.props.register.order.checkout_session.shipping.phone){
            return(
              <div><abbr title="Phone">P:</abbr> {this.props.register.order.checkout_session.shipping.phone}</div>
              )

        }
        return null;

    }
    getMessages(order){

        const params = queryString.parse(this.props.history.location.search);
            if(order.messages){
                const rev=JSON.parse(JSON.stringify(order.messages));

                return rev.reverse().map((item,index)=>{

                    return <div key={index} className={item[0].user=='client'?`${params.merchant_token?'bubble-left':'bubble-right'}`:`${params.merchant_token?'bubble-right':'bubble-left'}`+' '+item[0].user}>

                                               {item[0].user==='client'&&<div className={'material-icons chat'}>person</div>}
{item[0].user!=='client'&&<div className={'material-icons chat'}>business</div>}
 <div className={'message-date'}>{item[0].date}</div><br/>
                            {item[0].msg}

                    </div>;

                })


            }
            return;

    }
    getSuccesStatusButton(){

        if(this.props.register.order&&!this.props.register.order.checkout_session.metadata.pickup&&this.props.register.QR_DELIVER&&this.props.register.QR_DELIVER.shipping_type>1){
            return(
               <div className="counter">



                   {this.props.register.QR_DELIVER.shipping_type&&this.props.register.QR_DELIVER.shipping_type>1&&this.props.register.QR_DELIVER.delivery_from_gps&&this.getProgress()}
                   {this.props.register.QR_DELIVER.shipping_type&&this.props.register.QR_DELIVER.shipping_type>1&&this.props.register.QR_DELIVER.delivery_from_gps&&this.getProgressMessage()}
                            {this.getShippingAdrress()}
                   {this.getSummary()}
                    {this.getPrinterButton()}
               </div>
            )
        }else if(this.props.register.order&&this.props.register.order.checkout_session.metadata.pickup){
            return(
                <div className="counter">

{this.getProgress()}

                   {this.getProgressMessage()}
                    {this.getPickupLocation()}
                    {this.getMapsLink()}
                    {this.getSummary()}

  {this.getPrinterButton()}

                </div>
            )
        }else{
           return(
                <div>

                {this.getSummary()}
                  {this.getPrinterButton()}

               </div>
           )
        }
    }
    getPrinterButton(){
    return(

<div className="messages-title" onClick={()=>{

   const params = queryString.parse(this.props.history.location.search);
    if(params.merchant_token&&params.redirect_status==='success'&&this.props.register.order){
    const canvas = document.getElementById('delivery-qr');

       var dataImg =canvas.toDataURL();
       if(window.debug)console.log(dataImg);
       this.props.register.order.rawhtml=this.props.register.order.rawhtml.replace("</body>",`<div style="margin:auto;text-align:center;border-top:2px dashed #000;padding:10px;"><img  src="data:image/jpeg;${dataImg}" /></div></body>`)
if(window.debug)console.log(     this.props.register.order.rawhtml,"html",this.props.register.order.rawhtml.replace('width:215.43307087px!important','width:auto!important').replace('max-width:580px;','').replace('.main','table td{vertical-align:middle;padding:4px;}.main'))

    }


                var me = this; // keep reference for inner call

//return PrintElem(this.props.register.order.increment_id, me.props.register.order.rawhtml.replace('width:215.43307087px!important','width:auto!important').replace('max-width:580px;','').replace('.main','table td{vertical-align:middle;padding:4px;}.main'))
             if(!window.nativeqrdeliver) {
if(window.debug)console.log(this.props.register.order.rawhtml.replace('width:215.43307087px!important','width:auto!important').replace('max-width:580px;','').replace('.main','table td{vertical-align:middle;padding:10px!important;}.main'));

  PrintElem(this.props.register.order.increment_id, me.props.register.order.rawhtml.replace('width:215.43307087px!important','width:auto!important').replace('max-width:580px;','').replace('.main','table td{vertical-align:middle;padding:4px;}.main'))
                }else{
                if(window.debug)console.log("mobile")
                window.qrdeliver.postMessage(escape(
      `
      {
        "cmd": "print",
        "text": "${encodeURIComponent(me.props.register.order.rawhtml)}"
      }
      `)
    );


                }



                }}>{getKey(this.props.register.lang.shop_data.qrdeliver, 'print')}
                <div className={"material-icons printer"} >print</div>
               <div className={`material-icons arrow`}>
receipt_long
</div>
                </div>)


    }

    getPickupLocation(){

        if(this.props.register.QR_DELIVER.pickup_location_maps_link){

            return(
                <div className="user-pickup">
                    {getKey(this.props.register.lang.shop_data.qrdeliver, 'pickup_your_order_at')}
                    <div className={"pickup-loc"}>{this.props.register.QR_DELIVER.pickup_location}</div>

                </div>
            )

        }
        return null;
    }
    getMapsLink(){

        if(this.props.register.QR_DELIVER.pickup_location_maps_link){
            if(window.nativeqrdeliver){

            <div>
                    <div className={"plan-trip"} onClick={()=>{


                     window.qrdeliver.postMessage(escape(`
              {
                "cmd": "directions",
                "address":"${this.props.register.QR_DELIVER.pickup_location}"
              }
              `))
                    }}>{getKey(this.props.register.lang.shop_data.qrdeliver, 'plan_your_trip')}</div>
                <div className={"material-icons maps"}>assistant_direction</div>


                </div>


return;
            }
            return(
                <div>
                    <a href={`https://www.google.com/maps/dir/?api=1&destination=${this.props.register.QR_DELIVER.pickup_location_maps_link}`} target={"_blank"}>
                    <div className={"plan-trip"}>{getKey(this.props.register.lang.shop_data.qrdeliver, 'plan_your_trip')}</div>
                <div className={"material-icons maps"}>assistant_direction</div></a>


                </div>
            )

        }
        return null;
    }


    getPostcodeButton() {
     if (this.props.register.minicheckout) return null;
        const params = queryString.parse(this.props.history.location.search);
        if (!this.props.register.buttoncheckout && !params.redirect_status && this.props.register.products && this.props.register.products.length > 0 && this.props.register.QR_DELIVER.restrict_shipping && !this.props.register.menuFlipped && this.props.register.QR_DELIVER.postcode) {
            return (<div>{getKey(this.props.register.lang.shop_data.qrdeliver, 'postcode')}<div className="postcode-change" onClick={() => {
                var qrDeliver = JSON.parse(JSON.stringify(this.props.register.QR_DELIVER));
                qrDeliver.request_postcode = true;
                qrDeliver.postcode = undefined;
                this.props.isRegister(['msg', null])
                this.props.isRegister(['buttoncheckout', 0])
                this.props.isRegister(['QR_DELIVER', qrDeliver])
            }}>{this.props.register.QR_DELIVER.postcode}</div></div>)
        }
        return null;
    }

    getPostcodeCheckoutButton() {

    }

    getError() {

        if (this.props.register.error) {
            return (
                <div className={"error"}>
                    <div className={'material-icons'}>error_outline</div>
                    <div className="label">{getKey(this.props.register.lang.shop_data.qrdeliver, this.props.register.error)}</div>
                </div>
            )
        }
        return null
    }

    getRecap() {
        if (!this.props.register.loading&&!window.nativeqrdeliver&&!this.props.register.captach_token) {
            return (
                <div id="recaptcha-container">
                    <Recaptcha id={"recapp"}
                               locale={this.props.register.lang.key.split("_")[0]}
                               ref={ref => this.recaptcha = ref}
                               sitekey={'6LeFfjIeAAAAABkQQMVFLTHbzU67WSGJXH-S5iHu'}

                               onResolved={() => {
                                   ;
                                   this.props.isRegister(['captcha_token', this.recaptcha.getResponse()])
                                   if (this.props.register.command) {

                                       this.props.register.command();
                                       this.props.register.command = undefined;
                                   }
                               }}/></div>
            )
        }
    }

    getQRAnalatics() {

    }

    getMenuName() {
        if (this.props.register.menuFlipped) return null;
        if (!this.props.register.buttoncheckout) {
            return (<div className="title-container" >
                <h1 className="title">{this.props.register.QR_DELIVER.user.name}</h1>


        <div>{this.props.register.QR_DELIVER.user.company_desc}</div>
{this.props.register.lightbox&&<div className="lightbox"><div className="material-icons lightclose" onClick={()=>{


this.props.isRegister(['lightbox',null])
}}>cancel</div><img src={this.props.register.lightbox.image} /></div>}
            </div>)
        }
        return null;
    }

    getNav() {
if(window.debug)console.log(this.props.register.QR_DELIVER.is_menu,this.props.register.menus)
       // if(!this.props.register.QR_DELIVER.is_menu)return null;
  if(!this.props.register.menus||this.props.register.menus.length==0)return null;
        if(window.debug)console.log(this.props.register.menus,  "<<<<<<")
        const params = queryString.parse(this.props.history.location.search);
        if(params.redirect_status)return null;
        if (this.props.register.checkout&&this.props.register.QR_DELIVER.is_menu) {

            return (<div className={'header-menu'}>{this.getLoader()}</div>)
        }
        if (this.props.register.buttoncheckout) {
            return (<div className={'header-menu'}>
                <div className={'material-icons menu'} onClick={() => {
                this.props.isRegister(['reviews',false]);
                    this.props.isRegister(['buttoncheckout', 0])
                }}>arrow_circle_left
                </div>
            </div>)
        }
        if (!this.props.register.menuFlipped) {
            return (<div className={'header-menu'}>
                <div className={'material-icons menu'} onClick={() => {
  this.props.isRegister(['reviews',false]);
  if(window.debug)console.log(this.props.register.menuFlipped,"flipperkast",!this.props.register.menuFlipped)
                    this.props.isRegister(['menuFlipped', !this.props.register.menuFlipped]);

                }}>menu
                </div>
            </div>)
        } else if (this.props.register.menuFlipped) {

            return (<div className={'header-menu'}>
                <div className={'material-icons menu'} onClick={() => {

                    this.props.isRegister(['menuFlipped', !this.props.register.menuFlipped]);



                }}>arrow_circle_left
                </div>
            </div>)
        }
        return null;


    }
    getArt(){
    //  if(window.debug)console.log('artie',this.props.register.QR_DELIVER.background_image)
      if(this.props.register.QR_DELIVER.background_image){
        const wind= window.innerWidth;
          const height= window.innerHeight;
              var styles={backgroundImage:`url(${this.props.register.QR_DELIVER.background_image})`,backgroundRepeat:this.props.register.QR_DELIVER.background_repeat?"repeat":"no-repeat"};

          if(wind>height){
            styles['backgroundSize']='100% auto';
          }else{
            styles['backgroundSize']=' auto 100%';
          }
        return(<div className="art_image" style={styles}>
        </div>)
      }
      return null
    }
    getLogo(){

           // if(!window.nativeqrdeliver){

            return (
              <div className={`image-container logo ${this.getItemBG({image:this.props.register.QR_DELIVER.image})}`} onClick={() => {
                        if (!this.props.register.is_menu||this.props.register.loading || this.props.register.buttoncheckout || this.props.register.postcode_request) return;
                        this.props.isRegister(['menuFlipped', !this.props.register.menuFlipped]);
                    }}>
                        <img onLoad={()=>{

                                           this.props.isRegister([`img_${md5(this.props.register.QR_DELIVER.image)}`, 1]);

                                    }} src={this.props.register.QR_DELIVER.image} alt={this.props.register.QR_DELIVER.user.name}/>

                    </div>
                    )

     //       }
    return null;

    }
    getMode(){
        if(this.props.register.QR_DELIVER.pk.split("test").length>1){


            return <div className="testmode"><a href={'https://stripe.com/docs/testing'} target="_blank">{getKey(this.props.register.lang.shop_data.qrdeliver, 'test_mode').toUpperCase()}</a></div>
        }
        return null;

    }
    render() {

        const params = queryString.parse(this.props.history.location.search);
    if(window.nativeqrdeliver&&!window.qrdelivertoken){

    return null;

    }
    if(!window.nativeqrdeliver&&window.qrdelivertoken){

    return null;

    }
    if (!this.props.register.order&&!this.props.register.checkout&&!this.props.register.loading&&
    this.props.register.total_amount != undefined &&
    this.props.register.QR_DELIVER&&
    this.props.register.fonts_loaded&&
    this.props.register.QR_DELIVER.deleted) {


    return(
       <div className="card shop"  onScroll={this.handleScroll} style={{
                    'backgroundImage': this.props.register.utoken?"":`url(${bg})`,
                    'backgroundRepeat': 'no-repeat',
                    'backgroundPosition': 'bottom left'
                }}>



                    {this.getLogo()}




                    </div>

    )

}

        if (!this.props.register.checkout&&!this.props.register.loading&&this.props.register.total_amount != undefined && this.props.register.QR_DELIVER&&this.props.register.fonts_loaded) {

            return (


                <div className="card shop"  onScroll={this.handleScroll}  style={{
                    'backgroundImage': this.props.register.utoken?"":`url(${bg})`,
                    'backgroundRepeat': 'no-repeat',
                    'backgroundPosition': 'bottom left'
                }} ref={this.productList}>



                    {this.getLogo()}
                    {this.getShippingCity()}
{!window.nativeqrdeliver&&<Menu className="main-menu" menuButton={ <div className={'material-icons menu'} onClick={() => {
//                this.props.isRegister(['reviews',false]);
//                    this.props.isRegister(['buttoncheckout', 0])
                }}>menu
                </div>}>

 {this.getOrdersMenu()}



{this.getFavorites(true)>0&&<SubMenu label={<div><div className="material-icons delivery">
                    apps
                </div>{getKey(this.props.register.lang,'favorites')}</div>}>

        {this.getFavorites(false)}
        </SubMenu>}

    {/*<MenuItem onClick={()=>{
window.location = `https://www.qrdeliver.com/merchants/login?redirect=${encodeURIComponent(window.location.toString())}`;
 }}>
        <i className="material-icons">login</i>{getKey(this.props.register.lang,'account')}
    </MenuItem>*/}
      <MenuItem onClick={()=>{
window.location = `https://www.qrdeliver.com/`;

    }}>
        <i className="material-icons">home</i>{getKey(this.props.register.lang,'home')}
    </MenuItem>


</Menu>}
{!this.props.register.order&&this.props.register.QR_DELIVER.AverageRating&&this.props.register.QR_DELIVER.AverageRating[0].count>0&&<div className={'reviews_bar'} onClick={()=>{

this.props.isRegister(['contact_form',false]);
this.props.isRegister(['reviews',!this.props.register.menuFlipped]);
this.props.isRegister(['menuFlipped', !this.props.register.menuFlipped]);
if(window.debug)console.log(this.props.register)
if(!this.props.register.reviews_data){

 this.props.isRegister(['loading_reviews',1]);

 serverRequest('post', `fooiappmobile/${this.props.match.params.appid}/${this.props.match.params.qrcode}?reviews=1`,{},(res) => {




 this.props.isRegister(['reviews_data',res.res.reviews]);
 this.props.isRegister(['loading_reviews',false]);
 });

}


}}><div>
<Rating allowHover={false} allowHalfIcon={true} readonly={true} transition={true} initialValue={this.props.register.QR_DELIVER.AverageRating[0].AverageRating} size={14}/></div>
</div>}

                      {this.getMode()}

                  {/*this.getNav()*/}
{/*this.getCurrencies*/}


                   {!this.props.register.minicheckout&&this.getMenuName()}

                                  {!this.props.register.minicheckout&&!this.props.register.checkout&&!this.props.register.menuFlipped&&this.props.register.QR_DELIVER.show_about&&<span onClick={()=>{

 this.props.isRegister(['contact_form',!this.props.register.contact_form]);

            }} className="material-icons info ">
{!this.props.register.contact_form?'info':'close'}
</span>}
                     {this.props.register.contact_form&&this.getWeek()}

                    {this.getShippingHeader(this.props.register.QR_DELIVER)}{this.getPostcodeButton()}

                    {this.getHeader()}

                    {this.qrdeliverForm()}



{this.getTotalButton()}
                    {!params.delivery_token&&this.getFooter()}
                       {!params.delivery_token&&this.getMobileLink()}
                    {!params.delivery_token&&this.getQR()}

                     {!this.props.register.menuFlipped&&<div style={{marginTop: '70px'}}>
                     <div className={'shop_nav'}>
{<Menu className="main-menu" menuButton={<MenuButton disabled={!this.props.register.shop_languages||this.props.register.shop_languages.length==0} width={100} style={{float:'right',marginTop:10,cursor:'pointer'}}>{this.props.register.QR_DELIVER.language.split("_")[0].toUpperCase()/*this.getL(this.props.register.QR_DELIVER.language.split("_")[0])*/}</MenuButton>}>{!this.props.register.shop_languages||this.props.register.shop_languages.length>0&&this.getShopLanguages()}</Menu>}
   {<Menu className="main-menu" menuButton={<MenuButton disabled={!(this.props.register.shop_currencies&&this.props.register.shop_countries.length>0)}
   width={100} style={{float:'right',marginTop:10,cursor:'pointer'}}>{getSymbolFromCurrency(this.props.register.QR_DELIVER.currency)}</MenuButton>}>{this.props.register.shop_currencies&&this.props.register.shop_currencies.length>0&&this.getShopCurrencies()}</Menu>}


    {<Menu className="main-menu" menuButton={<MenuButton disabled={!(this.props.register.shop_countries&&this.props.register.QR_DELIVER.shipping_country&&this.props.register.shop_countries.length>0)} width={100} style={{float:'right',marginTop:10,cursor:'pointer'}}><table><tbody><tr><td><ReactCountryFlag
                        countryCode={this.props.register.QR_DELIVER.shipping_country}/></td></tr></tbody></table></MenuButton>}>{this.props.register.shop_countries&&this.props.register.QR_DELIVER.shipping_country&&this.props.register.shop_countries.length>0&&this.getShopCountries()}</Menu>}

</div>
                    </div>}


                    {this.getPowerdBy()}

{this.getCookie()}

</div>
            )


        }

        if(window.debug)console.log(this.props)
        return <div className="loadingShop">{this.getLoader()}
        {this.props.register.shop_msg&&<div style={{fontSize:20,marginTop:150}}>{this.props.register.shop_msg}</div>}
            <div id="recaptcha-container">{this.getCaptchaIfNotNative()}</div>
            {this.getQRAnalatics()}</div>;
    }
    getOrdersMenu(){
       try{
                const storageItem = JSON.parse(window.localStorage.getItem(this.props.register.QR_DELIVER._id+"_orders"));
if(!storageItem){

return null;
}
 if(!storageItem.orders){
                storageItem.orders={};
                return;
                 }
}catch(err){


}


    return <SubMenu label={<div><div className="material-icons delivery">
                    shopping_cart
                </div>{getKey(this.props.register.lang,'orders')}</div>}>

        {this.showOrders()}
        </SubMenu>

    }
    getPowerdBy(){
            if(!window.nativeqrdeliver){
                return(
                <div>
                        <a href={"https://www.qrdeliver.com"} target={"_blank"}>
                            <div
                                className={"label powerd"}>{getKey(this.props.register.lang.shop_data.qrdeliver, 'powerd_by_qrdeliver')}</div>
                        </a>
{this.getClimatePopup()}

                    </div>
                    )


            }


            return (<div>{this.getClimatePopup()}</div>)


    }
    getCookie(){

if(window.nativeqrdeliver)return null;
    if(!window.localStorage.getItem('qrcookie')){

   return(
    <CookieConsent
  location="bottom"
  buttonText={getKey(this.props.register.lang.shop_data.qrdeliver, 'ok_cookie')}
  cookieName="qrdeliver"
  style={{ background: "#2B373B" }}
  buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
onAccept={()=>{
window.localStorage.setItem('qrcookie','true')

}}
  expires={600}
>
  {getKey(this.props.register.lang.shop_data.qrdeliver, 'cookie_text_footer')}
</CookieConsent>

);

}
return null;

    }
    getShippingCity(){
       if (this.props.register.loading || !this.props.register.cart) return null;
    if(this.props.register.QR_DELIVER.shipping_type!==2){

    return null
    }

    return (<Menu className="main-menu" menuButton={<MenuButton disabled={!(this.props.register.shop_cities&&this.props.register.shop_cities.length>0)} width={100} style={{marginTop:10,cursor:'pointer'}}>
    {this.props.register.QR_DELIVER.shipping_city}</MenuButton>}>
    {this.getShopCities()}</Menu>)


return null;
    }
    getFavorites(count){
             if(window.debug)console.log(count)
      const storage=  Object.keys(window.localStorage);
        if(window.debug)console.log(storage,'storage');
        const checkForHexRegExp = /^[0-9a-fA-F]{24}$/;
        if(storage){
        var pindex=0;
if(count){
var cou=0;
storage.map((index)=>{
        pindex++;
    if(index.split("_order").length==1&&window.localStorage.getItem(index).split("{").length>1&&window.localStorage.getItem(index).split('"homeqrdeliver":true').length==1){



                const code =JSON.parse(window.localStorage.getItem(index)).code;

        if(code&&code.image){
        if(window.debug)console.log(code)
        cou++;
        }
}
})
return cou;

}
const mix={};
        return storage.map((index)=>{
        pindex++;
        if(index.split("_order").length==1&&window.localStorage.getItem(index).split("{").length>1&&window.localStorage.getItem(index).split('"homeqrdeliver":true').length==1){



                const code =JSON.parse(window.localStorage.getItem(index)).code;

if(!code||!code.image){

return null;
}

if(count)return 2;
if(!count){

if(mix[`${code.app}_${code.scope}_{code.group}_{code.currency}`]){
return null;
}
mix[`${code.app}_${code.scope}_{code.group}_{code.currency}`]=1;
            return (
            <MenuItem key={pindex} onClick={() => {

                                    window.location = '/'+code.url_key

                                }
                                }>

            <table width={'100%'}><tbody><tr><td><div className={`productimg ${this.getItemBG(code)}`} style={{width:40,height:40}}>
                                    <img src={code.image} onLoad={()=>{

                                    this.props.isRegister([`img_${md5(code.image)}`, 1]);
if(window.debug)console.log("image loaded",`img_${md5(code.image)}`);

                                    }} alt={code.user.name}/>

                                </div></td><td  width={'100%'}>{code.user.name}{/*<div className="langloc">{code.locale.split("_")[0]}{code.shipping_country?'-'+code.shipping_country:''}{code.shipping_city?'-'+code.shipping_city:''}</div>*/}</td><td><div className="material-icons delivery">
                    shopping_cart_checkout
                </div></td></tr></tbody></table>

            </MenuItem>
            )
}
  }
        })
        if(count){

        return 0;

        }

        }
        return null;

    }
    getSubGroups(){
    if(!this.props.register.menus)return null
    return(

     <SubMenu label={<div><div className="material-icons delivery">
                    apps
                </div>{getKey(this.props.register.lang,'favorites')}</div>}>
            {this.getMenuHeaders()}
        </SubMenu>
      )

    }

    getCaptchaIfNotNative(){
        if(!window.nativeqrdeliver&&!this.props.register.token){
            return(
            <Recaptcha
                locale={this.props.register.lang ? this.props.register.lang.key.split("_")[0] : 'en'}
                ref={ref => this.recaptcha = ref}
                sitekey={'6LeFfjIeAAAAABkQQMVFLTHbzU67WSGJXH-S5iHu'}
                onLoaded={() => {
                 if(this.recaptcha){
                     this.recaptcha.execute().catch((er)=>{

                     if(window.debug)console.log(er)
                     });
                    }

                    this.props.isRegister(['captcha_loaded',1]);
                    this.props.isRegister(['command', this.start.bind(this)]);

                }
                }
                onResolved={() => {
                    ;
                    this.props.isRegister(['captcha_token', this.recaptcha.getResponse()])
                    if (this.props.register.command) {

                        this.props.register.command();
                        this.props.register.command = undefined;
                    } else {
                        this.props.isRegister(['checkout', 0])
                        this.props.isRegister(['loading', 0])
                    }
                }}/>
                )

        }
        return null;
    }
    getClimatePopup() {
        //if(!this.props.register.buttoncheckout)return null
        if (this.props.register.climateinfo) {
            return (
                <div onClick={() => {
                    this.props.isRegister(['climateinfo', 0])
                }} className={"climate-holder"}><div className={"climate-img"} style={{backgroundImage:`url(${carbon})`}}></div><br/>{getKey(this.props.register.lang.shop_data.qrdeliver,'contribution_txt')} <a
                        href={getKey(this.props.register.lang.shop_data.qrdeliver, 'climate_url')}
                        target={'_blank'}>{getKey(this.props.register.lang.shop_data.qrdeliver, 'more_about')}</a>
                        </div>

            )
        }


        return (
            <div className={"climate-holder mini"}>
            <div className={"climate-img"} onClick={() => {
                this.props.isRegister(['climateinfo', 1])
            }} style={{backgroundImage:`url(${carbon})`}}></div></div>

        )

        return null;
    }
    getTotalShippingCosts(){
        var shop_costs=this.props.register.QR_DELIVER.shipping_costs

        var products = Object.keys(this.props.register.cart.products);

        products.map((item) => {
        if(window.debug)console.log(this.props.register.cart.products[item].shipping_costs,this.props.register.cart.products[item],'shipping_costs')
        if(this.props.register.cart.products[item].shipping_costs){

        shop_costs+=this.props.register.cart.products[item].shipping_costs*this.props.register.cart.products[item].qty;
        }


        });
        return shop_costs;
    }
    getShippingMsg() {
        if (!this.props.register.pickup&&this.getTotalShippingCosts() &&!this.props.register.checkout) {
            return (<div className={`minimum-price-amount shipping`}>
                <div className="material-icons delivery" style={{fontSize:40}}>
                    {this.props.register.QR_DELIVER.shipping_type==2&&'delivery_dining'}
                    {this.props.register.QR_DELIVER.shipping_type==3&&'local_shipping'}

                </div>
                <div className="add-voucher-link">{getKey(this.props.register.lang.shop_data.qrdeliver, 'shipping_costs')}: {this.getPriceFormated(this.getTotalShippingCosts())}</div>
            </div>)
        }
        return null

    }


}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({setUser, isRegister, regKey}, dispatch);

}

function mapStateToProps(state) {
    return {
        user: state.user,
        register: state.register


    }

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Game))
