import L from "leaflet";
import { createControlComponent } from "@react-leaflet/core";
    import "leaflet-routing-machine";
import css from "./routingmachine.css";
const createRoutineMachineLayer = (props) => {

console.log(props,"homs");
if(props.register.gpsLoading){

return null;
}
const opts={
    lineOptions: {
      styles: [{ color: "#6FA1EC", weight: 10,background:'#FFF' }]
    },
createMarker: function (i: number, waypoint: any, n: number) {
            var icon=i==0?props.register.QR_DELIVER.image:'https://www.qrdeliver.com/media/qrdelivergif.webp';

            if(props.courrier&&i==0){
            return null;
            }
            const marker = window.L.marker(waypoint.latLng, {
              draggable: false,
              bounceOnAdd: true,
              bounceOnAddOptions: {
                duration: 1000,
                height: 800,
                function() {
//                  (bindPopup(myPopup).openOn(map))
                }
              },
              icon: window.L.icon({
                iconUrl:icon,
                iconSize: [40, 40],
                iconAnchor: [20,40],
                popupAnchor: [-3, 6],
                shadowUrl: require('./assets/pointer_green_shadow.png'),
                shadowSize: [0, 0],
                shadowAnchor: [0, 0]
              })
            });
            return marker;
          },autoRoute:true,
    show: false,
waypoints:[

      L.latLng(Number(props.register.QR_DELIVER.delivery_from_gps.split(',')[0]),Number(props.register.QR_DELIVER.delivery_from_gps.split(',')[1])),
       L.latLng(Number(props.order.gps.lat),Number(props.order.gps.lon)),
    ],

    addWaypoints: false,
    draggableWaypoints: false,
    fitSelectedRoutes: true,
    defaultErrorHandler: function(e) {
				console.warn('Routing error:', e.error);
			},
    routeWhileDragging:true,
    showAlternatives: false,
    serviceURL:'https://routing.openstreetmap.de/routed-foot/route/v1/driving/',
       router:  L.Routing.osrmv1({serviceURL: 'https://routing.openstreetmap.de/routed-foot/route/v1/driving/',language:props.order.order.locale})

  }

//  if(props.order.courrier_gps){
//
//    opts.waypoints=[
//      L.latLng(Number(props.order.courrier_gps.split(',')[0]),Number(props.order.courrier_gps.split(',')[1])),
//      L.latLng(Number(props.order.gps.lat),Number(props.order.gps.lon)),
//      L.latLng(Number(props.register.QR_DELIVER.delivery_from_gps.split(',')[0]),Number(props.register.QR_DELIVER.delivery_from_gps.split(',')[1])),
//    ]
//  }
  //https://routing.openstreetmap.de/routed-foot/route/v1/driving/
  //https://router.project-osrm.org/route/v1
  if(props.order.gps){
console.log(Number(props.register.QR_DELIVER.delivery_from_gps.split(',')[0]),Number(props.register.QR_DELIVER.delivery_from_gps.split(',')[1]),'gps')
//  opts.waypoints= [
//      L.latLng(Number(props.order.gps.lat),Number(props.order.gps.lon)),
//      L.latLng(Number(props.register.QR_DELIVER.delivery_from_gps.split(',')[0]),Number(props.register.QR_DELIVER.delivery_from_gps.split(',')[1])),
//    ]
  }
  if(props.courrier&&props.order.courrier_gps){
  opts.center=props.center;
  opts.waypoints=[
   L.latLng(Number(props.order.courrier_gps.split(',')[0]),Number(props.order.courrier_gps.split(',')[1])),
   L.latLng(Number(props.register.QR_DELIVER.delivery_from_gps.split(',')[0]),Number(props.register.QR_DELIVER.delivery_from_gps.split(',')[1])),
   L.latLng(Number(props.order.gps.lat),Number(props.order.gps.lon)),


       ]
  }

  window.scope_instance = L.Routing.control(opts).on('routesfound', function(e) {
        var routes = e.routes;
       console.log(e);
       if(e.routes&&e.routes.length>0){


       window.estimation=e.routes[0].summary;
       props.isRegister(['route',e.routes[0]])

       }
    });

  return window.scope_instance;

};

const RoutingMachine = createControlComponent(createRoutineMachineLayer);

export default RoutingMachine;
