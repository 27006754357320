import React, { Component } from 'react';
import thunk from 'redux-thunk';
import {BrowserRouter,Route,Switch,useHistory} from 'react-router-dom';
//import './App.css';
import { Provider } from 'react-redux'
import { createStore ,applyMiddleware} from 'redux';
import todoApp from './reducers';
import Recharge from './recharge.js';
import QRHome from './home_screen.js';

const store = createStore(todoApp,applyMiddleware(thunk));

class App extends Component {
  constructor(props){
    super(props);

  }
  handleScriptCreate(){

  }
  handleScriptError(){

  }
  handleScriptLoad(){

  }
  getLobby(){
    if (this.props.register.wsuser&&this.props.register.wsuser.status=='lobby'){

        return(<div>


          lobby


        </div>)

    }
    return null;
  }
  browserCompatible(){
    // if user is running mozilla then use it's built-in WebSocket
    window.WebSocket = window.WebSocket || window.MozWebSocket;
    // if browser doesn't support WebSocket, just show some notification and exit
      if(!window.WebSocket){
        return(<div>
        Your browser does not support Websocket witch is an essential for this game to initialize. Try annother browser such as Chrome or any other modern browser. Thanks for your understanding!
        <ul className="simple">
        <li><p>Chrome 16 + (incl. Chrome for Android)</p></li>
        <li><p>Firefox 11 + (incl. Firefox for Android)</p></li>
        <li><p>Internet Explorer 10+ (incl. Internet Explorer Mobile on Windows
        Phone 8)</p></li>
        <li><p>Safari 6 +</p></li>
        <li><p>Opera 12.1 + (incl. Opera Mobile)</p></li>
        <li><p>iOS 6.0 +</p></li>
        <li><p>Blackberry 7 +</p></li>
        </ul>
      </div>)
      }
    return(
      <Switch>

            <Route path="/qrpay/:appid/:qrcode/" component={Recharge} />
            <Route path="/:url_key/" component={Recharge} />

          <Route path="/" component={QRHome} />

      </Switch>)
    return ;
  }
  renderShops(){




  }
  render() {

    return (

            <BrowserRouter path="/" key="home">


                {this.browserCompatible()}


            </BrowserRouter>




      );
  }
}

export default App;
