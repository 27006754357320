import React, {Component} from 'react';
import {connect} from 'react-redux';
//import ReCAPTCHA from "react-google-recaptcha";

import CookieConsent,{ Cookies, resetCookieConsentValue } from "react-cookie-consent";
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router';
import {setUser, isRegister, translate, getOS,serverRequest, regKey, getKey} from './actions/index';
import AnimatedNumber from "animated-number-react";
import 'react-phone-input-2/lib/style.css'
import PhoneInput from 'react-phone-input-2';
import NumberEasing from 'react-number-easing';
import {IdealBankElement, CardElement,EpsBankElement,P24BankElement,PaymentElement,useStripe, useElements, Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import ReactGA from 'react-ga';
import {useState} from 'react';
import queryString from "query-string";
import css from './cardDetailed.css';
import homecss from './home.css';
import Config from './config/config.js';
import ls from 'localstorage-slim';

import {
    Menu,SubMenu,
    MenuItem,MenuDivider,
    MenuButton
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
// import CurrencyInput from 'react-currency-input-field';
import getSymbolFromCurrency from 'currency-symbol-map';
import ReactCountryFlag from "react-country-flag";
import Autocomplete from "react-google-autocomplete";
import TimePicker from 'react-time-picker';
import background from "./assets/paymentmethods.png";
import bg_full from "./assets/bg.png";
import bg from "./assets/qrfooter.png";
import Recaptcha from 'react-google-invisible-recaptcha';
import carbon from './assets/carbon-removal-icon.png'
import Parser from 'html-react-parser';
import LazyLoad from 'react-lazyload';
import animatedLogo from "./assets/loader.base";
import { IFrame } from './iframe'
import {isMobile} from 'react-device-detect';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import Homemap from './homemap'
import {
    useRouteMatch
} from "react-router-dom";

import { Rating } from 'react-simple-star-rating';
import currencies from './assets/currencies.json';
import md5 from 'md5';
var stripePromise;
const paymentMethods = {
    'eur': [ 'card','p24', 'ideal', 'giropay',  'eps', 'bancontact'],
    'pln': [ 'card','p24']
}
const fonts=['Material Icons', 'Nunito'];

const  waitForWebfonts=function(fonts, callback) {
    var loadedFonts = 0;
    for(var i = 0, l = fonts.length; i < l; ++i) {
        (function(font) {
            var node = document.createElement('span');
            // Characters that vary significantly among different fonts
            node.innerHTML = 'giItT1WQy@!-/#';
            // Visible - so we can measure it - but not on the screen
            node.style.position      = 'absolute';
            node.style.left          = '-10000px';
            node.style.top           = '-10000px';
            // Large font size makes even subtle changes obvious
            node.style.fontSize      = '300px';
            // Reset any font properties
            node.style.fontFamily    = 'sans-serif';
            node.style.fontVariant   = 'normal';
            node.style.fontStyle     = 'normal';
            node.style.fontWeight    = 'normal';
            node.style.letterSpacing = '0';
            document.body.appendChild(node);

            // Remember width with no applied web font
            var width = node.offsetWidth;

            node.style.fontFamily = font;

            var interval;
            function checkFont() {
                // Compare current width with original width
                if(node && node.offsetWidth != width) {
                    ++loadedFonts;
                    node.parentNode.removeChild(node);
                    node = null;
                }

                // If all fonts have been loaded
                if(loadedFonts >= fonts.length) {
                    if(interval) {
                        clearInterval(interval);
                    }
                    if(loadedFonts == fonts.length) {
                        callback();
                        return true;
                    }
                }
            };

            if(!checkFont()) {
                interval = setInterval(checkFont, 50);
            }
        })(fonts[i]);
    }
};
var replacePostcodeStr=function(str,len=50) {
    if (str) {
        str = String(str).replace(/[^0-9,]/g, '');
        if (str.length > len) {
            str = str.substring(0, len);
        }
        if (str == '-') return '';
        return str;
    }


    return '';
}
var replaceString=function(str,len=50) {
    if (str) {
        str = String(str).replace(/[^0-9 a-z A-Z \u00C0-\u00FF]/g, '');
        if (str.length > len) {
            str = str.substring(0, len);
        }
        if (str == '-') return '';
        return str;
    }


    return '';
}
const ELEMENTS_OPTIONS = {
    fonts: [
        {
            cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
        },
    ],
    classes: {
        base: 'field',
        complete: 'complete',
        empty: 'is-empty',
        focus: 'is-focused',
        invalid: 'is-invalid',
        webkitAutofill: 'webkit-autofill',
    }
};

const SubmitButton = ({onClick, processing, error, children, disabled}) => (
    <button onClick={onClick}
            className={`SubmitButton ${error ? 'SubmitButton--error' : ''}`}
            type="submit"
            disabled={processing || disabled}
    >
        {processing ? 'Processing...' : children}
    </button>
);

const ErrorMessage = ({children}) => (
    <div className="ErrorMessage" role="alert">
        <svg width="16" height="16" viewBox="0 0 17 17">
            <path
                fill="#FFF"
                d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
            />
            <path
                fill="#6772e5"
                d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
            />
        </svg>
        {children}
    </div>
);
const Field = ({
                   label,
                   id,
                   type,
                   placeholder,
                   required,
                   autoComplete,
                   value,
                   onChange,
               }) => (
    <div className="FormRow">
        <label htmlFor={id} className="FormRowLabel">
            {label}
        </label>
        <input
            className="FormRowInput"
            id={id}
            type={type}
            placeholder={placeholder}
            required={required}
            autoComplete={autoComplete}
            value={value}
            onChange={onChange}
        />
    </div>
);
const CheckoutForm = (bttn) => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [cardComplete, setCardComplete] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(null);

    const [billingDetails, setBillingDetails] = useState({
        email: '',
        phone: '',
        name: '',
    });
    var obj = bttn;
    ////console.log(bttn,"bttn")
    const handleSubmit = (event) => {




        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        if (error) {
            elements.getElement('card').focus();
            return;
        }

        if (cardComplete) {
            //  setProcessing(true);
        }
        ////console.log(bttn.props.match);
        if(!bttn.props.register.buttoncheckout){


            bttn.props.history.push('#checkout');

            bttn.props.isRegister(['buttoncheckout', 1]);
            return;
        }

        //  bttn.props.isRegister(['loading', 1]);
        bttn.props.isRegister(['checkout', 1])
        bttn.props.isRegister(['error', '']);
        serverRequest('post', `qrdeliverpay/${bttn.props.match.params.appid}/${bttn.props.match.params.qrcode}`, {
            app: `${bttn.props.match.params.appid}`,
            type: 'fooi',
            qritem: `${bttn.props.match.params.qrcode}`,
            amount: bttn.getCartAmount(),
            cart: bttn.getCartProducts(),
            qty: 1,
            num: bttn.props.register.clientnum,
            postcode: bttn.props.register.postcode,
            voucher:bttn.props.register.voucher
        }, (res) => {


            if (res.res&&res.res.session && res.res.session.url) {
                ////console.log('res', res.res.session.url, bttn.props.register.postcode);
                window.localStorage.setItem(bttn.props.register.QR_DELIVER._id, JSON.stringify({
                    postcode: bttn.props.register.postcode,
                    cart: bttn.props.register.cart,
                    total_amount: bttn.props.register.total_amount
                }));
                if(!bttn.props.register.payment_method||bttn.props.register.payment_method=='card'){
                    window.location.href = res.res.session.url;
                }else{
                    ////console.log(res.res.session,"<<<_")

                    const sourceData = {
                        type: bttn.props.register.payment_method,
                        amount: res.res.session.amount_total,
                        currency:bttn.props.register.QR_DELIVER.currency,
                        owner: {
                            name: bttn.props.register.name,
                            email: bttn.props.register.email,
                            address:{
                                country:bttn.props.register.QR_DELIVER.shipping_country,
                                city:bttn.props.register.QR_DELIVER.shipping_city,
                                postal_code:bttn.props.register.postcode
                            }
                        },
                        metadata:{
                            appid:`${bttn.props.register.QR_DELIVER.user.appid}`,

                            account_id: `${bttn.props.register.QR_DELIVER.user.account_id}`,
                            code:bttn.props.match.params.qrcode,
                            token:res.res.session.token

                        },
                        redirect: {
                            return_url: res.res.session.return_url,
                        },
                        statement_descriptor: bttn.props.register.QR_DELIVER.user.company_desc
                    };
                    if(bttn.props.register[bttn.props.register.payment_method+'_bankSelection']){
                        sourceData[bttn.props.register.payment_method]={
                            bank:bttn.props.register[bttn.props.register.payment_method+'_bankSelection'],
                            statement_descriptor:bttn.props.register.QR_DELIVER.statement_descriptor
                        }
                    }else{
                        sourceData[bttn.props.register.payment_method]={
                            statement_descriptor:bttn.props.register.QR_DELIVER.statement_descriptor
                        }
                    }
                    const payload =  stripe
                        .createSource(sourceData)
                        .then(function(payload) {
                            if(payload&&payload.source&&payload.source.redirect){
                                window.location.href=payload.source.redirect.url;

                            }else if(payload.error){
                                bttn.props.isRegister(['checkout', 0])
                                bttn.props.isRegister(['error', payload.error.message]);
                                bttn.props.isRegister(['loading', false]);
                            }

                        }).catch((err)=>{
                            ////console.log(err)
                            bttn.props.isRegister(['checkout', 0])
                            bttn.props.isRegister(['error', err.message]);
                            bttn.props.isRegister(['loading', false]);
                        });
                }


            } else if (res.res&&res.res.msg) {
                ////console.log("???????")
                bttn.props.isRegister(['checkout', 0])
                bttn.props.isRegister(['error', res.res.msg]);
                bttn.props.isRegister(['loading', false]);
            }


        });

    };

    const reset = () => {
        setError(null);
        //setProcessing(false);
        setPaymentMethod(null);
        setBillingDetails({
            email: '',
            phone: '',
            name: '',
        });
    };
    const me=this;
    const opt={

        iconStyle: 'solid',
        style: {
            base: {
                color: '#000',
                lineHeight: '30px',
                cursor: 'pointer!important',
                fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                fontWeight: 'bold',
                '::placeholder': {
                    color: '#000',
                }, borderRadius: '20px', overflow: 'hidden!important', padding: '8px',
                background:'#ccc',
            },
            invalid: {
                color: '#B71C1C',
                iconColor: '#B71C1C',
            },

        }, theme: 'black',
        variables: {
            colorText: '#3c372f',
        },
        hidePostalCode: true,
        classes: {
            base: 'field',
            complete: 'complete',
            empty: 'is-empty',
            focus: 'is-focused',
            focus: 'is-focused',
            focus: 'is-focused',
            invalid: 'is-invalid',
            webkitAutofill: 'webkit-autofill',
        },
        hideIcon: false,
    }

    const getPaymentBank=()=>{
        if(bttn.canCheckoutForm()||bttn.getCartAmount()==0)return null;
        if(bttn.props.register.lang&&bttn.props.register.payment_method=='ideal'){
            return(
                <IdealBankElement  onChange={(ev) => {
                    ////console.log(ev)
                    bttn.props.isRegister(['ideal_bankSelection', ev.value]);
                }} options={opt}/>
            )
        }else
        if(bttn.props.register.lang&&bttn.props.register.payment_method=='card'){
            return(<div className={'payment-explain'}>
                {getKey(bttn.props.register.lang.shop_data.qrdeliver, 'card_redirect')}
                {/*<CardElement option={opt}/>*/}
            </div>)
        }
        return null
    }




    return paymentMethod ? (
        <div className="Result">
            <div className="ResultTitle" role="alert">
                Payment successful
            </div>
            <div className="ResultMessage">
                Thanks for trying Stripe Elements. No money was charged, but we
                generated a PaymentMethod: {paymentMethod.id}
            </div>
        </div>
    ) : (
        <form className="Form" onSubmit={(event)=>{

            event.preventDefault();
            /*
                        bttn.props.isRegister(['command',handleSubmit])
                        bttn.recaptcha.execute();*/
            handleSubmit(event)
        }}>
            {error && <ErrorMessage>{error.message}</ErrorMessage>}


            {getPaymentBank()}
            <SubmitButton processing={processing} error={error} disabled={bttn.getPayDisabled()||!stripe}>
                {bttn.getButtonTitle()}
            </SubmitButton>

        </form>
    );
};

class Game extends Component {
    constructor(props) {

        super(props);
        this.animatedAmount = React.createRef();
        this.voucherInput = React.createRef();
        this.searchfield=React.createRef();;
        this.emailInput = React.createRef();


    }


    getLoader(checkout) {
        if (!checkout) {

            return (
                <div className="lds-grid">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            )
        } else {
            return (

                <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>)
        }
    }

 start() {

//console.log("???????")
        if (this.props.register.QR_DELIVER) return;
        var me = this;
        const params = queryString.parse(this.props.history.location.search);
        me.props.isRegister(['loading', 1]);
        me.props.isRegister(['advanced_search', 0]);
        me.props.isRegister(['select_country', 0]);
        //me.props.isRegister(['menuFlipped', 1]);
        var storageItem = null;
        // window.localStorage.setItem(this.props.match.params.qrcode, null);
        if (window.localStorage.getItem('qrdeliver')) {
            ////console.log(window.localStorage.getItem('qrdeliver'))
            try {
                storageItem = JSON.parse(window.localStorage.getItem('qrdeliver'));
                ////console.log("storage item", storageItem);
                if(storageItem.city&&storageItem.city!=''){
                    this.props.isRegister(['city',storageItem.city])
                }
                if(storageItem.country&&storageItem.country!=''){
                    this.props.isRegister(['country',storageItem.country])
                }
                if(storageItem.qry&&storageItem.qry!=''){
                    this.props.isRegister(['qry',storageItem.qry])
                }
                if(storageItem.advanced_search){
                    this.props.isRegister(['advanced_search',storageItem.advanced_search])
                }
            } catch (err) {

            }
        }
        var post = {token: me.props.register.captcha_token};
        if (storageItem && storageItem.postcode) {
            post = {postcode: storageItem.postcode,

                city:me.props.register.city,country:me.props.register.country,
                qry:me.props.register.qry,
                token: me.props.register.captcha_token,t:9991};
        }


        post.homeqrdeliver=true;
        if(window.qrdelivertoken){
        post.utoken=window.qrdelivertoken

        }
        serverRequest('post', `fooiappmobile/qrdeliver/home`, post, (res) => {
            //var shop=window.localStorage.setItem('user', JSON.stringify(person));

            if (res.res && res.res.status   ) {
                try{
                ////console.log(storageItem,"<<<<<<<<<<<<storageItem");


                const accounts= [
                    {
                        trackingId: 'UA-133687885-9',
                        gaOptions: {
                            name: 'qrdeliver'

                        }
                    }
                ];


                ReactGA.initialize(
                    accounts,
                    { alwaysSendToDefaultTracker: true }
                );
                ReactGA.pageview(`${this.props.match.params.appid}/${this.props.match.params.qrcode}`, ['qrdeliver']);

                ////console.log(storageItem, "<<")
                me.props.isRegister(['gg_accounts', accounts]);
                me.props.isRegister(['loading', 0]);
                me.props.isRegister(['lang', res.res.language]);
                 me.props.isRegister(['langs', res.res.langs]);

                const params = queryString.parse(me.props.history.location.search);
                ////console.log(params);
                //https://localhost:3000/recharge?client_secret  =src_client_secret_iY68jaTOt9ehUtpN0uSKrMcf&livemode=true&redirect_status=succeeded&source=src_1IJbhaLRDgjS7ksTuMCZSRP2
                if (params.redirect_status) {
                    me.props.isRegister(['status', params.redirect_status])
                }



                if (me.props.register.search_timeout) {
                    clearTimeout(me.props.register.search_timeout);

                }
                var timer = setTimeout(function () {


                    me.props.isRegister(['command',()=>{
                        me.searchQuery({

                            postcode: me.props.register.postcode,
                            voucher: me.props.register.voucher,
                            street_name: me.props.register.street_name,
                            house_number: me.props.register.house_number,
                            house_number_addition: me.props.register.house_number_addition,
                            app: `${me.props.match.params.appid}`,
                            type: 'fooi',
                            qritem: `home`,
                            qty: 1,
                            num: me.props.register.clientnum,
                            postcode: me.props.register.postcode

                        })
                    }]);
                    if(!window.nativeqrdeliver){
                            if(me.recaptcha&&typeof me.recaptcha.execute =='function'&&!me.props.register.captcha_token){
                                me.recaptcha.execute();
                            }else if(me.props.register.command){
                                me.props.register.command();
                            }

                            }else if(me.props.register.command){

                             me.props.register.command();
                            }


                }, 1000);

                this.props.isRegister(['search_timeout', timer]);
                }catch(err){
//console.log(err)
                }
            } else {
                // window.location = 'https://www.qrdeliver.com';
                //window.location.reload();
            }
             this.resetbutton();
        });
    }
componentWillUnmount() {
  document.getElementById('root').removeEventListener('scroll', this.handleScroll);
}

    componentDidMount() {
        //console.clear();

document.getElementById('root').addEventListener('scroll', this.handleScroll.bind(this));
//   serverRequest('post','merchant/api/language/en_GB', {},response => {
//                     //   window.location.reload();
//
//                  });
        var me=this;

        var count=0;
        waitForWebfonts(fonts, function() {
            // Will be called as soon as ALL specified fonts are available


            //////console.log(this.props.location.hash=='#checkout',!this.props.register.buttoncheckout,this.props.location.hash,"<<dimount")
            // if(this.props.location.hash=='#checkout'&&!this.props.register.buttoncheckout){
            //     window.location = `/qrpay/${this.props.match.params.appid}/${this.props.match.params.qrcode}`;
            // }

          count+=1;
            if(count==fonts.length){

                    if(window.nativeqrdeliver&&window.qrdelivertoken){
                           me.props.isRegister(['utoken',window.qrdelivertoken]);
                     me.props.isRegister(['fonts_loaded',1]);

                        me.start();
                    }else{

                     me.props.isRegister(['fonts_loaded',1]);
                          me.start();
                           document.getElementById('bg').style.backgroundImage = 'url(/qrdeliver/img/footer2.png)';
                    document.getElementById('bg').style.backgroundSize = '50% auto ';
                    document.getElementById('bg').style.backgroundRepeat = 'repeat no-repeat ';
                    document.getElementById('bg').style.backgroundPosition= 'bottom center';

                      document.getElementById('art2').style.backgroundImage = 'url(/qrdeliver/img/art2.png)';
                    document.getElementById('art2').style.backgroundSize = '100% auto ';
                    document.getElementById('art2').style.backgroundRepeat = 'repeat no-repeat ';
                    document.getElementById('art2').style.backgroundPosition= 'bottom center';
                    //console.log(")___")

                    }
            }


        });
        //UA-133687885-8

    this.props.history.listen(location => {

            if (this.props.location.hash == '#home' ) {

                ////console.log(me.props, "<<mounted", me.props.location.hash == '', me.props.register.buttoncheckout)
            this.props.isRegister(['menuFlipped', 0]);

            }else if (this.props.location.hash == '#search' ) {


             me.props.isRegister(['menuFlipped',1]);

            }else{
              me.props.isRegister(['menuFlipped', 1]);
            }

        });
      if (this.props.location.hash == '#home' ) {

                ////console.log(me.props, "<<mounted", me.props.location.hash == '', me.props.register.buttoncheckout)
            this.props.isRegister(['menuFlipped', false]);

            }else{
              me.props.isRegister(['menuFlipped', 1]);
            }
    }
        getCookie(){

if(window.nativeqrdeliver)return null;
    if(!window.localStorage.getItem('qrcookie')){

   return(
    <CookieConsent
  location="bottom"
  buttonText={getKey(this.props.register.lang.shop_data.qrdeliver, 'ok_cookie')}
  cookieName="qrdeliver"
  style={{ background: "#2B373B" }}
  buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
onAccept={()=>{
window.localStorage.setItem('qrcookie','true')

}}
  expires={600}
>
  {getKey(this.props.register.lang.shop_data.qrdeliver, 'cookie_text_footer')}
</CookieConsent>

);

}
return null;

    }
     getFavorites(count){
     var mix={};
//             console.log(count)
      const storage=  Object.keys(window.localStorage);
        //console.log(storage,'storage');
        const checkForHexRegExp = /^[0-9a-fA-F]{24}$/;
        if(storage){
        var pindex=0;
if(count){
var cou=0;

storage.map((index)=>{
        pindex++;
        try{
    if(index.split("_order").length==1&&window.localStorage.getItem(index).split("{").length>1&&window.localStorage.getItem(index).split('"homeqrdeliver":true').length==1){






        const code =JSON.parse(window.localStorage.getItem(index)).code;

        if(code&&code.image){
//        console.log(code)
        cou++;
        }
        }

}catch(err){}

})
return cou;

}

        return storage.map((index)=>{
        pindex++;
        if(index.split("_order").length==1&&window.localStorage.getItem(index).split("{").length>1&&window.localStorage.getItem(index).split('"homeqrdeliver":true').length==1){

                try{
                 const code =JSON.parse(window.localStorage.getItem(index)).code;

if(!code)return null;

if(mix[`${code.app}_${code.scope}_{code.group}_{code.currency}`]){
return null;
}
mix[`${code.app}_${code.scope}_{code.group}_{code.currency}`]=1;
if(!code||!code.image){

return null;
}

if(count)return 2;
if(!count){




            return (
            <MenuItem key={pindex} onClick={() => {

                                    window.location = '/' + code.url_key

                                }
                                }>

            <table width={'100%'}><tbody><tr><td><div className={`productimg ${this.getItemBG(code)}`} style={{width:40,height:40}}>
                                    <img src={code.image} onLoad={()=>{

                                    this.props.isRegister([`img_${md5(code.image)}`, 1]);
//console.log("image loaded",`img_${md5(code.image)}`);

                                    }} alt={code.user.name}/>

                                </div></td><td  width={'100%'}>{code.user.name}{/*<div className="langloc">{code.locale.split("_")[0]}{code.shipping_country?'-'+code.shipping_country:''}{code.shipping_city?'-'+code.shipping_city:''}</div>*/}</td><td><div className="material-icons delivery">
                    shopping_cart_checkout
                </div></td></tr></tbody></table>

            </MenuItem>
            )
}
}catch(err){return null}
  }
        })
        if(count){

        return 0;

        }

        }
        return null;

    }
    componentDidUpdate(prevProps, prevState){
        ////console.log("mount2",this.props.history);
        //console.log(prevProps,"statea")
       if (!window.qrdelivertoken&&this.props.register.lang&&this.props.register.lang!=prevProps.register.lang) {
            if(window.nativeqrdeliver)return;
            this.setCaptchaLang(document.getElementById("recaptcha-container"), this.props.register.lang.key.split('_')[0]);
//console.log("language set")
        }
        if(window.qrdelivertoken&&window.nativeqrdeliver&&this.props.register.utoken&&!this.props.register.loading&&this.props.register.utoken!=prevProps.register.utoken){
          document.body.style.backgroundColor='transparent';
                                document.documentElement.style.backgroundColor='transparent';
            this.props.isRegister(['utoken',window.qrdelivertoken]);
            this.start();
        }else if(!window.nativeqrdeliver&&this.props.register.fonts_loaded&&this.props.register.fonts_loaded!=prevProps.register.fonts_loaded){

        this.start();
        }
    }
    searchQuery(p) {
        var me = this;
        if(!this.props.register.country){
        this.props.register.country='NL';
        }
        if(!this.props.register.currency){
        this.props.register.currency='EUR';
        }
        ////console.log('>>qerry',p);
        const params = queryString.parse(this.props.history.location.search);
        this.props.isRegister(['search_loading', true]);
                if(params.test_mode){
                p.test_mode=true;
                }
            p.country=this.props.register.country;
            if(currencies[this.props.register.country]){

            p.currency=currencies[this.props.register.country].defaultCurrency;
            }
        p.category=this.props.register.select_category?this.props.register.select_category.title:this.props.register.lang.shop_data.qrdeliver.categories[0].title;;
        this.props.isRegister(['msg','']);
        window.localStorage.setItem('qrdeliver', JSON.stringify({
            city: me.props.register.city,
            country: me.props.register.country,
            homeqrdeliver:true,
            advanced_search:me.props.register.advanced_search,
            qry:me.props.register.qry,
            country:me.props.register.country

        }));
 p.advanced=!me.props.register.advanced_search;
        p.homeqrdeliver=true;
        p.token =me.props.register.captcha_token;

        if(me.props.register.utoken){
        p.utoken =me.props.register.utoken;

        }
        p.qry=me.props.register.qry;
        p.country=me.props.register.country
        p.city=me.props.register.city;
        p.category=me.props.register.select_category?me.props.register.select_category.title:me.props.register.lang.shop_data.qrdeliver.categories[0].title;;
       p.category= p.category.toLowerCase()
         p.language=me.props.register.lang.key;
         if(params.test_mode){
                p.test_mode=true;
                }
        ReactGA.ga('qrdeliver.send', 'event',`fooiappmobile/qrdeliver/search`, JSON.stringify(p,true,2));

          this.props.isRegister(['lastquery',p]);
        serverRequest('post', `fooiappmobile/qrdeliver/search`, p, (res) => {


            if (res.res && res.res.shops!=null) {


                this.props.isRegister(['menus',res.res.shops]);
                // if(res.res.shops.length==0){
                //     this.props.isRegister(['msg',getKey(this.props.register.lang.shop_data.qrdeliver, 'nothing_found')])
                // }

            }else{
              if(res.res.msg&&res.res.msg=='captcha_is_not_correct'){
                 // window.location.reload();
              }
               // this.props.isRegister(['msg',getKey(this.props.register.lang.shop_data.qrdeliver, 'nothing_found')])
                this.props.isRegister(['menus',[]])
            }

         this.props.isRegister(['search_loading', false]);


        });
    }

    reload(p) {
        var me = this;
        const params = queryString.parse(this.props.history.location.search);
        this.props.isRegister(['loading', true]);

        ReactGA.ga('qrdeliver.send', 'event',`qrpayproducts/${me.props.match.params.appid}/${me.props.match.params.qrcode}_postcode_loading`, p.postcode);


        window.localStorage.setItem(this.props.register.QR_DELIVER._id, JSON.stringify({
            postcode: p.postcode,
            cart: this.props.register.cart,
            total_amount: this.props.register.total_amount,
            token:this.props.register.captcha_token
        }));

        serverRequest('post', `fooiappmobile/${this.props.match.params.appid}/${this.props.match.params.qrcode}`, p, (res) => {

            ReactGA.ga('qrdeliver.send', 'event',`qrpayproducts/${me.props.match.params.appid}/${me.props.match.params.qrcode}_postcode_loaded`, p.postcode);


            if (res.res && res.res.status) {
                me.props.isRegister(['amount', 1]);
                me.props.isRegister(['total_amount', 1 + Number(Config.fee)]);
                const params = queryString.parse(me.props.history.location.search);
                ////console.log(params);
                //https://localhost:3000/recharge?client_secret  =src_client_secret_iY68jaTOt9ehUtpN0uSKrMcf&livemode=true&redirect_status=succeeded&source=src_1IJbhaLRDgjS7ksTuMCZSRP2
                if (params.redirect_status) {
                    me.props.isRegister(['status', params.redirect_status])
                }
                ;
                stripePromise = loadStripe(res.res.code.pk);
                me.props.isRegister(['QR_DELIVER', res.res.code]);
            }
            if (me.props.register.QR_DELIVER.is_menu) {

                me.props.isRegister(['cart', {products: {}}]);
                me.props.isRegister(['step', 0]);
                serverRequest('post', `qrpayproducts/${me.props.match.params.appid}/${me.props.match.params.qrcode}`, {token: me.props.register.captcha_token,t:999}, (res) => {

                    if (res.res && res.res.products) {
                        me.props.isRegister(['products', res.res.products]);
                        me.props.isRegister(['menus', res.res.headers]);
                    }

                    if (me.props.register.QR_DELIVER.request_postcode) {
                        me.props.isRegister(['postcoderequest', true]);
                    }
                    if (res.res.code.msg) {
                        me.props.isRegister(['error', res.res.code.msg])
                    }
                    this.props.isRegister(['loading', false]);

                });
            } else {
                this.props.isRegister(['loading', false]);
            }


        });
    }

    setAmount(val) {


        this.props.isRegister(['amount', val]);
        this.props.isRegister(['total_amount', Number(val) + Number(Config.fee)]);


    }

    getAmount() {

        if (this.props.register.QR_DELIVER.user.fixed) {
            return (this.props.register.QR_DELIVER.user.amount)
        }
        if (this.props.register.amount) {
            return this.props.register.amount
        }
        return 0.00;
    }

    handleScriptCreate() {

    }

    handleScriptError() {

    }

    handleScriptLoad() {

    }

    getNumValue() {
        if (this.props.register.clientnum) {
            return this.props.register.clientnum
        }
        return null;
    }


    getRate() {
        return 0.1;
    }

    currentAction() {

    }

    getCurrency() {
        return new Intl.NumberFormat('nl-NL', {
            style: 'currency',
            currency: this.props.register.QR_DELIVER.user.currency
        }).format(this.props.register.amount);
    }

    getCurrencyAmount(amount) {
        return new Intl.NumberFormat('nl-NL', {
            style: 'currency',
            currency: this.props.register.QR_DELIVER.user.currency
        }).format(amount);
    }

    getAmountField() {
        if (this.props.register.QR_DELIVER.user.fixed) {
            return (<div
                className="form-outline recharge-amount"> {this.props.register.QR_DELIVER.user.currency} {this.props.register.amount}
            </div>);
        }
        return (
            <div className="form-outline recharge-amount">




            </div>
        )
    }

    getPriceFormated(amount) {
        ////console.log(this.props.register.lang.key)
        return new Intl.NumberFormat(this.props.register.lang.key.split('_')[0], {
            style: 'currency',
            currency: this.props.register.QR_DELIVER.user.currency
        }).format(amount / 100)
    }

    getCountryRestriction(item) {
        ////console.log(this.props.register.lang.shop_data.qrdeliver)
        if (item.restrict_shipping) {
            return (
                <div>  {getKey(this.props.register.lang.shop_data.qrdeliver.restricted_shipping_types, item.shipping_type)}
                    <ReactCountryFlag
                        countryCode={item.shipping_country}/>{`${item.shipping_country} ${item.shipping_city}`}</div>
            )
        }
        return <div>&#127758;</div>;
    }

    getShippingHeader(item) {

        const params = queryString.parse(this.props.history.location.search);

        if (!this.props.register.buttoncheckout&&!this.props.register.menuFlipped && !params.redirect_status && item.restrict_shipping) {
            return (
                <div><ReactCountryFlag
                    countryCode={item.shipping_country}/>{`${item.shipping_country} ${item.shipping_city}`}</div>
            )
        }
        return null;
    }
 getItemBG(item){
//console.log(md5(item.image),'<<<<');
    if(this.props.register[`img_${md5(item.image)}`]){
    //console.log("style set")
    return "loaded"
    }
    return '';
    }
    getMenusItems(ps) {

        return ps.map((item) => {
    const uri=`/${item.url_key}`
 //const uri=`/qrpay/${item.app}/${item._id}`;
            return <tr key={item.category + item._id}>

                <td colSpan="3" className="product">
                   <a href={uri} onClick={(evt) => {evt.preventDefault() ;return;}} title={`${item.user.name}`} >
                    <table>
                        <tbody>
                        <tr className={"listitem"}onClick={() => {
 if(window.localStorage.getItem(item._id)===null){
                  window.localStorage.setItem(item._id, JSON.stringify(this.props.register));

                  }else{



                 // window.localStorage.setItem(item._id, JSON.stringify(this.props.register));


                  }

                                    window.location = uri

                                }
                                }>
                            <td>

                                <div className={`productimg ${this.getItemBG(item)}`}>
                                    <img src={item.image} onLoad={()=>{

                                    this.props.isRegister([`img_${md5(item.image)}`, 1]);
//console.log("image loaded",`img_${md5(item.image)}`);

                                    }} alt={item.user.name}/>

                                </div>

                            </td>
                            <td className={"productname"}>

                            <div className={'item_title'}>{item.user.name}</div>
                            <div className={'item_description'}>{item.user.company_desc}</div>

                            <div className={'item_description'}>{this.getItemSpecs(item)}</div>



                            </td>

                            <td>
                                <div className={"goto material-icons"} >shopping_cart_checkout

                                </div>

                            </td>
                        </tr>
                        </tbody>
                    </table>
    </a>
                </td>
            </tr>


        })
    }
    getItemSpecs(item){
if(item.AverageRating){

//console.log(item.AverageRating,"<<ratig",item.AverageRating[0].AverageRating,item.AverageRating[0].AverageRating.toFixed(2))
}
        return(
        <div className="item-specs">

{item.AverageRating&&<div><Rating allowHover={false} allowHalfIcon={true} readonly={true} transition={true} initialValue={item.AverageRating[0].AverageRating.toFixed(2)} size={10}/></div>}
   {item.restrict_shipping&&<div className={"material-icons"}>electric_moped</div>}
            {item.shipping_type==2&&<div className={"material-icons"}>local_shipping</div>}
            {item.shipping_type==1&&<div className={"material-icons"}>payments</div>}
             {item.shipping_type==3&&<div className={"material-icons"}>plane</div>}


        </div>


        )

    }
    getInc(item) {
        if (this.props.register.QR_DELIVER.tax) {
            var tax_amount = Number((item.user.amount * (this.props.register.QR_DELIVER.tax / 100)).toFixed(0));

            if (item.tax_included) {


                if (tax_amount) {
                    return (<div>inc {tax_amount}</div>);
                }
            } else {
                var tax_amount = Number((item.user.amount * (this.props.register.QR_DELIVER.tax / 100)).toFixed(0));

                return (<div>excl {tax_amount}</div>);
            }


        }

    }

    getProducts(ps) {

        return ps.map((item) => {

        const uri=`/${item.url_key}`;
        //const uri=`/${item.app}/${item._id}`;
            return <tr key={item.category + item._id}>
                <td colSpan="3" className="product">
                  <a href={uri} onClick={()=>{
                  if(window.localStorage.getItem(item._id)==''){
                  window.localStorage.setItem(item._id, JSON.stringify(this.props.register));

                  }
                  }}title={`/${item.user.name}`} >
                    <table>
                        <tbody>
                        <tr>
                            <td>
                                <div className="productimg">
                                    <img src={item.image} alt={item.user.name}/>
                                </div>

                            </td>
                            <td className={"productname"}>{item.user.name}</td>
                            <td>{this.getPriceFormated(item.user.amount)}{this.getInc(item)}</td>
                        </tr>
                        <tr>
                            <td colSpan={3}>
                                <table className="productActions">
                                    <tbody>
                                    <tr>
                                        <td>
                                            <div className={"plus"} onClick={() => {

                                                var json = JSON.parse(JSON.stringify(this.props.register.cart));
                                                if (!json.products[item._id]) {
                                                    json.products[item._id] = item;
                                                }


                                                var current = json.products[item._id].qty ? json.products[item._id].qty : 0;
                                                if (json.products[item._id].manage_stock && json.products[item._id].stock >= (current + 1)) {
                                                    current += 1;
                                                } else if (!json.products[item._id].manage_stock) {
                                                    current += 1;
                                                }
                                                json.products[item._id].qty = Number(current);
                                                this.props.isRegister(['cart', json])
                                                this.props.isRegister(['buttoncheckout', 0]);
                                            }
                                            }>+
                                            </div>
                                        </td>
                                        <td className="amount">
                                            <input min="1" max={item.stock ? item.stock : 9999} width="100"
                                                   type="number"
                                                   value={this.props.register.cart.products[item._id] ? this.props.register.cart.products[item._id].qty : this.props.register.step}

                                                   onChange={(e) => {

                                                       var json = JSON.parse(JSON.stringify(this.props.register.cart));
                                                       if (!json.products[item._id]) {
                                                           json.products[item._id] = item;
                                                       }
                                                       if (json.products[item._id].manage_stock && e.target.value > json.products[item._id].stock) {
                                                           e.target.value = json.products[item._id].stock;
                                                       }
                                                       json.products[item._id].qty = Number(e.target.value);
                                                       this.props.isRegister(['cart', json])
                                                       this.props.isRegister(['buttoncheckout', 0]);
                                                   }
                                                   }

                                            />
                                        </td>
                                        <td>
                                            <div className="minus" onClick={() => {
                                                var json = JSON.parse(JSON.stringify(this.props.register.cart));
                                                if (!json.products[item._id]) {
                                                    json.products[item._id] = item;
                                                }


                                                var current = json.products[item._id].qty ? json.products[item._id].qty : 0;
                                                if ((current - 1) >= 0) {
                                                    current -= 1;
                                                }
                                                json.products[item._id].qty = Number(current);

                                                this.props.isRegister(['cart', json])
                                                this.props.isRegister(['buttoncheckout', 0]);

                                            }
                                            }>-
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>

                                </table>
                            </td>

                        </tr>
                        </tbody>
                    </table>
                    </a>
                </td>
            </tr>


        })
    }
    getCartCount(sku) {
        var cn=0;
        var products = Object.keys(this.props.register.cart.products);
        products.map((item) => {


            if(sku&&item==sku){
                cn+=this.props.register.cart.products[item].qty
            }else{
                cn+=this.props.register.cart.products[item].qty
            }


        })
        return cn;
    }
    getHeaders() {
        var headers = {};
        this.props.register.products.map((item) => {

            if (!headers[item.category]) {
                headers[item.category] = [];
            }

            // if (item.subGroup) {
                headers[item.category].push(item)
            // }

        })
        ////console.log(headers)
        return Object.keys(headers).map((index) => {
            ////console.log(index, "<<", headers[index]);

            return (

                <div key={index} className="l">
                    <div>{index}</div>
                    <table className="productsTable">
                        <tbody>{this.getProducts(headers[index])}</tbody>
                    </table>
                </div>

            );
        })


    }

    getMenuHeaders() {
        if(!this.props.register.menus)return null
        var headers = {};
        this.props.register.menus.map((item) => {
            if (!headers[item.category]) {
                headers[item.category] = [];
            }

                headers[item.category].push(item)


        })
        ////console.log(headers)
        return Object.keys(headers).map((index) => {
            ////console.log(index, "<<", headers[index]);

            return (

                <div key={index}>
                    <div>{index}</div>

                    <table className="productsTable">
                        <tbody>{this.getMenusItems(headers[index])}</tbody>
                    </table>
                </div>

            );
        })


    }

    getCartProducts() {
        var products = Object.keys(this.props.register.cart.products);
        var totalAmount = 0;
        var me = this;
        var cart = {};
        ////console.log(products)
        products.map((item) => {
            cart[item] = this.props.register.cart.products[item].qty;
        })

        return cart;
    }

    getCartAmount(reversed,tax_) {
        var products = Object.keys(this.props.register.cart.products);
        var totalAmount = 0;
        var me = this;
        var taxAmount = 0;
        products.map((item) => {
            ////console.log(item, "<<<")
            if (me.props.register.QR_DELIVER.tax) {

                if (!this.props.register.cart.products[item].tax_included) {
                    var tax_amount = Number((this.props.register.cart.products[item].user.amount * (me.props.register.QR_DELIVER.tax / 100)).toFixed(0));

                    taxAmount += this.props.register.cart.products[item].qty * tax_amount;

                }

            }
            totalAmount += this.props.register.cart.products[item].qty * this.props.register.cart.products[item].user.amount;


        })

        if(me.props.register.QR_DELIVER.voucher_valid&&me.props.register.QR_DELIVER.voucherData&&me.props.register.QR_DELIVER.voucherData.coupon){
            var amount=0;
            const cp=me.props.register.QR_DELIVER.voucherData.coupon;
            ////console.log(totalAmount, "tax")
            if(cp.amount_off) {
                if (totalAmount > cp.amount_off) {
                    amount = totalAmount - cp.amount_off
                } else {
                    amount = totalAmount
                }
            }else if(cp.percent_off&&(taxAmount+totalAmount)*(cp.percent_off/100)>=0){
                amount = (taxAmount+totalAmount)*(cp.percent_off/100);
            }
            if(reversed){
                return amount;
            }else{
                totalAmount -=amount;
            }

        }
        if (this.props.register.QR_DELIVER.shipping_costs) {
            totalAmount += this.props.register.QR_DELIVER.shipping_costs
        }
        ////console.log(totalAmount, "tax",taxAmount)
        if(tax_)return taxAmount;
        return totalAmount + taxAmount;
    }
    getTotalCartCount() {
        var products = Object.keys(this.props.register.cart.products);
        var totalAmount = 0;
        var me = this;
        var totalItems = 0;
        products.map((item) => {

            totalItems +=  this.props.register.cart.products[item].qty;


        })

        return totalItems;
    }


    getShippingsField(){
        if(this.props.register.QR_DELIVER.restricted_shipping){

            if(this.props.register.QR_DELIVER.city){

            }
            return(<div>
                    <ReactCountryFlag
                        countryCode={this.props.register.QR_DELIVER.shipping_country}/>{`${this.props.register.QR_DELIVER.shipping_country} ${this.props.register.QR_DELIVER.shipping_city}`}</div>
            )
        }

        return null;
    }
    getStreetAndNum(){

        if (this.props.register.QR_DELIVER.shipping_required) {
            return (


                <div>{this.getShippingsField()}
                    <div className={'label'}>{getKey(this.props.register.lang.shop_data.qrdeliver, 'shipping_address')} </div>
                    {this.getHelper()}

                    <input className="housenr-field"
                           value={this.props.register.house_number ? this.props.register.house_number : ""}
                           onChange={(evt) => {
                               this.props.isRegister(['house_number', !isNaN(evt.target.value.replace(/^\D+/g, '')) ? Number(evt.target.value.replace(/^\D+/g, '')) : null])

                           }} placeholder={getKey(this.props.register.lang.shop_data.qrdeliver, 'house_number_hint')}
                           name="huisnumber" type="text" pattern="[0-9]"/>


                    <input className="housenr-field addition"
                           value={this.props.register.house_number_addition ? this.props.register.house_number_addition : ""}
                           onChange={(evt) => {
                               this.props.isRegister(['house_number_addition', evt.target.value])

                           }}
                           placeholder={getKey(this.props.register.lang.shop_data.qrdeliver, 'house_number_addtion_hint')}
                           name="huisnumber-addtion" type="text" pattern="[0-9]*"/>

                </div>)
        }
    }


    getComments() {

        return (
            <div>
                <div>{getKey(this.props.register.lang.shop_data.qrdeliver, 'comments')} </div>

                <textarea className="street-field "
                          value={this.props.register.comments ? this.props.register.comments : ""} onChange={(evt) => {
                    this.props.isRegister(['comments', evt.target.value])

                }} placeholder={getKey(this.props.register.lang.shop_data.qrdeliver, 'comments_hint')} name="huisnumber"
                          type="text" pattern="[0-9]"/>

            </div>)

    }

    getPhone() {
        if(!this.props.register.stripe_checkout&&this.props.register.buttoncheckout) {
            if (this.props.register.QR_DELIVER.phone_required) {
                return (
                    <div>
                        <div className={"label"}>{getKey(this.props.register.lang.shop_data.qrdeliver, 'phone_nr')} </div>

                        <PhoneInput
                            country={this.props.register.QR_DELIVER.shipping_country.toLowerCase()}
                            value={this.props.register.phone ? this.props.register.phone : ''}
                            onChange={phone => this.props.isRegister(['phone',phone])}
                        />
                    </div>)
            }
        }
        return null;
    }
    preReduction(){
        if (this.props.register.QR_DELIVER.voucherData) {
            return(<div className={"reduction-label"}>{getKey(this.props.register.lang.shop_data.qrdeliver, 'reduction')}: {this.getPriceFormated(-this.getCartAmount(true))} </div>)
        }
        return null;
    }

    setCaptchaLang(recaptchaContainer, lang) {
        if(!recaptchaContainer)return;
        lang = lang || "en";

        // 1. Search for the ReCaptcha iframe
        const iframeGoogleCaptcha = recaptchaContainer.querySelector('iframe');
if(!iframeGoogleCaptcha)return;
        // 2. Retrieve the current language
        const currentLang = iframeGoogleCaptcha.getAttribute("src").match(/hl=(.*?)&/).pop();

        // 3. Verify if the language that you want to set is different to the current one
        if (currentLang !== lang) {
            // 4. If it is, change it
            iframeGoogleCaptcha.setAttribute(
                "src",
                iframeGoogleCaptcha.getAttribute("src").replace(
                    /hl=(.*?)&/,
                    'hl=' + lang + '&'
                )
            );
        }
    }
    addVoucher() {
        if(this.props.register.checkout||this.props.register.loading)return null

        if(!this.props.register.stripe_checkout) {
            if (this.props.register.voucher_enabled) {

                return (
                    <div>
                        <div>{getKey(this.props.register.lang.shop_data.qrdeliver, 'voucher')} </div>

                        {this.getCurrentVoucher()}
                    </div>)
            } else if (this.props.register.QR_DELIVER.voucher_enabled) {


                return (
                    <div className="voucher-label">
                        <div className="material-icons">label</div>
                        <div className="add-voucher-link" onClick={() => {

                            this.props.isRegister(['voucher_enabled', !this.props.register.voucher_enabled]);

                        }
                        }>{getKey(this.props.register.lang.shop_data.qrdeliver, 'add_voucher')} </div>


                    </div>)

            }
        }
        return null


    }

    getMinimumAmount() {
        if (this.props.register.QR_DELIVER.minimum_amount) {
            return (<div
                className={"minimum-price-amount"}>{getKey(this.props.register.lang.shop_data.qrdeliver, 'minimum_order_amount_required')}: {this.getPriceFormated(this.props.register.QR_DELIVER.minimum_amount)}</div>)
        }
        return null;
    }

    getHelper() {
        if (this.props.register.QR_DELIVER.gg_id) {
            if (this.props.register.street_name) {
                return (<input className="street-field"
                               value={this.props.register.street_name ? this.props.register.street_name : ""}
                               onChange={(evt) => {
                                   this.props.isRegister(['street_name', evt.target.value])

                               }}
                               placeholder={getKey(this.props.register.lang.shop_data.qrdeliver, 'house_number_hint')}
                               name="huisnumber" type="text" pattern="[0-9]"/>
                )
            }
            return (<div>{this.props.register.lang&&<Autocomplete
                apiKey={this.props.register.QR_DELIVER.gg_id}
                    language={this.props.register.lang.simple}
                style={{
                    width: "90%",
                    padding: '10px',
                    border: '1px solid #000',
                    borderRadius: '4px',
                    fontSize: '14px',
                    fontFamily: 'Nunito'
                }}
                onPlaceSelected={(place) => {
                    ////console.log(place)
                    var add = {street: ''};
                    if (place && place.address_components) {

                        place.address_components.map((item) => {
                            if (item.types[0] == 'route') {
                                add.street = item.long_name;
                            }
                            if (item.types[0] == 'street_number') {
                                add.street_number = item.long_name;
                            }
                        })
                        if (add.street) {
                            ////console.log("<><><><><><>!")

                            this.props.isRegister(['street_name', add.street])
                        }
                        if (add.street_number) {
                            ////console.log(add.street_number, "<><><><><><>!")

                            this.props.isRegister(['house_number', add.street_number.toString()])
                        }
                    } else {

                    }


                }}
                inputAutocompleteValue={this.props.register.street_name}
                language={this.props.register.QR_DELIVER.locale}
                options={{

                    types: ["address"],
                    componentRestrictions: {country: this.props.register.country ? this.props.register.country : 'NL'},
                }}

                defaultValue={this.props.register.qry ? this.props.register.qry : ''}
            />}<input className="street-field"
                     value={this.props.register.street_name ? this.props.register.street_name : ""}
                     onChange={(evt) => {
                         this.props.isRegister(['street_name', evt.target.value])

                     }}
                     placeholder={getKey(this.props.register.lang.shop_data.qrdeliver, 'street_name_hint')}
                     name="street_name" type="text" /></div>)
        } else {
            return (<input className="street-field"
                           value={this.props.register.street_name ? this.props.register.street_name : ""}
                           onChange={(evt) => {
                               this.props.isRegister(['street_name', evt.target.value])

                           }} placeholder={getKey(this.props.register.lang.shop_data.qrdeliver, 'house_number_hint')}
                           name="huisnumber" type="text" pattern="[0-9]"/>
            )
        }
        return null;
    }
    getShippingFields(){
        if(!this.props.register.QR_DELIVER.stripe_checkout){
            return(<div className="shippingform">

                    {this.getStreetAndNum()}


                </div>
            )
        }
        return null;
    }
    getCardIcon(index){
        if(index=='card'){
            return(
                <div className="material-icons cc">
                    credit_card
                </div>
            );

        }
        return null;
    }
    getNameField(){
        if(this.props.register.payment_method=='eps'||this.props.register.payment_method=='p24'){
            return (<input className="street-field"
                           value={this.props.register.name ? this.props.register.name : ""}
                           onChange={(evt) => {
                               this.props.isRegister(['name', evt.target.value])

                           }}
                           placeholder={getKey(this.props.register.lang.shop_data.name, 'name_hint')}
                           name="huisnumber" type="text" pattern="[0-9]"/>
            )
        }
        return null;
    }
    getEmailField(){
        if(this.props.register.payment_method=='eps'||this.props.register.payment_method=='p24'){
            return (<input className="street-field"
                           value={this.props.register.name ? this.props.register.name : ""}
                           onChange={(evt) => {
                               this.props.isRegister(['name', evt.target.value])

                           }}
                           placeholder={getKey(this.props.register.lang.shop_data.name, 'name_hint')}
                           name="huisnumber" type="text" pattern="[0-9]"/>
            )
        }
        return null;
    }
    getPaymentMethods(){
        if(this.canCheckoutForm()||this.getCartAmount()==0)return null;
        var ps=[];
        if(paymentMethods[this.props.register.QR_DELIVER.currency]){
            paymentMethods[this.props.register.QR_DELIVER.currency].map((index)=>{

                if (this.props.register.payment_method == index) {


                    ps.push(<div style={{backgroundImage: `url(${background})`}} key={index}
                                 className={`payment-method p-${index}`} onClick={()=>{
                        this.props.isRegister(['select_method',!this.props.register.select_method])

                    }
                    }>
                        {this.getCardIcon(index)}
                        <div className={"pm-name"}>{index}</div>
                        <div className="material-icons ">
                            arrow_drop_down
                        </div>
                    </div>);
                }

            })
            if(this.props.register.select_method){
                paymentMethods[this.props.register.QR_DELIVER.currency].map((index)=>{

                    if (this.props.register.payment_method != index) {

                        ps.push(<div style={{backgroundImage: `url(${background})`}} key={index}
                                     className={`payment-method p-${index}`} onClick={()=>{
                            this.props.isRegister(['payment_method',index]);
                            this.props.isRegister(['select_method',!this.props.register.select_method])

                        }
                        }>
                            {this.getCardIcon(index)}
                            <div className="material-icons ">
                                arrow_right
                            </div>
                            <div className={"pm-name"}>{index}</div>
                        </div>);
                    }

                })
            }

        }
        return ps
    }
    checkoutAction(){
        var bttn = this;
        bttn.props.isRegister(['checkout', 1])
        ReactGA.ga('qrdeliver.send', 'event',`qrpayproducts/${bttn.props.match.params.appid}/${bttn.props.match.params.qrcode}_checkoutAction`, bttn.getPriceFormated(bttn.getCartAmount()));
        serverRequest('post', `qrdeliverpay/${bttn.props.match.params.appid}/${bttn.props.match.params.qrcode}`, {
            app: `${bttn.props.match.params.appid}`,
            type: 'fooi',
            qritem: `${bttn.props.match.params.qrcode}`,
            amount: bttn.getCartAmount(),
            cart: bttn.getCartProducts(),
            qty: 1,
            num: bttn.props.register.clientnum,
            postcode: bttn.props.register.postcode,
            voucher:bttn.props.register.voucher
        }, (res) => {
            ReactGA.ga('qrdeliver.send', 'event',`qrpayproducts/${bttn.props.match.params.appid}/${bttn.props.match.params.qrcode}_checkoutAction_loaded`, bttn.getPriceFormated(bttn.getCartAmount()));


            if (res.res&&res.res.session && res.res.session.url) {
                ////console.log('res', res.res.session.url, bttn.props.register.postcode);
                window.localStorage.setItem(this.props.register.QR_DELIVER._id, JSON.stringify({
                    postcode: bttn.props.register.postcode,
                    cart: this.props.register.cart,
                    total_amount: this.props.register.total_amount
                }));

                window.location.href = res.res.session.url;

            } else if (res.res&&res.res.msg) {
                bttn.props.isRegister(['checkout', 0])
                bttn.props.isRegister(['error', res.res.msg])
            }


        });
    }
    getPaymentMethodsHolder(){
        if(!this.props.register.buttoncheckout||this.props.register.checkout||this.props.register.loading)return null
        return(
            <div className={`payment-methods `}>
                {this.getPaymentMethods()}
            </div>
        )
    }
    getCheckoutForm(){
        if(!this.props.register.QR_DELIVER.stripe_checkout){
            return(<Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
                {this.getPaymentMethodsHolder()}
                <CheckoutForm getPriceFormated={this.getPriceFormated.bind(this)} recaptcha={this.recaptcha}getCartProducts={this.getCartProducts.bind(this)} getCartAmount={this.getCartAmount.bind(this)} canCheckoutForm={this.canCheckoutForm.bind(this)} props={this.props} getButtonTitle={this.getButtonTitle.bind(this)} getPayDisabled={this.getPayDisabled.bind(this)}/>
            </Elements>)
        }
        if(!this.props.register.QR_DELIVER.stripe_checkout)return;
        return (<SubmitButton disabled={this.getPayDisabled()} onClick={() => {
            // this.props.isRegister(['command',this.checkoutAction.bind(this)])
            // this.recaptcha.execute();
            this.checkoutAction();
        }}> <input
            type="hidden"
            onChange={() => {

            }}
            value={this.getCartAmount()}
        />
            {this.getButtonTitle()}
        </SubmitButton>)
    }
    getShippingCostsAmount() {
        const params = queryString.parse(this.props.history.location.search);
        if (this.props.register.QR_DELIVER.shipping_required||this.props.register.QR_DELIVER.restricted_shipping) {
            return (<div className={`shipping-price-amount ${this.canCheckoutForm()?'hidden':''}`}>

                {this.getShippingFields()}
                {this.getComments()}



                {this.getPrefferedDelivery()}


                <div></div>
            </div>)
        }
        return null;
    }

    getPrefferedDelivery() {
        if (this.props.register.QR_DELIVER.preffered_time) {
            return (<div className="preferred-delivery">
                <div>{getKey(this.props.register.lang.shop_data.qrdeliver, 'preferred_delivery_time')}
                </div>

                <TimePicker
                    onChange={() => {


                    }
                    }
                    disableClock={true}
                    minTime={this.props.register.QR_DELIVER.preffered_from?this.props.register.QR_DELIVER.preffered_from:""}
                    maxTime={this.props.register.QR_DELIVER.preffered_to?this.props.register.QR_DELIVER.preffered_to:""}
                    value={this.props.register.QR_DELIVER.preffered_from?this.props.register.QR_DELIVER.preffered_from:null}
                /></div>)
        }
    }

    getPayDisabled() {
        const amount = this.getCartAmount();
        ////console.log(this.getCartCount(),'<<<<<')
        if(this.props.register.QR_DELIVER.voucher_valid&&this.props.register.QR_DELIVER.voucherData&&amount==0&&Object.keys(this.props.register.cart.products).length >0){

            if(!this.props.register.buttoncheckout)return false;
            if(this.getCartCount()==0)return true;
            if(this.props.register.QR_DELIVER.shipping_required&&!this.props.register.postcode)return true;
            if(this.props.register.QR_DELIVER.shipping_required&&!this.props.register.house_number)return true;
            if(this.props.register.QR_DELIVER.shipping_required&&!this.props.register.street_name)return true;
            return false;
        }
        if (this.props.register.checkout || this.props.register.loading_voucher || Object.keys(this.props.register.cart.products).length == 0 || amount == 0 || amount < this.props.register.QR_DELIVER.minimum_amount) {


            return true;
        }
        ////console.log(this.props.register.QR_DELIVER.postcode,this.props.register.street_name,'slurf',amount)
        if(this.getCartCount()==0)return true;
        if(!this.props.register.buttoncheckout)return false;
        if(this.props.register.QR_DELIVER.shipping_required&&!this.props.register.postcode)return true;
        if(this.props.register.QR_DELIVER.shipping_required&&!this.props.register.house_number)return true;
        if(this.props.register.QR_DELIVER.shipping_required&&!this.props.register.street_name)return true;
        if(this.props.register.QR_DELIVER.phone_required&&!this.props.register.phone)return true;
        if(!this.props.register.QR_DELIVER.stripe_checkout&&!this.props.register.name)return true;
        if(!this.props.register.QR_DELIVER.stripe_checkout&&(!this.props.register.email||!this.emailValidation(this.props.register.email)))return true;
        return false;
    }
    canCheckoutForm() {
        const amount = this.getCartAmount();
        if(this.props.register.QR_DELIVER.voucher_valid&&this.props.register.QR_DELIVER.voucherData&&amount==0&&Object.keys(this.props.register.cart.products).length >0){
            if(!this.props.register.buttoncheckout||this.props.register.checkout){
                return true;
            }

            return false;
        }
        if (!this.props.register.buttoncheckout||this.props.register.checkout || Object.keys(this.props.register.cart.products).length == 0 || amount == 0 || amount < this.props.register.QR_DELIVER.minimum_amount) {


            return true;
        }

        return false;
    }
    getName(){
        if(this.props.register.checkout){
            return null
        }
        if(!this.props.register.stripe_checkout&&this.props.register.buttoncheckout){


            return(
                <div className="name-holder">
                    <div className="label">{getKey(this.props.register.lang.shop_data.qrdeliver, 'name_and_lastname')}</div>
                    <input className="street-field name"
                           value={this.props.register.name ? this.props.register.name : ""}
                           onChange={(evt) => {
                               this.props.isRegister(['name', evt.target.value])

                           }}
                           placeholder={getKey(this.props.register.lang.shop_data.name, 'name_hint')}
                           name="name" type="text" pattern="[0-9]"/>
                    <input className="street-field lastname"
                           value={this.props.register.lastname ? this.props.register.lastname : ""}
                           onChange={(evt) => {
                               this.props.isRegister(['lastname', evt.target.value])

                           }}
                           placeholder={getKey(this.props.register.lang.shop_data.qrdeliver, 'lastname_hint')}
                           name="lastname" type="text" pattern="[0-9]"/></div>
            )
        }
        return null;
    }
    emailValidation(email){
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if(!email || regex.test(email) === false){

            return false;
        }
        return true;
    }

    getEmail(){
        if(this.props.register.checkout){
            return null
        }
        if(!this.props.register.stripe_checkout&&this.props.register.buttoncheckout){

            return(
                <div className="email-holder">
                    <div className="label">{getKey(this.props.register.lang.shop_data.qrdeliver, 'email')}</div>
                    <input className="street-field email"
                           value={this.props.register.email ? this.props.register.email : ""}
                           onChange={(evt) => {

                               this.props.isRegister(['email', evt.target.value])

                           }}
                           placeholder={getKey(this.props.register.lang.shop_data.qrdeliver, 'email_hint')}
                           name="email" type="text"/>
                </div>

            )



        }
    }
    getTotalButton() {
        const params = queryString.parse(this.props.history.location.search);
        if (this.props.register.loading || !this.props.register.cart || this.props.register.menuFlipped ||
            this.props.register.QR_DELIVER.request_postcode ||
            !this.props.register.products ||
            this.props.register.products.length == 0) return null;
        if (params.redirect_status) {
            return null;
        }

        return (
            <div className={`checkout-form `} onClick={()=>{
                if(this.props.register.climateinfo){
                    this.props.isRegister(['climateinfo',0])
                }
            }}>
                {this.getName()}
                {this.getEmail()}
                <div className="phoneform">
                    {this.getPhone()}
                </div>




                {this.getShippingMsg()}
                {this.getVoucherHolder()}
                {this.getTaxAmount()}
                {this.getShippingCostsAmount()}
                {this.getError()}
                {this.getCheckoutForm()}


                {this.getMinimumAmount()}
            </div>
        )


    }
    getVoucherHolder(){
        if(this.props.register.loading||this.props.register.checkout)return null;
        return(
            <div className="phoneform voucher">

                {this.addVoucher()}
            </div>
        )
    }

    getButtonTitle() {
        if (this.props.register.checkout || this.props.register.loading_voucher) {
            return (this.getLoader(true))

        }
        return (<div className="paytext"><div className={'material-icons pay'}>shopping_cart_checkout</div><div className="translated-text-pay"> <AnimatedNumber complete={()=>{

        }}

                                                                                                                                                         value={this.getCartAmount()}
                                                                                                                                                         formatValue={this.getPriceFormated.bind(this)}
        /></div></div>)
    }

    getProductsMenu() {
        if (this.props.register.menuFlipped) return null;
        if (!this.props.register.loading && !this.props.register.status && this.props.register.products && this.props.register.products.length && !this.props.register.menuFlipped) {

            return (this.getHeaders());

        }
        if (!this.props.register.loading) {
            return (<div className="results">
                <div className="form-label noproducts"
                     htmlFor="typeNumber">{getKey(this.props.register.lang.shop_data.qrdeliver, 'no_products')}</div>


            </div>)
        }

        return <div className="loader">{this.getLoader()}</div>;

    }

    getMenus() {

        if (!this.props.register.status && this.props.register.menus && this.props.register.menuFlipped) {

            return (this.getMenuHeaders());

        }
        return null;

    }
    getTaxAmount(){
        if(this.props.register.QR_DELIVER.tax&&this.getCartAmount(false,true)>0){
            return(<div className={"tax-amount"}>{this.props.register.QR_DELIVER.tax}
                <div className="material-icons ">
                    percent
                </div>
                {getKey(this.props.register.lang.shop_data.qrdeliver, 'tax')}: {this.getPriceFormated(this.getCartAmount(false,true))}
            </div>)
        }
        return null;
    }
    getCountries(){
        return Object.keys(currencies).map((item)=>{
        if(item!=this.props.register.country){



            return(<div key={`flag_item${currencies[item].name}`} onClick={()=>{

                this.props.isRegister(['country', item]);
                this.props.isRegister(['currency', currencies[item].defaultCurrency]);
                 this.props.isRegister(['show_countries', false]);

                 this.searchQuery(this.props.register.lastquery);


            }}><ReactCountryFlag
                        countryCode={item}/>{currencies[item].name} </div>);

        }

        })
    }
    getLanguages(){
    const me=this;
        return this.props.register.langs.map((item)=>{

            return(<div key={`flag_item${item.key}`} onClick={()=>{
this.props.isRegister(['lang', null]);
                me.props.isRegister(['language', item.key]);
                 me.props.isRegister(['show_languages', false]);
                 serverRequest('post','merchant/api/language/'+item.key, {},response => {
                     //   window.location.reload();
                     me.start();
                  });

            }}>{item.english_name}</div>);
        })
    }
    getPostcodeForm() {
        if(this.props.register.checkout)return null;
        if(this.props.register.buttoncheckout){
            return(
                <div className="label goback" >

                    <div className="label">{getKey(this.props.register.lang.shop_data.qrdeliver, 'checkout')} </div> <span className="material-icons edit-checkout" onClick={()=>{
                    this.props.isRegister(['buttoncheckout',0])
                }}>
shopping_cart
</span><div className={'cartCount'} onClick={()=>{
                    this.props.isRegister(['buttoncheckout',0])
                }}>{this.getTotalCartCount()}</div></div>
            )
        };

        if (this.props.register.QR_DELIVER.request_postcode && !this.props.register.QR_DELIVER.postcode && !this.props.register.menuFlipped) {

            return (
                <div className={"postcode-holder"}>
                    <div>{getKey(this.props.register.lang.shop_data.qrdeliver, 'fill_in_postcode')}</div>
                    <input className="postcode-field"
                           value={this.props.register.postcode ? this.props.register.postcode : ""} onChange={(evt) => {
                        this.props.isRegister(['postcode', replacePostcodeStr(evt.target.value)])

                    }} placeholder={getKey(this.props.register.lang.shop_data.qrdeliver, 'postcode_hint')} name="zip"
                           type="text" pattern="[0-9]*"/>
                    <div>{this.props.register.QR_DELIVER.msg ? getKey(this.props.register.lang.shop_data.qrdeliver, this.props.register.QR_DELIVER.msg) : ""}</div>
                    <SubmitButton disabled={this.props.register.loading ? true : false} onClick={() => {

                        if (this.props.register.postcode) {
                            this.reload({postcode: this.props.register.postcode})
                        }


                    }}>{this.props.register.loading ? this.getLoader(true) : getKey(this.props.register.lang.shop_data.qrdeliver, 'submit')} </SubmitButton>
                </div>
            )
        }
        return this.getProductsMenu();
    }

    getFooter() {
        if (this.props.register.QR_DELIVER.footer) {
            return (<div className={'footer'}>{this.props.register.QR_DELIVER.footer}</div>)
        }
        return null;
    }

    getHeader() {
        if (this.props.register.QR_DELIVER.footer) {
            return (<div className={"header"}>{this.props.register.QR_DELIVER.header}</div>)
        }
        return null;
    }

    mikariForm() {
        const params = queryString.parse(this.props.history.location.search);
        if (!this.props.register.status) {
            if (this.props.register.QR_DELIVER.is_menu) {

                return (<div className={"flip-card " + (this.props.register.menuFlipped ? "flipped" : "")}>
                    <div className="flip-card-inner">
                        <div className="flip-card-front">

                            {this.getPostcodeForm()}


                        </div>
                        <div className="flip-card-back">
                            {this.getMenus()}

                        </div>
                    </div>
                </div>)
            }
            return (
                <div>

                    <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">

                                <div className="form-label tpu-amount"
                                     htmlFor="typeNumber">{this.props.register.QR_DELIVER.user.company_desc}</div>


                                {this.getAmountField()}
                                {this.getSummary()}


                                <SubmitButton onClick={() => {
                                    var bttn = this;
                                    serverRequest('post', `qrdeliverpay/${bttn.props.match.params.appid}/${bttn.props.match.params.qrcode}`, {
                                        app: `${bttn.props.match.params.appid}`,
                                        type: 'fooi',
                                        qritem: `${bttn.props.match.params.appcode}`,
                                        amount: (String(bttn.props.register.amount).replace(/\D/g, "")),
                                        qty: 1,
                                        num: bttn.props.register.clientnum,token: bttn.props.register.captcha_token
                                    }, (res) => {


                                        if (res.res.session) {
                                            ////console.log('res', res.res.session.url);
                                            window.location.href = res.res.session.url;
                                        } else if (res.res.msg) {
                                            bttn.props.isRegister(['error', res.res.msg])
                                        }


                                    });
                                }}> Pay </SubmitButton>
                            </div>
                            <div className="flip-card-back">
                                <h1>John Doe</h1>
                                <p>Architect & Engineer</p>
                                <p>We love that guy</p>
                            </div>
                        </div>
                    </div>
                </div>

            );
        } else if (params.redirect_status) {


            return (<div>


                {this.getMessage()}

            </div>)

        } else {
            return (<div>


                {this.redirectMessage()}

            </div>)
        }
    }

    redirectMessage() {
        const params = queryString.parse(this.props.history.location.search);
        if (this.props.register.status == 'loading') {
            return (<div className="">
                <div className="form-label noproducts" htmlFor="typeNumber">Please wait...</div>

            </div>)
        } else if (this.props.register.status == 'redirecting') {
            return (<div className="">
                <div className="form-label noproducts" htmlFor="typeNumber">Your beeing redirected...</div>

            </div>)
        } else if (this.props.register.status == 'order_error') {
            return (<div className="">
                <div className="form-label noproducts" htmlFor="typeNumber">
                    <div>{getKey(this.props.register.lang.shop_data.qrdeliver, 'order_error')}</div>

                </div>
                <button
                    className={`SubmitButton`}
                    type="submit"

                    onClick={() => {
                        window.location = `/qrpay/${this.props.match.params.appid}/${this.props.match.params.qrcode}`;
                    }}
                > {getKey(this.props.register.lang.shop_data.qrdeliver, 'order_error')}
                </button>
            </div>)
        }


    }

    getMessage() {
        const params = queryString.parse(this.props.history.location.search);
        if (params.redirect_status == 'failed') {
            return (<div className="">
                <div className="form-label noproducts" htmlFor="typeNumber">
                    <div>{getKey(this.props.register.lang.shop_data.qrdeliver, 'order_error')}</div>

                </div>
                <button
                    className={`SubmitButton`}
                    type="submit"

                    onClick={() => {
                        window.location = `/qrpay/${this.props.match.params.appid}/${this.props.match.params.qrcode}`;
                    }}
                > {getKey(this.props.register.lang.shop_data.qrdeliver, 'try_again')}
                </button>
            </div>)

        } else {
            return (<div className="results success">
                <div className="form-label success" htmlFor="typeNumber">Payment Success</div>

                <button
                    className={`SubmitButton order-again`}
                    type="submit"

                    onClick={() => {
                        window.location = `/qrpay/${this.props.match.params.appid}/${this.props.match.params.qrcode}`;
                    }}
                > {getKey(this.props.register.lang.shop_data.qrdeliver, 'order_again')}
                </button>
            </div>)
        }
    }

    getSummary() {
        if (!this.props.register.status) {


            return (<table className="checkout-table">

                <tbody>

                <tr>
                    <td className="tlab">
                        Transaction fee
                    </td>
                    <td>
                        {this.getCurrencyAmount(this.props.register.QR_DELIVER.user.fee)}
                    </td>

                </tr>
                </tbody>
            </table>);


        }
    }

    getPostcodeButton() {
        const params = queryString.parse(this.props.history.location.search);
        if (!this.props.register.buttoncheckout&&!params.redirect_status && this.props.register.products && this.props.register.products.length > 0 && this.props.register.QR_DELIVER.restrict_shipping && !this.props.register.menuFlipped && this.props.register.QR_DELIVER.postcode) {
            return (<div className="postcode-change" onClick={() => {
                var qrDeliver = JSON.parse(JSON.stringify(this.props.register.QR_DELIVER));
                qrDeliver.request_postcode = true;
                qrDeliver.postcode = undefined;
                this.props.isRegister(['msg',null])
                this.props.isRegister(['buttoncheckout',0])
                this.props.isRegister(['QR_DELIVER', qrDeliver])
            }}>{this.props.register.QR_DELIVER.postcode}</div>)
        }
        return null;
    }
    getPostcodeCheckoutButton() {

    }
    getError(){
        if(this.props.register.error&&this.props.register.buttoncheckout){
            return(
                <div className={"error"}> <div className={'material-icons'}>error_outline</div><div className="label">{this.props.register.error}</div></div>
            )
        }
        return null
    }
    getRecap(){
        if(this.props.register.menuFlipped&&!this.props.register.captcha_token&&!window.nativeqrdeliver){
            return(
                <div id="recaptcha-container">
                    <Recaptcha id={"recapp"}
                               locale={this.props.register.lang.key.split("_")[0]}
                               ref={ ref => this.recaptcha = ref }
                               sitekey={ '6LeFfjIeAAAAABkQQMVFLTHbzU67WSGJXH-S5iHu' }

                               onResolved={ () => {
                                   ;
                                   this.props.isRegister(['captcha_token', this.recaptcha.getResponse()])
                                   if(this.props.register.command){

                                       this.props.register.command();
                                       this.props.register.command = undefined;
                                   }
                               } } /></div>
            )
        }
    }
    getQRAnalatics(){

    }
    getMenuName(){
        if(this.props.register.menuFlipped)return null;
        if(!this.props.register.buttoncheckout){
            return(<div className="title-container">
                <h1 className="title">{this.props.register.QR_DELIVER.user.name}</h1>
            </div>)
        }
        return null;
    }
    getNav(){
            if(window.nativeqrdeliver){

            return null;
            }
        if(this.props.register.menuFlipped){

            return(<div className={'about-us'}>
            <div className={'material-icons menu home'} onClick={()=>{

                this.props.history.push('#home');
                 this.props.isRegister(['menuFlipped', !this.props.register.menuFlipped]);
            }}>home</div>

            </div>)
        }
        return(<div className={'header-menu'}><div className={'material-icons menu'} onClick={()=>{

            this.props.history.push('#search');
             this.props.isRegister(['menuFlipped', !this.props.register.menuFlipped]);
        }}>search</div></div>)
        return null;


    }
    getLangHolder(){



                    return(

                    <div className={'languageHolder'}>

             <div className={'countryLabel lang'} onClick={()=>{

                this.props.isRegister(['show_languages', !this.props.register.show_languages]);
            }}>{this.props.register.lang.key.split("_")[0]}</div>
{this.props.register.show_languages&&<div onClick={()=>{
              this.props.isRegister(['show_languages', false]);
            }} className="hidedrops"></div>}
                           {this.props.register.show_languages&&<div className={'languages-drop'}>{this.getLanguages()}</div>}


                    </div>

                    )




    }
    getToggleIcon(){

        if(!window.nativeqrdeliver){
                return "loaded"

        }

        return "";


    }
    getSearchIcon(){
        if(this.props.register.advanced_search){
            return (
                <span className={`material-icons edit-checkout home ${this.getToggleIcon()}`} onClick={()=>{
                    this.props.isRegister(['advanced_search',!this.props.register.advanced_search])
                }}>share_location</span>

            )
        }
        return (
            <span className={`material-icons edit-checkout home ${this.getToggleIcon()}`} onClick={()=>{
                this.props.isRegister(['advanced_search',!this.props.register.advanced_search])
            }}>person_search</span>
        )
    }
    getCountryLabel(){
 if(this.props.register.country&&currencies[this.props.register.country]){
            return(
                <div><ReactCountryFlag
                        countryCode={this.props.register.country}/>{currencies[this.props.register.country].name} <div className={'currency_label'}>{currencies[this.props.register.country].defaultCurrency}</div></div>
            )
        }
        return <div>{getKey(this.props.register.lang.shop_data.qrdeliver, 'select_country')}<div className={"material-icons"} >keyboard_arrow_right

                                </div></div>
    }
    search(){
        if(this.props.register.loading)return;
        var post={};
        post.qry=replaceString(this.props.register.qry);
        if(this.props.register.advanced_search){
            post.country=this.props.register.country;
        }
 post.language=this.props.register.lang.key;
        const params = queryString.parse(this.props.history.location.search);
         if(params.test_mode){
                post.test_mode=true;
                }
        serverRequest('post', `fooiappmobile/qrdeliver/search`, post, (res) => {





        });
    }
    getCurrentSearchForm(){
        var me=this;
        if(this.props.register.advanced_search){
            return(
                <div className={"postcode-holder"}>

                   <div className="searchbar">
                    <input id="search-input" ref={this.searchfield} className="postcode-field search-name"
                           value={this.props.register.qry ? this.props.register.qry : ""} onChange={(evt) => {
                        this.props.isRegister(['qry', replaceString(evt.target.value)]);

                        if (me.props.register.search_timeout) {
                            clearTimeout(me.props.register.search_timeout);

                        }
                        var timer = setTimeout(function () {


                            me.props.isRegister(['command',()=>{
                                me.searchQuery({

                                    postcode: me.props.register.postcode,
                                    voucher: me.props.register.voucher,
                                    street_name: me.props.register.street_name,
                                    house_number: me.props.register.house_number,
                                    house_number_addition: me.props.register.house_number_addition,
                                    app: `${me.props.match.params.appid}`,
                                    type: 'fooi',
                                    qritem: `home`,
                                    qty: 1,
                                    num: me.props.register.clientnum,
                                    postcode: me.props.register.postcode

                                })
                            }]);
                            if(!window.nativeqrdeliver){
                            if(me.recaptcha&&typeof me.recaptcha.execute =='function'&&!me.props.register.captcha_token){
                                me.recaptcha.execute();
                            }else{
                                me.props.register.command();
                            }

                            }else{

                             me.props.register.command();
                            }


                        }, 500);

                        this.props.isRegister(['search_timeout', timer]);

                        //this.search();

                    }} placeholder={getKey(this.props.register.lang.shop_data.qrdeliver, 'person_name_company')} name="person"
                           type="text" pattern="[a-zA-Z0-9]*"/> {this.getSearchLoader()}</div>


                    <div>{this.props.register.msg ? getKey(this.props.register.lang.shop_data.qrdeliver, this.props.register.msg) : ""}</div>

                </div>
            )
        }
        return(
            <div className={"postcode-holder"}>

                <div   className="searchbar">

                {!this.props.register.gg_enabled&&<div className={"autosuggest-osm"}><input ref={this.searchfield} id="search-input" className="postcode-field search-name"
                           value={this.props.register.qry ? this.props.register.qry : ""} onChange={(evt) => {

                           if(this.search_timeout){
                           clearTimeout(this.search_timeout);

                           }
                           this.search_timeout=setTimeout(()=>{
                           const provider = new OpenStreetMapProvider({params:{addressdetails:1}});
provider.search({ query:`${replaceString(evt.target.value)}`}).then((results)=>{

this.props.isRegister(['auto_complete_suggest',results]);
});
                           },1000)
               this.props.isRegister(['qry', replaceString(evt.target.value)]);
                           return;
                        this.props.isRegister(['qry', replaceString(evt.target.value)]);

                        if (me.props.register.search_timeout) {
                            clearTimeout(me.props.register.search_timeout);

                        }
                        var timer = setTimeout(function () {


                            me.props.isRegister(['command',()=>{
                                me.searchQuery({

                                    postcode: me.props.register.postcode,
                                    voucher: me.props.register.voucher,
                                    street_name: me.props.register.street_name,
                                    house_number: me.props.register.house_number,
                                    house_number_addition: me.props.register.house_number_addition,
                                    app: `${me.props.match.params.appid}`,
                                    type: 'fooi',
                                    qritem: `home`,
                                    qty: 1,
                                    num: me.props.register.clientnum,
                                    postcode: me.props.register.postcode

                                })
                            }]);
                            if(!window.nativeqrdeliver){
                            if(me.recaptcha&&typeof me.recaptcha.execute =='function'&&!me.props.register.captcha_token){
                                me.recaptcha.execute();
                            }else{
                                me.props.register.command();
                            }

                            }else{

                             me.props.register.command();
                            }


                        }, 500);

                        this.props.isRegister(['search_timeout', timer]);

                        //this.search();

                    }} placeholder={''} name="person"
                           type="text" pattern="[a-zA-Z0-9]*"/>


                           </div>}

                {this.props.register.gg_enabled&&<Autocomplete
                 language={this.props.register.lang.simple}
                    apiKey={'AIzaSyDb0snCLtF7zN3_iPYS9ezsErm3FFUmFcg'}
                    style={{
                        width: "90%",
                        padding: '10px',
                        border: '1px solid #000',
                        borderRadius: '4px',
                        fontSize: '14px',
                        fontFamily: 'Nunito'
                    }}
                    onPlaceSelected={(place) => {
                        ////console.log(place)
                        var add = {street: ''};
                        if (place && place.address_components) {

                            place.address_components.map((item) => {
                                ////console.log(item.types[0])
                                if (item.types[0] == 'route') {
                                    add.street = item.long_name;
                                }
                                if (item.types[0] == 'street_number') {
                                    add.street_number = item.long_name;
                                }
                                if (item.types[0] == 'city') {
                                    add.city = item.long_name;
                                }
                                if (item.types[0] == 'country') {
                                    add.country = item.short_name;
                                }
                            })
                            if (add.street) {
                                ////console.log("<><><><><><>!")

                                this.props.isRegister(['street_name', add.street])
                            }
                            if (add.street_number) {
                                ////console.log(add.street_number, "<><><><><><>!")

                                this.props.isRegister(['house_number', add.street_number.toString()])
                            }
                            if (add.city) {
                                ////console.log(add.street_number, "<><><><><><>!")

                                this.props.isRegister(['ciy', add.city.toString()])
                            }
                            if (add.country) {
                                ////console.log(add.country, "<><><><><><>!")

                                this.props.isRegister(['country', add.country.toString()])
                            }
                        }
                        if (me.props.register.search_timeout) {
                            clearTimeout(me.props.register.search_timeout);

                        }
                        ////console.log(add)
                        var timer = setTimeout(function () {


                            me.props.isRegister(['command',()=>{
                                me.searchQuery({

                                    postcode: me.props.register.postcode,
                                    voucher: me.props.register.voucher,
                                    street_name: me.props.register.street_name,
                                    house_number: me.props.register.house_number,
                                    house_number_addition: me.props.register.house_number_addition,
                                    app: `${me.props.match.params.appid}`,
                                    type: 'fooi',
                                    qritem: `home`,
                                    qty: 1,
                                    num: me.props.register.clientnum,
                                    postcode: me.props.register.postcode

                                })
                            }]);
                            if(!window.nativeqrdeliver){
                            if(me.recaptcha&&typeof me.recaptcha.execute =='function'&&!me.props.register.captcha_token){
                                me.recaptcha.execute();
                            }else{
                                me.props.register.command();
                            }

                            }else{

                             me.props.register.command();
                            }


                        }, 100);

                        this.props.isRegister(['search_timeout', timer]);


                    }}
                    inputAutocompleteValue={this.props.register.street_name}
                    language={this.props.register.lang.simple}
                    options={{

                        types: ["address"],
                        componentRestrictions: {country: this.props.register.country ? this.props.register.country : 'NL'},
                    }}

                    defaultValue={this.props.register.qry ? this.props.register.qry : ''}
                />}{this.getSearchLoader()}</div>
                    {this.props.register.auto_complete_suggest&&this.props.register.auto_complete_suggest.length>0&&<div className={"autosuggest-list"}>{this.getAutoSugestOSM()}</div>}

                <div>{this.props.register.msg ? getKey(this.props.register.lang.shop_data.qrdeliver, this.props.register.msg) : ""}</div>

            </div>
        )
    }
    getAutoSugestOSM(){


    return this.props.register.auto_complete_suggest.map((item,index)=>{

//console.log(this.props.register.auto_complete_suggest[index])
    return <div key={index}className={'autosuggest-list-item'} onClick={()=>{
        if(this.props.register.auto_complete_suggest&&this.props.register.auto_complete_suggest[index].raw.address&&this.props.register.auto_complete_suggest[index].raw.address.country_code){

              if(currencies[this.props.register.auto_complete_suggest[index].raw.address.country_code.toUpperCase()]){

                 this.props.isRegister(['country',this.props.register.auto_complete_suggest[index].raw.address.country_code.toUpperCase()]);
              }
               this.props.isRegister(['city',this.props.register.auto_complete_suggest[index].raw.address.city])
            //this.props.isRegister(['city',code?code:ct]);


if(this.props.register.auto_complete_suggest[index].raw.address.postcode){

this.props.isRegister(['postcode',this.props.register.auto_complete_suggest[index].raw.address.postcode])

}
if(this.props.register.auto_complete_suggest[index].label){

this.props.isRegister(['qry',this.props.register.auto_complete_suggest[index].label])

}
             this.searchQuery(this.props.register.lastquery);
        }
        //console.log(this.props.register.auto_complete_suggest[0])

this.props.isRegister(['auto_complete_suggest',null]);


    }}>

    {item.label}


    </div>


    })





    }
    getSearchLoader(dots){
        if(this.props.register.search_loading){
            if(dots){
                return(
                    <div className={'miniloader ellipsis'}>{this.getLoader(true)}</div>
                )
            }
            return(
                <div className={'miniloader'}>{this.getLoader()}</div>
            )
        }

        return null;
    }

    getSearchTitle(){
        if(this.props.register.advanced_search){
            return (
                getKey(this.props.register.lang.shop_data.qrdeliver, 'search_a_company_or_a_person')
            )
        }
        return(
            getKey(this.props.register.lang.shop_data.qrdeliver, 'search_in_your_area')
        )
    }
    getCategoryForm(){

        return(

        <div className="categories">

         <div className={'categoryLabel'} onClick={()=>{
                this.props.isRegister(['show_categories', !this.props.register.show_categories]);
            }}>{this.getCategoryLabel()}</div>


            {this.props.register.show_categories&&<div className={"category_holder"}>{this.getHomeCategories()}</div>}

        </div>

        )


    }
    getCategoryLabel(){


        if(this.props.register.select_category){


            return (<div>



   <div className={'material-icons'}>{this.props.register.select_category.icon}</div><div className="title">{this.props.register.select_category.title}</div>

        </div>)

        }else{


        return (<div>





<div className={'material-icons'}>{this.props.register.lang.shop_data.qrdeliver.categories[0].icon}</div><div className={'title'}>{this.props.register.lang.shop_data.qrdeliver.categories[0].title}</div>


        </div>)
        }

    }
    getHomeCategories(){
        if(this.props.register.lang.shop_data.qrdeliver.categories){
        return this.props.register.lang.shop_data.qrdeliver.categories.map((item,index) => {

            return (
            <div key={index} onClick={()=>{



this.props.isRegister(['select_category', item]);
this.props.isRegister(['show_categories', !this.props.register.show_categories]);
 this.searchQuery(this.props.register.lastquery);

            }


            }>


<div className={'material-icons'}>{item.icon}</div><div className={'title'}>{item.title}</div>
            </div>
            )



        })
        }
return null;

    }
    getSearch(){
        if(!this.props.register.menuFlipped)return null
        return(
            <div>
            <div className={'countryLabel'} onClick={()=>{
                this.props.isRegister(['show_countries', !this.props.register.show_countries]);
            }}>{this.getCountryLabel()}</div>
            <div className={'changelang'}>
            {this.props.register.show_countries&&<div onClick={()=>{

              this.props.isRegister(['show_countries', false]);
            }} className="hidedrops"></div>}
              <div className={'homecountries'}>{this.props.register.show_countries&&this.getCountries()}</div>


            </div>
            <div className={'bar'}>
            <div className="label goback" >

                <div className="label title_search">{this.getSearchTitle()}


                </div>

                {this.getCategoryForm()}
                {this.getSearchIcon()}

            </div>

             {this.getCurrentSearchForm()}

            </div>

                {this.getMenus()}
                {this.getFooterNative()}


            </div>
        )

    }
    getFooterNative(){

            if(!window.nativeqrdeliver){


            return(
<div>
            <LazyLoad><div style={{paddingTop:100}}>{Parser(getKey(this.props.register.lang.home_data.qrdeliver_homedata, 'intro9'))}</div></LazyLoad>
                <LazyLoad> <div  className={"home-item"}> {Parser(getKey(this.props.register.lang.home_data.qrdeliver_homedata, 'download'))}</div></LazyLoad>
</div>

)


            }
return null;
    }
    getDefault(){
        if(this.props.register.menuFlipped)return null;
        return(
            <div className={'info-items'}>
                <LazyLoad><div className={"home-item"}>{Parser(getKey(this.props.register.lang.home_data.qrdeliver_homedata, 'intro'))}</div></LazyLoad>

                <LazyLoad><div style={{textAlign:'left'}} className={"home-item"}>{Parser(getKey(this.props.register.lang.home_data.qrdeliver_homedata, 'intro1'))}</div></LazyLoad>
                <LazyLoad> <div className={"home-item"}>{Parser(getKey(this.props.register.lang.home_data.qrdeliver_homedata, 'download'))}</div></LazyLoad>
    <LazyLoad><div className={"home-item"}>{Parser(getKey(this.props.register.lang.home_data.qrdeliver_homedata, 'intro2'))}</div></LazyLoad>
        <LazyLoad><div className={"home-item"} >{Parser(getKey(this.props.register.lang.home_data.qrdeliver_homedata, 'intro3'))}</div></LazyLoad>
        <LazyLoad><div className={"home-item"}>{Parser(getKey(this.props.register.lang.home_data.qrdeliver_homedata, 'intro4'))}</div></LazyLoad>
        <LazyLoad><div className={"home-item"}>{Parser(getKey(this.props.register.lang.home_data.qrdeliver_homedata, 'intro5'))}</div></LazyLoad>
        <LazyLoad><div className={"home-item"}>{Parser(getKey(this.props.register.lang.home_data.qrdeliver_homedata, 'intro7'))}</div></LazyLoad>
                <LazyLoad>   <a href={getKey(this.props.register.lang.shop_data.qrdeliver, 'climate_url')} target={"_blank"}><div className={"climate-img"} style={{backgroundImage:`url(${carbon})`}}></div></a> </LazyLoad>

                <LazyLoad><div >{Parser(getKey(this.props.register.lang.home_data.qrdeliver_homedata, 'intro6'))}</div></LazyLoad>


                    <LazyLoad>   <div >{Parser(getKey(this.props.register.lang.home_data.qrdeliver_homedata, 'download'))}</div> </LazyLoad>


  {!this.props.register.menuFlipped&&<div onClick={()=>{
this.props.isRegister(['menuFlipped',!this.props.register.menuFlipped])
const me=this;
if(!this.props.register.menuFlipped){
                         setTimeout(()=>{




                             me.searchfield.current.scrollIntoView({ behavior: "auto" });
                             me.searchfield.current.focus()

                         },3000)

}
                    }}


                    className={'material-icons foating-search'}  >search</div>}

    <div style={{marginTop:'70px'}}>
    </div></div>

    )

    }
    renderHome(){
    if(window.nativeqrdeliver&&!window.qrdelivertoken){

    return null;

    }
    if(!window.nativeqrdeliver&&window.qrdelivertoken){

    return null;

    }
            return(
                <div className={"flip-card " + (this.props.register.menuFlipped ? "flipped" : "")}>
                    <div className="flip-card-inner">
                        <div className="flip-card-front">

                            {this.getDefault()}


                        </div>
                        <div className="flip-card-back">



                            {this.getSearch()}


                        </div>
                    </div>

                </div>
            )

    }
    render() {
        if (this.props.register.lang&&this.props.register.fonts_loaded) {

            return (
                <div className="card shop" style={{
                    'backgroundImage': this.props.register.utoken?"":`url(${bg})`,
                    'backgroundRepeat': 'repeat no-repeat',
                    'backgroundPosition': 'bottom left',
                    'backgroundSize': '24%'
                }}>

                 {this.getCookie()}
                        {this.getAccountButton()}

                    {this.getNativeLogo()}
  {!window.nativeqrdeliver&&<Menu className="main-menu" menuButton={ <div className={'material-icons menu'} onClick={() => {
//                this.props.isRegister(['reviews',false]);
//                    this.props.isRegister(['buttoncheckout', 0])
                }}>menu
                </div>}>
                <MenuItem onClick={()=>{

 this.props.isRegister(['menuFlipped', !this.props.register.menuFlipped]);

}}><div className="material-icons delivery">
                    {this.props.register.menuFlipped?'help':'search'}
                </div>{this.props.register.menuFlipped?getKey(this.props.register.lang.shop_data.qrdeliver,'about'):getKey(this.props.register.lang.shop_data.qrdeliver,'search')}</MenuItem>


{this.getFavorites(true)>0&&<SubMenu label={<div><div className="material-icons delivery">
                    apps
                </div>{getKey(this.props.register.lang,'favorites')}</div>}>


        {this.getFavorites(false) }
        </SubMenu>}
        </Menu>}
            {this.getLangHolder()}
                    {/*this.getNav()*/}

                    {this.renderHome()}


                    {this.getNativeFooter()}
                    {this.getRecap()}
                    {this.getMobileLink()}

                </div>

            )


        }

        ////console.log(this.props)
        return (<div className="card shop" style={{'backgroundImage':`url(${bg})`,'backgroundRepeat':'no-repeat','backgroundPosition':'bottom left'}}>
            <div className="full-page-loader">


                  <img width="100" src={animatedLogo} alt="QRDeliver" /></div>
        </div>)
    }
    getAccountButton(){
if(window.nativeqrdeliver)return null;
 const os=getOS();
        ////console.log(os,"<<<<<");
            //if(os==='iOS'||os==='Android'||window.nativeqrdeliver)return null;
            return(

    <div className={'material-icons account-bttn'} onClick={()=>{

     document.location=`https://www.qrdeliver.com/merchants/login`;

    }}>login</div>
            )

    }
    getMobileLink(){

  if(window.nativeqrdeliver) return;
        const params = queryString.parse(this.props.history.location.search);
        const os=getOS();
        //console.log(os,"<<<<<");
         if(os!=='iOS'&&os!='Android')return null;
     return(
   <div className="share-all">



                                 <div className={"goto material-icons"} onClick={() => {

                                    document.location=`qrdeliver://autourl=${encodeURIComponent(document.location.toString())}`;
                                    setTimeout(function(){

                                        if(getOS()=='iOS'){
                                        document.location=`qrdeliver://autourl=${encodeURIComponent(document.location.toString())}`;

                                        }else if(getOS()=='Android'){

                                        document.location=`qrdeliver://autourl=${encodeURIComponent(document.location.toString())}`;

                                        }else{

                                        document.location=`https://www.qrdeliver.com`;

                                        }

                                    },1000)
                                }
                                }>share_all</div></div> )






    }
    getNativeLogo(){

           // if(!window.nativeqrdeliver){


            return(
            <div className={`image-container logo ${this.getItemBG({image:'https://www.qrdeliver.com/media/qrdeliver-512.png'})}`} onClick={() => {
                      this.props.isRegister(['menuFlipped', !this.props.register.menuFlipped]);
                    }}>


                                    <img src={'https://www.qrdeliver.com/media/qrdeliver-512.png'} onLoad={()=>{

                                           this.props.isRegister([`img_${md5("https://www.qrdeliver.com/media/qrdeliver-512.png")}`, 1]);

                                    }} alt={'QRDeliver'}/>


                    </div>)
         //   }
        return null;

    }
    resetbutton(){


             const floatsearch=document.getElementById("float-search");
               const searchinput=document.getElementById("search-input");

      if(floatsearch&&searchinput){



if(window.debug)console.log(searchinput.getBoundingClientRect().top<0)
if(searchinput.getBoundingClientRect().top<0){
floatsearch.style.display="inherit"

}else{


floatsearch.style.display="none"
}
}

    }
    handleScroll(event) {
       if(this.resetbutton)this.resetbutton();

//    this.setState({
//      transform: itemTranslate
//    });
}
    getNativeFooter(){

     if(!window.nativeqrdeliver){
        return(
        <div className="footer-links">
                        <a href={this.props.register.lang.home_data.qrdeliver_homedata.qrdeliver_terms_and_conditions_url.url} target={"_blank"}><div className={"label powerd"}>{this.props.register.lang.home_data.qrdeliver_homedata.qrdeliver_terms_and_conditions_url.title}</div></a>
                        <a href={this.props.register.lang.home_data.qrdeliver_homedata.qrdeliver_legal_url.url} target={"_blank"}><div className={"label powerd"}>{this.props.register.lang.home_data.qrdeliver_homedata.qrdeliver_legal_url.title}</div></a>
                        <a href={this.props.register.lang.home_data.qrdeliver_homedata.qrdeliver_contact.url} target={"_blank"}><div className={"label powerd"}>{this.props.register.lang.home_data.qrdeliver_homedata.qrdeliver_contact.title}</div></a>

                        {this.getClimatePopup()}

                    <div className={'qr_txt'}></div>
                     <div onClick={()=>{

                             this.searchfield.current.scrollIntoView({ behavior: "auto" });
                             this.searchfield.current.focus()


                    }}


                    className={'material-icons foating-search'} id={"float-search"} >search</div>
                    </div>
        )
    }
    return null;

    }
    getClimatePopup(){
        //if(!this.props.register.buttoncheckout)return null
        if(this.props.register.climateinfo){
            return(
                <div onClick={()=>{
                    this.props.isRegister(['climateinfo',0])
                }}  className={"climate-holder"}><div className={"climate-img"} style={{backgroundImage:`url(${carbon})`}}></div><br/>{getKey(this.props.register.lang.shop_data.qrdeliver,'contribution_txt')}<a href={getKey(this.props.register.lang.shop_data.qrdeliver, 'climate_url')} target={'_blank'}>{getKey(this.props.register.lang.shop_data.qrdeliver, 'more_about')}</a></div>

            )
        }


        return(
            <div className={"climate-holder mini"}>
                <div onClick={()=>{
                    this.props.isRegister(['climateinfo',1])
                }} className={"climate-img"} style={{backgroundImage:`url(${carbon})`}}></div>



            </div>

        )

        return null;
    }

    getShippingMsg() {
        if (this.props.register.QR_DELIVER.shipping_costs&&!this.props.register.checkout) {
            return(<div className={`minimum-price-amount shipping`}>
                <div className="material-icons delivery">
                    delivery_dining
                </div>
                {getKey(this.props.register.lang.shop_data.qrdeliver, 'shipping_costs')}: {this.getPriceFormated(this.props.register.QR_DELIVER.shipping_costs)}
            </div>)
        }
        return null

    }


}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({setUser, isRegister, regKey}, dispatch);

}

function mapStateToProps(state) {
    return {
        user: state.user,
        register: state.register


    }

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Game))
