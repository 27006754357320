export default function(state,action){

  if(!state&&!action.payload){
    ////console.log("returned")
      return null
  }
    switch(action.type){
        case 'IS_SETTINGS':
            return action.payload;
        default:
            return state;
    }
}
