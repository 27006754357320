import React from 'react';
import ReactDOM from 'react-dom';
import { createStore,applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import reducers from './reducers'
import ReduxPromise from 'redux-promise'
import registerServiceWorker from './serviceWorker';
import './App.css';
import { createRoot } from 'react-dom/client';
import App from './home';
const createStoreWithMiddleware= applyMiddleware(ReduxPromise)(createStore);
//ReactDOM.render(, document.getElementById('root'));
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Provider store={createStoreWithMiddleware(reducers)}><App /></Provider>);

registerServiceWorker();
