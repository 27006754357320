import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import GoogleMapReact from 'google-map-react';
import {setUser, isRegister, translate, getOS,serverRequest, regKey, getKey} from './actions/index';
import { MapContainer, TileLayer, useMap,Marker,Popup } from 'react-leaflet';
import Routing from "./Routing";
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
class LeafMap extends Component {
static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33
    },
    zoom: 11
  };
    constructor(props) {

        super(props);
        this.googleMaps = React.createRef();



    }

    render() {
    const me=this;
var center=[52.3547925,4.7638777];
if(this.props.register.home_gps){

center=this.props.register.home_gps;
}


   return(

       <MapContainer whenReady={map =>{
me.props.isRegister(['map',map]);
       }} style={{width:'100%',height:'50%',position:'fixed',zIndex:-1,top:0,left:0}}center={center} zoom={8} scrollWheelZoom={false}>

<TileLayer
    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  />
</MapContainer>);

    }
    getPopup(){

    return  <Popup>
          A pretty CSS3 popup. <br /> Easily customizable.
        </Popup>;
    }
    getRoute(){

    return this.props.register.route.instructions.map((item,index)=>{


        return <div key={index}>...</div>


    })


    }


}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({setUser, isRegister, regKey}, dispatch);

}

function mapStateToProps(state) {
    return {
        user: state.user,
        register: state.register


    }

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeafMap))
