import axios from 'axios';
import Config from '../config/config';
export const IS_USER="IS_USER";
axios.defaults.withCredentials = true
export function getJokes(max,scope,cb){
  scope.props.isRegister(['loading',true]);
  serverRequest("get",max,{},response => {
          if(response.status&&response.res){
            cb(response.res);
          }
  });
}
export function PrintElem(html, title, offset)
    {

      var dStart = Math.round(new Date().getTime()/1000);

          var i = 0;



              var printWindow = window.open('', 'PRINT', 'height=' + window.innerheight + ',width=' + window.innerWidth,);
              // Append new window HTML
              printWindow.document.write('<head><title>'+title+'</title><script>function onload(){window.print(); setTimeout(function(){window.document.close();},1000)}</script></head><body onload="onload()">'+html+'</body>');

              printWindow.document.close(); // necessary for IE >= 10
              printWindow.focus(); // necessary for IE >= 10*/
              setTimeout(function(){
              printWindow.close();
              },1000)
              /* Make sure that page is loaded correctly */
//              window.$(printWindow).on('load', function(){
//                  setTimeout(function(){
//                      // Open print
//                      printWindow.print();
//
//                      // Close on print
//                      setTimeout(function(){
//                          printWindow.close();
//                          return true;
//                      }, 3);
//
//                  }, (Math.round(new Date().getTime()/1000) - dStart)+i+offset);
//              });


    }
export var mongoObjectId = function () {
    var timestamp = (new Date().getTime() / 1000 | 0).toString(16);
    return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function() {
        return (Math.random() * 16 | 0).toString(16);
    }).toLowerCase();
};
export function getOS() {
  var uA = navigator.userAgent || navigator.vendor || window.opera;
  if ((/iPad|iPhone|iPod/.test(uA) && !window.MSStream) || (uA.includes('Mac') && 'ontouchend' in document)) return 'iOS';

  var i, os = ['Windows', 'Android', 'Unix', 'Mac', 'Linux', 'BlackBerry'];
  for (i = 0; i < os.length; i++) if (new RegExp(os[i],'i').test(uA)) return os[i];
}
export function hasClass(el, cl) {
        return el.classList ? el.classList.contains(cl) : !!el.className && !!el.className.match(new RegExp('(?: |^)' + cl + '(?: |$)'));
}
export  function isRegister(data){
    return {type:"IS_REGISTER_UPDATE", payload:data};
}

export  function regKey(key,data){
    return {type:key, payload:data};
}
export  function setUser(user){



    return {type:IS_USER, payload:user};


}
export function translate(data,torender,callback){
    if(!callback){
        return;
    }
    return "";

}

export function getKey(data,key,parms,upperfirst){
    if(!data)return key;


    if(data[key]){
        var str = data[key];
        if(upperfirst){
            str=data[key].charAt(0).toUpperCase() + data[key].slice(1)
        }
        return str;
    }else{
        if(upperfirst){
            str=key.charAt(0).toUpperCase() +key.slice(1);
            return str;
        }else{
            return key;
        }

    }

}
export function serverRequest(method,url,data,callback) {
  var host='https://www.qrdeliver.com';
  if(window.location.hostname=='localhost'){
    host='http://localhost:9993';
  }
//console.log(method,url,data,"<<<<<<>")
  const req = axios[method](`${host}/${url}`, data,{});
  req.then((response) => {
    callback({status:1,res:response.data})
  }).catch((error) => {
    callback({status:0,error:error})
  });

}
export function findAddress(address,req, res, next) {

axios.get(`https://nominatim.openstreetmap.org/search.php?q=${encodeURIComponent(address)}&polygon_geojson=1&format=jsonv2`)
.then((results)=>{
if(results){
//console.log(results)
next(results);
}

}
)
};
export function replaceString(str,len=50) {
    if (str) {
        str = String(str).replace(/[^0-9 a-z A-Z \u00C0-\u00FF]/g, '');
        if (str.length > len) {
            str = str.substring(0, len);
        }
        if (str == '-') return '';
        return str;
    }


    return '';
}